import React from "react";
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import io from 'socket.io-client';
import useUserStore from "../components/UserStore";
import LiveActivityCard from "../components/LiveActivityCard";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from "react-bootstrap/Form";
import Translate from "../components/Translate";
import Button from "react-bootstrap/Button";

let socket;

function LiveHome() {
  document.title = "Hub Live - Group Wall";

  // Get the room ID
  const { roomId } = useParams();

  // State to store the current state
  const [roomState, setRoomState] = useState({});

  // Currently selected users
  const [selectedUsers, setSelectedUsers] = useState([]);

  // ergo target
  const [targetWatts, setTargetWatts] = useState();


  // the website joins the socket room like a rider would, but set as a 'viewer'
  const randomInt = getRandomInt(10000);
  const socketUser = {
    _id: randomInt,
    name: "test web app " + randomInt,
    type: 'viewer',
    performanceState: {
      weight: null,
      ftp: null
    }
  }

  useEffect(() => {
    // Create a socket connection
    const SOCKET_URL = "http://127.0.0.1:4000";
    socket = io(SOCKET_URL, { autoConnect: false });
    socket.connect();
    console.log("Connecting to: ", roomId);
    socket.emit("joinRoom", { roomSelected: roomId, user: socketUser });

    // Listen for incoming room state changes
    socket.on('roomStateUpdate', (newState) => {
      // console.log("roomStateUpdate: ", newState);
      setRoomState(newState);
    });

    // Clean up the socket connection on unmount
    return () => {
      socket.disconnect();
    };


  }, []);

  const setUserSelected = (userId) => {
    emitSelected({ userId: userId });
  }

  /**
   * Send a revolution to all users of the room.
   * @param {Object} rev
   * @param {int} rev.power
   * @param {int} rev.cadence
   */
  const emitSelected = ({ userId }) => {
    socket.emit("selectUser", {
      roomSelected: roomId,
      userId: userId,
    });
  }

  /**
   * Send a revolution to all users of the room.
   * @param {string} roomId
   * @param {int} target
   */
  const emitTargetWatts = () => {
    console.log("Emitting target: ", targetWatts);
    socket.emit("setTargetWatts", {
      roomSelected: roomId,
      target: targetWatts,
    });
  }


  const styles = {
    headerContainer: {
      textAlign: "center",
      colour: "white",
      paddingTop: "2em",
      backgroundColor: "black",
    },
    riderContainer: {
      textAlign: "center",
      colour: "white",
      minHeight: "100vh",
      backgroundColor: "black",
    },
    roomNumber: {
      fontFamily: "trim-semibold",
      textAlign: "center",
      color: "white",
      fontSize: "4em",
      margin: 0
    },
    roomInfo: {
      fontFamily: "trim-semibold",
      textAlign: "center",
      color: "white",
      fontSize: "1em",
      margin: 0
    },
    controlsContainer: {
      fontFamily: "trim-semibold",
      textAlign: "center",
      color: "white",
      fontSize: "1em",
      margin: 0
    },
  };


  let riders;
  if (roomState.riders) {
    riders = Object.keys(roomState.riders).map(key => {
      let isRecent = false;
      let isPew = false;
      let containerStyle = 'containerDimmed';

      if ((Math.floor(Date.now()) - roomState.riders[key].live.lastRevUpdate) < 5000) { isRecent = true; }
      if ((Math.floor(Date.now()) - roomState.riders[key].live.eventFired) < 5) { isPew = true; }

      if (roomState.riders[key].selected) {
        containerStyle = 'cardSelected';
      } else if (isPew) {
        containerStyle = 'cardPew';
      } else if (isRecent) {
        containerStyle = 'card';
      } else {
        containerStyle = 'cardDimmed';
      }

      if (roomState.riders[key].type === 'rider') {
        return <LiveActivityCard containerStyle={containerStyle} roomstate={roomState} user={roomState.riders[key]} selectUser={setUserSelected} />
      }
    }
    )
  }

  //

  if (roomState.riders && riders.length > 0) {
    return (
      <>
        <Row style={styles.headerContainer}>
          <div style={styles.roomNumber}>
            {roomId} <br />
          </div>
          <div style={styles.roomInfo}>
            DISTANCE: {Math.round(roomState.stats.distance / 1000)}km
          </div>
          <div style={styles.roomInfo}>
            RIDERS: {riders.length}
          </div>
          <div style={styles.controlsContainer}>
            <Form.Control
              id="targetErgoEntry"
              type="text"
              placeholder="Target W"
              value={targetWatts}
              onChange={(event) => { setTargetWatts(event.target.value); }}
              style={{ "margin": "auto", "padding": 5, "width": 200 }}
            />
            <Button variant="secondary" style={{ "margin": "auto", "padding": 5, "width": 200 }} onClick={() => emitTargetWatts()}>
              Set
            </Button>
          </div>
        </Row>
        <Row style={styles.riderContainer}>
          <div>
            {riders}
          </div>
        </Row>
      </>
    );
  } else {
    return (
      <Row style={styles.container}>
        No users
      </Row>
    );
  }

}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export default LiveHome;
