import React from 'react';
import { useParams } from "react-router-dom";
// import usePreferencesStore from '../components/PreferenceStore';
import useUserStore from "../components/UserStore";
import LoginLayout from "../components/LoginLayout";
import { Link } from "react-router-dom";
import logo from '../assets/img/wattbike_logo_410x.avif';

function Home() {

  document.title = "Hub Clubs";

  // Get redirect url if there is one
  let { redirect } = useParams();

  const { user, signedIn, setSignedIn } = useUserStore((state) => state);

  const styles = {
    logo: {
      width: "250px",
      margin: "auto",
      verticalAlign: "center"
    },
    login: {
      display: "block",
      marginTop: "20px",
      fontSize: "1.2em",
      color: "black",
      width: "100%",
      height: "100%",
    },
    copy: {
      width: 300,
      paddingTop: "20px",
      margin: "auto",
      textAlign: "center",
    }
  }

  return (
    <>
      <LoginLayout>
        <div className="text-center" >
          <img style={styles.logo} src={logo} alt="Logo" />
          <h2>CLUBS</h2>
          <p style={styles.copy} >A simple interface to experiment with coach access to athlete activity data</p>
          {signedIn ? (
            <div className="text-center">
              <Link style={styles.login} to={"/dashboard"}>Dashboard</Link>
              <button onClick={() => setSignedIn(false)}>Sign Out</button>
            </div>
          ) : (
            <div className="text-center">
              {/* <Link style={styles.login} to={"https://dev-auth.wattbike.com/login?client_id=7i11a51i1s0735po0knubqq2dp&response_type=token&scope=email+openid&redirect_uri=https://dev-coach.wattbike.com/cauth/callback"}>Login</Link> */}
              <Link style={styles.login} to={`${process.env.REACT_APP_WBAUTH_URL}?client_id=${process.env.REACT_APP_WBAUTH_CLIENT}&response_type=token&scope=email+openid&redirect_uri=${process.env.REACT_APP_WBAUTH_CALLBACK}&state=${redirect}`}>Login</Link>
            </div>
          )}

        </div>
      </LoginLayout>
    </>
  )
}
export default Home