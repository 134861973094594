import React, { useState, useEffect } from "react";
import Translate from "../components/Translate";
import { Row, Col } from "react-bootstrap";

function Loading(props) {

  const [loadingMessage, setLoadingMessage] = useState("Loading rides...");

  // onload
  useEffect(() => {
    setTimeout(() => {
      setLoadingMessage("Crunching the numbers...");
    }, 3000);
    setTimeout(() => {
      setLoadingMessage("Still working...");
    }, 10000);
    setTimeout(() => {
      setLoadingMessage("Looks like something went wrong. Please refresh or try again later.");
    }, 20000);
  }, []);


  const styles = {
    container: {
      height: 500,
      marginTop: "10%"
    },
  };
  return (
    <>
      <div style={styles.container}>
      <Row className="text-center">
        <h3>
          { loadingMessage }
        </h3>
        </Row>
      </div>
    </>
  );
}

export default Loading;
