import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Translate from "../components/Translate";
import useUserStore from "../components/UserStore";
import wattbikeLogo from "../assets/img/wattbike_logo_410_black.avif";
import { Row, Col } from "react-bootstrap";

const Header = () => {
  const { user, signedIn, setSignedIn } = useUserStore((state) => state);

  const styles = {
    navBar: {
      height: 60,
      borderBottom: "2px solid #b2ff00",
      backgroundColor: "white",
      zIndex: 1000,
    },
    navBarCollapsed: {
      backgroundColor: "white",
      // paddingTop: 10,
    },
    navBarSmall: {
      height: 30,
      backgroundColor: "#f5f5f5",
      fontSize: 15,
    },
    logo: {
      width: 140,
      padding: 0,
      margin: 0,
    },
    link: {
      fontFamily: "trim-regular",
      textDecoration: "none",
      textTransform: "uppercase",
    },
    linkHighlight: {
      fontFamily: "trim-regular",
      textDecoration: "underline",
      textTransform: "uppercase",
    },
  };

  return (
    <Container>
      <Row>
        <Navbar data-bs-theme="light" style={styles.navBarSmall}>
          <Container>
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <Link to={"/preferences"}>{user.objectId}</Link>
                {user.coach ? " (Coach)" : " (Not coach)"}
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Row>

      <Row>
        <Navbar expand="md" bg="white" data-bs-theme="light" style={styles.navBar}>
          <Container fluid>
            <Navbar.Brand>
              <Nav.Link as={Link} to="/dashboard">
                <img
                  src={wattbikeLogo}
                  alt="Wattbike Logo"
                  style={styles.logo}
                />
              </Nav.Link>
            </Navbar.Brand>

            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end" style={styles.navBarCollapsed}>
              <Navbar.Text>
                <Nav className="me-auto">
                  <Nav.Link as={Link} to="/dashboard" style={styles.link}>
                    <Translate label={"navigation-dashboard"} />
                  </Nav.Link>
                  <Nav.Link as={Link} to="/share" style={styles.link}>
                    <Translate label={"navigation-share-details"} />
                  </Nav.Link>
                  <Nav.Link as={Link} to="/export" style={styles.link}>
                    <Translate label={"navigation-export"} />
                  </Nav.Link>
                  <Nav.Link as={Link} to="/preferences" style={styles.link}>
                    <Translate label={"navigation-preferences"} />
                  </Nav.Link>
                  {/* <Nav.Link
                    as={Link}
                    to="https://wattbike.typeform.com/to/L9j14BCJ"
                    target="_blank"
                    style={styles.linkHighlight}
                  >
                    <Translate label={"navigation-feedback"} />
                  </Nav.Link> */}
                  <Nav.Link
                    as={Link}
                    to="https://wattbike.com/"
                    target="_blank"
                    style={styles.link}
                  >
                    wattbike.com
                  </Nav.Link>
                  {user.objectId === 'u-167561f9bdf04214' ? (
                    <Nav.Link as={Link} to="/live" style={styles.link}>
                      <Translate label={"navigation-live"} />
                    </Nav.Link>
                  ) : null}
                </Nav>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Row>
    </Container>
  );
};

export default Header;
