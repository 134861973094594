import React, { ChangeEventHandler, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import Button from "react-bootstrap/Button";
import { ChevronDown } from "react-bootstrap-icons";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const DateRangePicker = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedRange, setSelectedRange] = useState(props.value);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [isOpen, setisOpen] = useState(false);

  const today = new Date();

    let sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    let thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    let ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

  useEffect(() => {

  }, []);

  const handleRangeSelect = (range) => {
    setSelectedRange(range);
    if (range?.from) {
      setFromValue(format(range.from, "y-MM-dd"));
    } else {
      setFromValue("");
    }
    if (range?.to) {
      setToValue(format(range.to, "y-MM-dd"));
    } else {
      setToValue("");
    }
  };

  const handleApply = () => {
    props.setDateRange(selectedRange);
    console.log("range: ", selectedRange);
    setisOpen(false);
  };

  const handleClose = () => {
    setisOpen(false);
  };

  const handleOpen = () => {
    setisOpen(true);
  };

  const styles = {
    container: {
      position: "absolute",
      backgroundColor: "white",
      padding: 10,
      textAlign: "right",
      // width: 325,
      zIndex: 1000,
      border: "1px solid #999",
      borderRadius: 10,
    },
    quickLinkContainer: {
      marginTop: 70,
      borderRight: "1px solid",
      textAlign: "left",
      paddingLeft: 20,
      paddingRight: 30,
    },
    quickLink: {
      display: "inline-block",
      marginTop: 10,
      padding: 0
    },
    minimisedContainer: {
      backgroundColor: "white",
      display: "inline-block",
      padding: 5,
      paddingLeft: 10,
      paddingTop: 10,
      borderRadius: 4,
      border: "1px solid rgba(0, 0, 0, 0.12)"
    }
  };

  if (loading) {
    return <div></div>;
  } else if (!isOpen) {
    return (
      <div style={styles.minimisedContainer} onClick={() => handleOpen()}>
        {selectedRange.from.toLocaleDateString()} - {selectedRange.to.toLocaleDateString()}
        <ChevronDown
          style={{ fill: "#666", fontSize: "1.2em", marginLeft: 10, marginRight: 10, marginBottom: 5, cursor: "pointer" }}
          onClick={() => handleOpen()}
        />
      </div>
    );
  } else {
    return (
      <div style={styles.container} className="text-end">
        <Row className="text-end">
        <Col style={styles.quickLinkContainer}>
          <b>Quick Links</b><br />
          <Link style={styles.quickLink} onClick={() => { handleRangeSelect({from: sevenDaysAgo, to: today}); props.setDateRange({from: sevenDaysAgo, to: today}); setisOpen(false); } } >Last 7 days</Link> <br />
          <Link style={styles.quickLink} onClick={() => { handleRangeSelect({from: thirtyDaysAgo, to: today}); props.setDateRange({from: thirtyDaysAgo, to: today}); setisOpen(false);} }>Last 30 days</Link> <br />
          <Link style={styles.quickLink} onClick={() => { handleRangeSelect({from: ninetyDaysAgo, to: today}); props.setDateRange({from: ninetyDaysAgo, to: today}); setisOpen(false);} }>Last 90 days</Link> <br />
          {/* <Link style={styles.quickLink} onClick={() => null} >This week</Link> <br />
          <Link style={styles.quickLink} onClick={() => null} >This month</Link> <br /> */}
        </Col>
        <Col>
          <DayPicker
            mode="range"
            selected={selectedRange}
            onSelect={handleRangeSelect}
            // footer={
            //   <form className="ma2">
            //     <input
            //       size={10}
            //       placeholder="From Date"
            //       value={fromValue}
            //       onChange={handleFromChange}
            //     />
            //     {" – "}
            //     <input
            //       size={10}
            //       placeholder="To Date"
            //       value={toValue}
            //       onChange={handleToChange}
            //     />
            //   </form>
            // }
          />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="secondary" onClick={() => handleClose()}>
              Cancel
            </Button>
            &nbsp;
            <Button variant="primary" onClick={() => handleApply()}>
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
};

//

export default DateRangePicker;
