import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
// import UndoIcon from '@mui/icons-material/Undo';
// import IconButton from '@mui/material/IconButton';

export default function BackButton() {
  const navigate = useNavigate();

  const link = {
    color: "grey",
    borderRadius: "12px",
    backgroundColor: "white",
    // padding: "8px",
    fontFamily: "Arial",
    textDecoration: "none"
  };

  const container = {
    marginBottom: "5px",
  };


  return (
    // <IconButton
    //   aria-label="back"
    //   size="small"
    //   onClick={() => {
    //     navigate(-1);
    //   }}
    //   style={style}
    // >
    //   <UndoIcon />
    // </IconButton>
    <div style={container} >
      <Link 
      style={link} 
      onClick={() => { navigate(-1); }}
      >
        {"< BACK"}
      </Link>
    </div>
  );
}