import { Link } from "react-router-dom";
import Translate from "../components/Translate";
import Header from "../components/Header";
import { Container } from "react-bootstrap";

const CoachLinkLayout = (props) => {
  return (
    <div>
      {/* <Header /> */}
      <Container style={styles.content}>
        <div>{props.children}</div>
      </Container>
    </div>
  );
};

const styles = {
    content: {
      padding: "20px"
    }
  }

export default CoachLinkLayout;
