import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

import Router from './Router'

export default function App() {

  return (
    <div >
      <Router />
    </div>
  )

}