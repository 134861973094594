import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { hubGetFile } from "../utils/hubApiRequest";

const SessionMiniChartPower = (props) => {
  const [loading, setLoading] = useState(false);
  const [chartOptions, setChartOption] = useState({});
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {

    const formatDate = async () => {
      setLoading(true);

      const file = await hubGetFile({ fileName: props.fileName, cache: true });
      if (file){
        const revolutions = await formatRevolutions(file.data.revolutions);
        await updateOptions(revolutions);
      } else {
        await setNotFound(true);
      }

      setLoading(false);
    };
    formatDate().catch("Mini chart error: ", console.error);

  }, [props.fileName]);

  //
  const formatRevolutions = (revolutions) => {
    return new Promise((resolve, reject) => {
      let formattedRevolutions = [];
      for (let i = 0; i < revolutions.length; i++) {
        formattedRevolutions.push([revolutions[i].time, revolutions[i].power]);
      }
      resolve(formattedRevolutions);
    })
  };

  const updateOptions = (revolutions) => {
    let chartOptions = {
      chart: {
        type: "area",
        // backgroundColor: chartTheme[that.state.theme].background,
        animation: false,
        margin: [2, 0, 2, 0],
        width: 120,
        height: 30,
        style: {
          overflow: "visible",
        },
        // small optimalization, saves 1-2 ms each sparkline
        skipClone: true,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tooltip: {
        hideDelay: 0,
        outside: true,
        shared: true,
        formatter: function () {
          return Math.round(this.y) + " W";
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          data: revolutions,
          pointStart: 1,
        },
      ],
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 1,
          shadow: false,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 2,
              },
            },
          },
          fillOpacity: 0.25,
        },
        column: {
          negativeColor: "#910000",
          borderColor: "silver",
        },
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tickPositions: [0],
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
      },
    };

    setChartOption(chartOptions);
  };

  if (loading) {
    return <div>Loading...</div>;
  } else if (notFound) {
    return "Not found";
  } else {
    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  }

};

export default SessionMiniChartPower;
