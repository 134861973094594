////////////////////////////////////////////////////////////////
// Handles the low level API requests and related local caching
////////////////////////////////////////////////////////////////
import axios from "axios";
import localDB from "../utils/LocalDB";
var hash = require("object-hash");

/**
 * ---------------------------------------------
 * Low level Get request
 * Performs a get request on the API or returns local data if cache is requested and found
 * ---------------------------------------------
 * @param {Object} request - Object containing the request
 * @param {Object} request.authUser (required) Parse user object
 * @param {String} request.resource (required) the resource required e.g. 'rideSession'
 * @param {Bool|null} request.cache (optional) should we look for this response in cache and cache any result
 * @param {Object|null} request.where (optional) string representation of object e.g. '{state: "ready"}'
 * @param {Array|null} request.include (optional) array of strings, any pointer collections to include
 * @param {ObjIntect|null} request.limit (optional) default is 1000
 * @param {Int|null} request.skip (optional) how many items to skip - used for pagination
 * @param {Object|null} request.order (optional)
 * @returns {Object} - API response
 * ---------------------------------------------
 */
const hubGet = async (request) => {

  try {
    // check local cache
    let requestHash = hash([process.env.REACT_APP_CACHE_ID, process.env.REACT_APP_CUSTOM_STAGE, request]);
    if (request.cache) {
      // console.log("looking in cache");
      let cacheResponse = await localDB.localCache.get(requestHash);
      if (cacheResponse) {
        // console.log("found in cache");
        return cacheResponse.value;
      } else{
        // console.log("not found in cache");
      }
    } else {
      // console.log("API response from server");
    }

    // Build the query params
    let params = {};

    if (request.where) {
      params["where"] = request.where;
    }
    if (request.include) {
      params["include"] = request.include;
    }
    if (request.limit) {
      params["limit"] = request.limit;
    }
    if (request.skip) {
      params["skip"] = request.skip;
    }
    if (request.order) {
      params["order"] = request.order;
    }

    // build headers
    let headers = {};
    if (request.authUser) {
      // console.log("getting with user");
      headers = {
        "Content-Type": "application/json",
        "X-Parse-Session-Token": request.authUser.sessionToken,
        "X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
        "X-Parse-Javascript-Key": process.env.REACT_APP_JS_KEY,
      };
    } else {
      // console.log("getting without user");
      headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
        "X-Parse-Javascript-Key": process.env.REACT_APP_JS_KEY,
      };
    }

    // Build the request
    const axiosOptions = {
      method: "GET",
      url: process.env.REACT_APP_API_URL + "classes/" + request.resource,
      headers: headers,
      params: params,
    };

    // Make the API call
    // console.log("axiosOptions: ", axiosOptions);
    const { data } = await axios(axiosOptions);

    // save response to cache
    if (request.cache) {
      localDB.localCache.put({ key: requestHash, value: data });
    }

    return data;
  } catch (err) {
    throw new Error("Response Error: " + err);
  }
};

/**
 * ---------------------------------------------
 * Low level PUT request
 * Update a record on the API, any fields omitted will remain the same
 * ---------------------------------------------
 * @param {Object} request - Object containing the request
 * @returns {Object} - API response
 * ---------------------------------------------
 */
const hubPut = async (request) => {
  try {

    // Build the query params
    // let dataToUpdate = request.data;

    // build headers
    let headers = {};
    if (request.authUser) {
      headers = {
        "Content-Type": "application/json",
        "X-Parse-Session-Token": request.authUser.sessionToken,
        "X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
        "X-Parse-Javascript-Key": process.env.REACT_APP_JS_KEY,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
        "X-Parse-Javascript-Key": process.env.REACT_APP_JS_KEY,
      };
    }

    // Build the request
    const axiosOptions = {
      method: "put",
      url: process.env.REACT_APP_API_URL + request.resource,
      headers: headers,
      data: request.data,
    };

    // Make the API call
    // console.log("PUT axiosOptions: ", axiosOptions);
    const response = await axios(axiosOptions);

    return response;
  } catch (err) {
    throw new Error("Response Error: " + JSON.stringify(request));
  }
};

/**
 * ---------------------------------------------
 * Low level File request
 * Get file contents and/or return cache data
 * ---------------------------------------------
 * @param {Object} request - Object containing the request
 * @param {String} request.fileName (required) filename e.g. 'some_file.wbs'
 * @param {Bool|null} request.cache (optional) should we look for this response in cache and cache any result
 * @returns {Object} - API response
 * ---------------------------------------------
 */
const hubGetFile = async (request) => {
  try {
    if (request.cache) {
      let fileStore = await localDB.localCache.get({ key: request.fileName });
      if (fileStore) {
        // console.log("Local file found");
        return JSON.parse(fileStore.value);
      } else {
        // console.log("Local file NOT found: ", request.fileName);
      }
    } else {
      // console.log("File from remote: ", request);
    }

    // Build the request
    const axiosOptions = {
      method: "GET",
      url: process.env.REACT_APP_API_FILES_URL + "files/" + request.fileName,
    };

    // Make the API call
    const file = await axios(axiosOptions);

    // save response to cache
    if (request.cache) {
      // console.log("caching file", request.fileName);
      localDB.localCache.put({
        key: request.fileName,
        value: JSON.stringify(file),
      });
    }

    return file;
  } catch (err) {
    // console.log("File Error: " + err);
    throw new Error("File Error: " + err);
  }
};

export { hubGet, hubPut, hubGetFile };
