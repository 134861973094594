import React from "react";
import useUserStore from "../components/UserStore";
import PrimaryLayout from "../components/PrimaryLayout";
import { Link } from "react-router-dom";
import logo from "../assets/img/wattbike_logo_410x.avif";

function Terms() {
  document.title = "Hub Clubs Terms & Conditions of Use";

  const { user, signedIn, setSignedIn } = useUserStore((state) => state);

  const styles = {};

  return (
    <>
      <PrimaryLayout>
        <div class="header">
          <h1>Terms &amp; Conditions</h1>
        </div>
        <div>
          <hr />
          <p>
            The Wattbike websites, mobile and desktop applications and services
            (the "Site") are made available to you by Wattbike Ltd. ("Wattbike")
            subject to these terms and conditions and attached Privacy Policy.
            By accessing, using, uploading or downloading any materials from the
            Site, you agree to follow and be bound by the Terms, which may be
            updated by Wattbike from time to time without notice to you.
            Wattbike and its third party providers may make improvements and/or
            changes in the products, services, mobile applications, features,
            programs, and prices described in this Site at any time without
            notice. Certain provisions of the Terms may be supplemented or
            superseded by expressly designated legal notices or terms located on
            particular pages at this Site. If you do not agree with the Terms,
            please do not use this Site. BY CONTINUING TO USE THE SITE, YOU ARE
            INDICATING YOUR AGREEMENT TO THE TERMS AND ALL REVISIONS THEREOF.
          </p>
          <p>
            Wattbike reserves the right at any time and from time to time to
            modify or discontinue, temporarily or permanently, the Site or any
            portion thereof with or without notice. You agree that Wattbike
            shall not be liable to you or to any third party for any
            modification, suspension or discontinuance of the Site or any
            portion thereof.
          </p>
          <h2>Registration</h2>
          <p>
            To use the services available on the Site, you must complete the
            membership registration form. You agree to: (a) provide true,
            accurate, current and complete information about yourself as
            prompted by the Site's registration form ("User Data") and (b)
            maintain and promptly update the User Data to keep it accurate and
            current. You agree that Wattbike may use your User Data to provide
            services on the Site for which you have expressed interest. If you
            provide any information that is inaccurate or not current, or
            Wattbike has reasonable grounds to suspect that such information is
            inaccurate or not current, Wattbike has the right to suspend or
            terminate your account and refuse any and all current or future use
            of the Site. In consideration of your use of the Site, you represent
            that you are of legal age to form a binding contract and are not a
            person barred from receiving services under the laws of any
            applicable jurisdiction.
          </p>
          <h2>User Conduct</h2>
          <p>
            You are solely responsible for maintaining the confidentiality of
            the password associated with your account and for restricting access
            to your password and to your computer while logged into the Site.
            You accept responsibility for all activities that occur under your
            account or from your computer. We endeavor to use reasonable
            security measures to protect against unauthorized access to your
            account. We cannot, however, guarantee absolute security of your
            account, your Content or the personal information you provide, and
            we cannot promise that our security measures will prevent
            third-party "hackers" from illegally accessing the Site or its
            contents. You agree to immediately notify Wattbike of any
            unauthorized use or your account or password, or any other breach of
            security, and to accept all risks of unauthorized access to the User
            Data and any other information you provide to Wattbike.
          </p>
          <p>
            You understand that all information, data, text, software, sound,
            photographs, graphics, video, messages, tags, or other materials
            ("Content"), whether publicly posted or privately transmitted,
            available in connection with the Site are the sole responsibility of
            the person from whom such Content originated. This means that you,
            and not Wattbike, are entirely responsible for all Content that you
            upload, post, email, transmit or otherwise make available via the
            Site. Wattbike does not control or monitor the Content posted to the
            Site by others and, as such, does not guarantee the accuracy,
            integrity or quality of such Content. You understand that by using
            the Site, you may be exposed to Content that is offensive, indecent
            or objectionable. Under no circumstances will Wattbike be liable in
            any way for any Content, including, but not limited to, any errors
            or omissions in any Content, or any loss or damage of any kind
            incurred as a result of the use of any Content posted, emailed,
            transmitted or otherwise made available on the Site.
          </p>
          <p>
            You agree to not use the Site to: (a) upload, post, email, transmit
            or otherwise make available any Content that is unlawful, harmful,
            threatening, abusive, harassing, tortious, defamatory, vulgar,
            obscene, libelous, invasive of another's privacy, hateful, or
            racially, ethnically or otherwise objectionable; (b) harm minors in
            any way; (c) impersonate any person or entity, including, but not
            limited to, a Wattbike official, forum leader, guide or host, or
            falsely state or otherwise misrepresent your affiliation with a
            person or entity; (d) upload, post, email, transmit or otherwise
            make available any Content that you do not have a right to make
            available under any law or under contractual or fiduciary
            relationships; (e) upload, post, email, transmit or otherwise make
            available any Content that infringes any patent, trademark, trade
            secret, copyright or other proprietary rights of any party; (f)
            upload, post, email, transmit or otherwise make available any
            unsolicited or unauthorized advertising, promotional materials,
            "junk mail," "spam," "chain letters," "pyramid schemes," or any
            other form of solicitation; (g) upload, post, email, transmit or
            otherwise make available any material that contains software viruses
            or any other computer code, files or programs designed to interrupt,
            destroy or limit the functionality of any computer software or
            hardware or telecommunications equipment; (h) interfere with or
            disrupt the Site or servers or networks connected to the Site, or
            disobey any requirements, procedures, policies or regulations of
            networks connected to the Site; and/or (i) intentionally or
            unintentionally violate any applicable local, state, national or
            international law. You specifically agree not to access (or attempt
            to access) the Site or the Content through any automated means
            (including the use of any script, web crawler, robot, spider, or
            scraper), and that you will not forge or manipulate identifiers in
            order to disguise the origin of any access (or attempted access) to
            the Site.
          </p>
          <p>
            You acknowledge that Wattbike may or may not pre-screen or monitor
            Content, but that Wattbike and its designees shall have the right
            (but not the obligation) in their sole discretion to pre-screen,
            monitor, refuse or remove any Content that is available via the
            Site. Without limiting the foregoing, Wattbike and its designees
            shall have the right to remove any Content that violates the Terms
            or is otherwise objectionable. You agree that you must evaluate, and
            bear all risks associated with, the use of any Content available in
            connection with the Site, including any reliance on the accuracy,
            completeness, or usefulness of such Content.
          </p>
          <p>
            You acknowledge, consent and agree that Wattbike may access,
            preserve and disclose your User Data, Payment Method information and
            other Content if required to do so by law or in a good faith belief
            that such access preservation or disclosure is reasonably necessary
            to: (a) comply with legal process; (b) enforce the Terms; (c)
            respond to claims that any Content violates the rights of third
            parties; (d) respond to your requests for customer service; or (e)
            protect the rights, property or personal safety of Wattbike, its
            users and the public. Subject to the foregoing, Wattbike will use
            reasonable efforts to maintain the confidentiality of your User Data
            and Payment Method information.
          </p>
          You may not modify, copy, distribute, transmit, display, perform,
          reproduce, publish, license, create derivative works from, transfer or
          sell for any commercial purposes any portion of the Site, use of the
          Site or access to the Site other than through features within the Site
          designed to support sharing of content.
          <p>
            You understand that use of certain features of the Site may require
            you to purchase third party equipment or materials (e.g., GPS
            systems). While Wattbike may recommend the equipment or materials of
            certain third party suppliers, Wattbike shall have no responsibility
            for your acquisition or use of any third party equipment or
            materials and does not guarantee that third party equipment or
            materials will function with the Site or will be error-free.
          </p>
          <h2>Content Submitted to the Site</h2>
          <p>
            Wattbike does not claim ownership of Content you may submit or make
            available for inclusion on the Site. However, with respect to
            Content you submit or make available for inclusion on the Site
            (other than User Data or Payment Method information), you grant
            Wattbike a worldwide, perpetual, irrevocable, royalty-free and
            non-exclusive license, as applicable, to use, distribute, reproduce,
            modify, adapt, publicly perform and publicly display and otherwise
            exploit such Content on the Site for the purposes of providing and
            promoting the Site and the features and services available on the
            Site.
          </p>
          <p>
            The Site may provide you with the option of making certain Content
            that you submit to the Site as private or available only to select
            users of the Site. If, upon submission of Content to the Site, you
            initially elect to mark such Content as private or available for a
            limited group of users, Wattbike will maintain the privacy of such
            Content in accordance with your election. However, if you do not
            elect to mark your Content as private or available for a limited
            group of users, or later change such designation to allow such
            Content to be made generally available, Wattbike can not and does
            not guarantee the privacy of such Content.
          </p>
          <h2>Dealings with Third Party Service Providers and Advertisers</h2>
          <p>
            Third party product and service offerings made available for
            purchase by you on the Site are made and offered directly by the
            applicable third party service provider or advertiser. When you
            purchase any such product or service offering through the Site, you
            acknowledge that you are contracting directly with the applicable
            third party service provider or advertiser and not with Wattbike.
            Your correspondence or business dealings with, or participation in
            promotions of, third party service providers or advertisers found on
            or through the Site, including payment and delivery of related goods
            or services, and any other terms, conditions, warranties or
            representations associated with such dealings, are solely between
            you and such third party service provider or advertiser. Although we
            are interested in receiving feedback regarding our third party
            service providers and advertisers and their products and services,
            and may from time to time assist you in your dealings with such
            third party service providers and advertisers, Wattbike is not
            responsible for the performance or nonperformance of any third party
            service provider or advertiser. You are in no way obligated to use
            or transact business with any particular third party service
            provider or advertiser that appears on the Site. YOU AGREE THAT
            WATTBIKE SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGES OF ANY KIND
            INCURRED BY YOU AS THE RESULT OF ANY OF YOUR DEALINGS WITH THIRD
            PARTY SERVICE PROVIDERS OR ADVERTISERS AVAILABLE ON THE SITE.
          </p>
          <h2>Links</h2>
          <p>
            The Site may provide, or third parties may provide, links to other
            Internet sites or resources. Because Wattbike has no control over
            such sites and resources, you acknowledge and agree that Wattbike is
            not responsible for the availability of such external sites or
            resources, and does not endorse and is not responsible or liable for
            any Content, advertising, products or other materials on or
            available from such sites or resources.
          </p>
          <p>
            You are granted a limited, non-exclusive right to create a text
            hyperlink to the Site, provided such link does not portray Wattbike
            or any of its products or services in a false, misleading,
            derogatory or otherwise defamatory manner, and provided further that
            the linking site does not contain any pornographic, illegal,
            offensive, harassing or otherwise objectionable material. You are
            further granted a right to implement any RSS feeds located on the
            Site for your personal, non-commercial use, solely as described on
            the Site. We reserve the right to revoke these licenses generally,
            or your right to use specific links or RSS feeds, at any time, with
            or without cause.
          </p>
          <h2>Interactions with Site Users</h2>
          <p>
            The Site functions as a venue to connect members in a virtual
            information place. As a neutral facilitator, Wattbike is not
            directly involved in the actual transactions between members of the
            Site. As a result, Wattbike has no control over the truth, accuracy,
            quality, legality, or safety of postings made by users of the Site.
            Wattbike shall have no responsibility to confirm the identity of
            members. Wattbike shall also have no responsibility to confirm or
            verify the qualifications, background, or abilities of users of the
            Site. You shall at all time exercise common sense and good judgment
            when dealing with any user of the Site.
          </p>
          <p>
            If you elect to use our "share-a-ride" service or similar Site
            features for informing a friend about our Site or for emailing a
            ride or other information to a friend, Wattbike will require you to
            provide your friend's contact information and/or email address.
            Wattbike will automatically send that friend a one-time email.
            Wattbike may store the information you provide for a period of time,
            provided that Wattbike will use such information only to identify if
            your friend is or later becomes a member of Wattbike. You represent
            that you are authorized to provide any third party email address or
            other information that you provide to Wattbike.
          </p>
          <h2>Electronic Communications</h2>
          <p>
            The Site may provide you with the ability to send emails, post
            messages to user forums, enter chat rooms, speak via Internet voice
            connections or send similar messages and communications to third
            party service providers, advertisers, other users and/or Wattbike.
            You agree to use communication methods available on the Site only to
            send communications and materials related to the subject matter for
            which Wattbike provided the communication method, and you further
            agree that all such communications by you shall be deemed your
            Content and shall be subject to and governed by the Terms. By using
            any of the communications methods available on the Site, you
            acknowledge and agree that (a) all communications methods constitute
            public, and not private, means of communication between you and the
            other party or parties, (b) communications sent to or received from
            third party service providers, advertisers or other third parties
            are not be endorsed, sponsored or approved by Wattbike (unless
            expressly stated otherwise by Wattbike) and (c) communications are
            not pre-reviewed, post-reviewed, screened, archived or otherwise
            monitored by Wattbike in any manner, though Wattbike reserves the
            right to do so at any time at its sole discretion in accordance with
            the Terms. You agree that all notices, disclosures and other
            communications that we provide to you electronically shall satisfy
            any legal requirement that such communications be in writing.
          </p>
          <h2>Proprietary Rights</h2>
          <p>
            You acknowledge and agree that the Site, any necessary software used
            in connection with the Site (if any) and any Content available on
            the Site contain proprietary and confidential information that is
            protected by applicable intellectual property and other laws. Except
            as expressly permitted by applicable law or authorized by Wattbike
            or applicable third party service providers or advertisers, you
            agree not to modify, rent, lease, loan, sell, distribute or create
            derivative works based on the Site, the software or Content
            available on the Site (other than Content that you may submit), in
            whole or in part.
          </p>
          <p>
            Wattbike grants you a personal, non-transferable and non-exclusive
            right and license to access and use the Site; provided that you do
            not (and do not allow any third party to) copy, modify, create a
            derivative work from, reverse engineer, reverse assemble or
            otherwise attempt to discover any source code, sell, assign,
            sublicense, grant a security interest in or otherwise transfer any
            right in the Site. You agree not to access the Site by any means
            other than through the interface that is provided by Wattbike for
            use in accessing the Site.
          </p>
          <p>
            The term Wattbike, the Wattbike logo and other Wattbike logos and
            product and service names are the exclusive trademarks of, and are
            owned by, Wattbike Ltd., and you may not use or display such
            trademarks in any manner without Wattbike's prior written
            permission. Any third party trademarks or service marks displayed on
            the Site are the property of their respective owners.
          </p>
          <p>Wattbike reserves all rights not expressly granted hereunder.</p>
          <h2>Claims of Copyright Infringement</h2>
          <p>
            If you have reason to believe any part of the Content of the Site
            infringes the copyrights of others, please notify our Copyright
            Agent immediately using the contact information provided below. It
            is our policy to investigate any allegations of copyright
            infringement brought to our attention. We reserve the right in our
            sole discretion to immediately suspend and/or terminate access to
            any Site by any user who is alleged to have posted infringing
            materials or a link to infringing materials on the Site and to
            immediately remove or disable the allegedly infringing Content or
            link.
          </p>
          <p>
            If you are the copyright owner (or are authorized to act on behalf
            of the copyright owner), please notify our Copyright Agent
            immediately if you believe that (a) any Content displayed on the
            Site infringes your copyright or (b) any link posted on the Site
            links to materials that infringe your copyright. As soon as we
            receive your notice of claimed infringement, in the form described
            below, we will promptly remove or disable access to the materials
            that are claimed to be infringing (or the subject of infringing
            activity). Your notice must be in writing and must include the
            following: a description of the copyrighted work you believe has
            been infringed (or if you believe multiple copyrighted works have
            been infringed, a representative list); a description of the
            material you believe is infringing or the subject of infringing
            activity, together with enough information to permit us to locate
            the material on the applicable Site; enough information to permit us
            to contact you, such as, your name, address, telephone number and,
            if available, email address; a statement that you have a good faith
            belief that the allegedly infringing use of the material was not
            authorized by the owner of the exclusive right that is allegedly
            infringed (the "copyright owner"), an agent for the copyright owner,
            or by law; a statement that all of the information you have provided
            is accurate; and a statement, made under penalty of perjury, that
            you are the copyright owner or are authorized to act on behalf of
            the copyright owner. Your notice must be signed (physically or
            electronically) and must be addressed as follows:
          </p>
          <p>
            Mr Pete Lay
            <br />
            Wattbike Ltd.
            <br />
            Unit 13
            <br />
            Nottingham South and Wilford Industrial Estate
            <br />
            Ruddington Lane
            <br />
            Wilford
            <br />
            Nottingham
            <br />
            NG11 7EP
          </p>
          <h2>Your Feedback</h2>
          <p>
            We welcome your comments, feedback, suggestions, and other
            communications regarding the Site and the information and services
            we make available through the Site (collectively, "Feedback"). While
            you are not obligated to provide Feedback, in the event that you
            provide Feedback, you hereby grant to Wattbike a worldwide,
            non-exclusive, transferrable, assignable, sub-licenseable,
            perpetual, irrevocable, royalty-free license to copy, distribute,
            create derivative works of, publicly display and perform and
            otherwise exploit such Feedback and to use, make, have made, sell,
            offer for sale, import and export products and services based on
            such Feedback. For this reason, we ask that you not send Wattbike
            any Feedback that you do not wish to license to us as set forth
            above.
          </p>
          <h2>Disclaimer of Warranties and Liability</h2>
          <p>
            THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND CONTENT AVAILABLE
            ON THE SITE IS PROVIDED TO YOU "AS IS" AND WITHOUT WARRANTY.
            WATTBIKE AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES,
            AGENTS, PARTNERS AND LICENSORS HEREBY DISCLAIM ALL WARRANTIES WITH
            REGARD TO SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
            CONTENT, INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES AND
            CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            TITLE AND NONINFRINGEMENT. WATTBIKE AND ITS SUBSIDIARIES,
            AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE
            NO WARRANTY THAT (a) THE SERVICE WILL MEET YOUR REQUIREMENTS; (b)
            THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (c)
            THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE
            ACCURATE OR RELIABLE; (d) THE QUALITY OF ANY PRODUCTS, SERVICES,
            INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH
            THE SERVICE WILL MEET YOUR EXPECTATIONS; AND (e) ANY ERRORS IN THE
            SITE WILL BE CORRECTED.
          </p>
          <p>
            YOU EXPRESSLY AGREE THAT WATTBIKE IS NOT PROVIDING MEDICAL ADVICE
            VIA THE SITE. THE CONTENT PROVIDED THROUGH THE SITE, INCLUDING ALL
            TEXT, PHOTOGRAPHS, IMAGES, ILLUSTRATIONS, GRAPHICS, AUDIO, VIDEO AND
            AUDIO-VIDEO CLIPS, AND OTHER MATERIALS, WHETHER PROVIDED BY US OR BY
            OTHER ACCOUNT HOLDERS OR THIRD PARTIES IS NOT INTENDED TO BE AND
            SHOULD NOT BE USED IN PLACE OF (a) THE ADVICE OF YOUR PHYSICIAN OR
            OTHER MEDICAL PROFESSIONALS, (b) A VISIT, CALL OR CONSULTATION WITH
            YOUR PHYSICIAN OR OTHER MEDICAL PROFESSIONALS, OR (c) INFORMATION
            CONTAINED ON OR IN ANY PRODUCT PACKAGING OR LABEL. SHOULD YOU HAVE
            ANY HEALTH RELATED QUESTIONS, PLEASE CALL OR SEE YOUR PHYSICIAN OR
            OTHER HEALTHCARE PROVIDER PROMPTLY. SHOULD YOU HAVE AN EMERGENCY,
            CALL YOUR PHYSICIAN OR 911 IMMEDIATELY. YOU SHOULD NEVER DISREGARD
            MEDICAL ADVICE OR DELAY IN SEEKING MEDICAL ADVICE BECAUSE OF ANY
            CONTENT PRESENTED ON THIS SITE, AND YOU SHOULD NOT USE THE SITE OR
            ANY CONTENT ON THE SITE FOR DIAGNOSING OR TREATING A HEALTH PROBLEM.
            THE TRANSMISSION AND RECEIPT OF OUR CONTENT, IN WHOLE OR IN PART, OR
            COMMUNICATION VIA THE INTERNET, EMAIL OR OTHER MEANS DOES NOT
            CONSTITUTE OR CREATE A DOCTOR-PATIENT, THERAPIST-PATIENT OR OTHER
            HEALTHCARE PROFESSIONAL RELATIONSHIP BETWEEN YOU AND WATTBIKE.
          </p>
          <p>
            YOU EXPRESSLY AGREE THAT YOUR ATHLETIC ACTIVITIES, WHICH GENERATE
            THE CONTENT YOU POST OR SEEK TO POST ON THE SITE (INCLUDING BUT NOT
            LIMITED TO CYCLING) CARRY CERTAIN INHERENT AND SIGNIFICANT RISKS OF
            PROPERTY DAMAGE, BODILY INJURY OR DEATH AND THAT YOU VOLUNTARILY
            ASSUME ALL KNOWN AND UNKNOWN RISKS ASSOCIATED WITH THESE ACTIVITIES
            EVEN IF CAUSED IN WHOLE OR PART BY THE ACTION, INACTION OR
            NEGLIGENCE OF WATTBIKE OR BY THE ACTION, INACTION OR NEGLIGENCE OF
            OTHERS. YOU ALSO EXPRESSLY AGREE THAT WATTBIKE DOES NOT ASSUME
            RESPONSIBILITY FOR THE INSPECTION, SUPERVISION, PREPARATION, OR
            CONDUCT OF ANY RACE, CONTEST, GROUP RIDE OR EVENT THAT UTILIZES
            WATTBIKE'S SITE.
          </p>
          <p>
            YOU EXPRESSLY AGREE TO RELEASE WATTBIKE, ITS SUBSIDIARIES,
            AFFILIATES, OFFICERS, AGENTS, REPRESENTATIVES, EMPLOYEES, PARTNERS
            AND LICENSORS (THE "RELEASED PARTIES") FROM ANY AND ALL LIABILITY
            CONNECTED WITH YOUR ATHLETIC ACTIVITIES, AND PROMISE NOT TO SUE THE
            RELEASED PARTIES FOR ANY CLAIMS, ACTIONS, INJURIES, DAMAGES, OR
            LOSSES ASSOCIATED WITH YOUR ATHLETIC ACTIVITIES. YOU ALSO AGREE THAT
            IN NO EVENT SHALL THE RELEASED PARTIES BE LIABLE TO YOU OR ANY THIRD
            PARTY FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR
            CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH
            (a) YOUR USE OR MISUSE OF THE SITE, (b) YOUR USE OR MISUSE OF
            EQUIPMENT OR PROGRAMS CREATED OR LICENSED BY WATTBIKE WHILE ENGAGED
            IN ATHLETIC ACTIVITIES, (c) YOUR DEALINGS WITH THIRD PARTY SERVICE
            PROVIDERS OR ADVERTISERS AVAILABLE THROUGH THE SITE, (d) ANY DELAY
            OR INABILITY TO USE THE SITE EXPERIENCED BY YOU, (e) ANY
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES OR CONTENT OBTAINED
            THROUGH THE SITE, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY
            OR OTHERWISE, EVEN IF WATTBIKE HAS BEEN ADVISED OF THE POSSIBILITY
            OF DAMAGES.
          </p>
          <h2>Indemnity</h2>
          <p>
            You agree to indemnify and hold Wattbike and its subsidiaries,
            affiliates, officers, agents, representatives, employees, partners
            and licensors harmless from any claim or demand, including
            reasonable attorneys' fees, made by any third party due to or
            arising out of Content you submit, post, transmit or otherwise seek
            to make available through the Site, your use of the Site, your
            athletic activities which generate the Content you post or seek to
            post on the Site (including, but not limited to, athletic activities
            in connection with any contests, races, group rides, or other events
            which Wattbike sponsors, organizes, participates in, or whose Site
            is used in connection with), your connection to the Site, your
            violation of the Terms, or your violation of any rights of another
            person or entity.
          </p>
          <h2>Termination</h2>
          <p>
            You agree that Wattbike may, under certain circumstances and without
            prior notice, immediately terminate your account and/or access to
            the Site. Cause for such termination shall include, but not be
            limited to, (a) breaches or violations of the Terms or other
            incorporated agreements or guidelines, (b) requests by law
            enforcement or other government agencies, (c) a request by you
            (self-initiated account deletions), (d) discontinuance or material
            modification to the Site (or any portion thereof), (e) unexpected
            technical or security issues or problems, (f) extended periods of
            inactivity, and/or (g) nonpayment of any fees owed by you in
            connection with the Site. Termination of your account may include
            (x) removal of access to all offerings within the Site, (y) deletion
            of your information, files and Content associated with or inside
            your account, and (z) barring of further use of the Site. Further,
            you agree that all terminations for cause shall be made in
            Wattbike's sole discretion and that Wattbike shall not be liable to
            you or any third party for any termination of your account or access
            to the Site.
          </p>
          <h2>APPLICABLE LAWS</h2>
          <p>
            This Site is controlled by Wattbike from its offices within the UK.
            Wattbike makes no representation that the Content in the Site or the
            Site are appropriate or available for use in other locations, and
            access to them from territories where their content or use is
            illegal is prohibited. Those who choose to access this Site from
            locations outside of the UK do so on their own initiative and are
            responsible for compliance with applicable local laws. You may not
            use or export the Content in violation of UK export laws and
            regulations. You hereby consent and submit to the exclusive
            jurisdiction of UK courts for any legal proceedings related to the
            Site or the Terms. If any party hereto brings any suit or action
            against another for relief, declaratory or otherwise, arising out of
            these Terms, the prevailing party will have and recover against the
            other party, in addition to all court costs and disbursements, such
            sum as the court may adjudge to be reasonable legal fees. Except to
            the extent prohibited by applicable law, the parties agree that any
            claim or cause of action arising out of or related to use of the
            Site or the Terms must be filed within one (1) year after such claim
            or cause of action arose or be forever barred. Sections 9, 11, 12,
            13, 15 and 16 shall survive termination of your account and/or the
            Terms.
          </p>
          <h2>General</h2>
          <p>
            You agree that no joint venture, partnership, employment or agency
            relationship exists between you and Wattbike as a result of the
            Terms or your use of the Site. The Terms constitute the entire
            agreement between you and Wattbike with respect to your use of the
            Site. The failure of Wattbike to exercise or enforce any right or
            provision of the Terms shall not constitute a waiver of such right
            or provision. If any provision of the Terms is found by a court of
            competent jurisdiction to be invalid, the parties nevertheless agree
            that the court should endeavor to give effect to the parties'
            intentions as reflected in the provision, and the other provisions
            of the Terms remain in full force and effect. You may not assign,
            delegate or otherwise transfer your account or your obligations
            under these Terms without the prior written consent of Wattbike.
            Wattbike has the right, in its sole discretion, to transfer or
            assign all or any part of its rights under these Terms and will have
            the right to delegate or use third party contractors to fulfill its
            duties and obligations under these Terms and in connection with the
            Site. Wattbike's notice to you via email, regular mail or notices or
            links on the Site shall constitute acceptable notice to you under
            the Terms. A printed version of the Terms and of any notice given in
            electronic form shall be admissible in judicial or administrative
            proceedings based upon or relating to the Terms to the same extent
            and subject to the same conditions as other business documents and
            records originally generated and maintained in printed form. Section
            titles and headings in the Terms are for convenience only and have
            no legal or contractual effect. Any rights not expressly granted
            herein are reserved.
          </p>
          <h2>Support and Questions</h2>
          <p>
            We will provide support to you related to the Site via the following
            methods:
            <br />
            <b>Website</b> (http://wattbike.com/)
            <br />
            <b>Email</b> (info@wattbike.com)
          </p>
          <p>
            We strive to respond to support requests within 48 hours after the
            request is placed.
            <br />
            If you have any questions regarding these Terms, please contact us
            by email at info@wattbike.com.
          </p>
          <hr />
        </div>
      </PrimaryLayout>
    </>
  );
}
export default Terms;
