import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useUserStore from "../components/UserStore";
import MyModal from "../components/MyModal";
import highchartsAccessibility from "highcharts/modules/accessibility";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import { InfoCircleFill } from "react-bootstrap-icons";
highchartsAccessibility(Highcharts);

const SessionChartPower = (props) => {
  const { user, signedIn, setUser, setSignedIn } = useUserStore(
    (state) => state
  );
  const [session, setSession] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chartOptions, setChartOptions] = useState({});
  const [firstDraw, setFirstDraw] = useState(true);
  const [ftpLineVisible, setFtpLineVisible] = useState(true);
  const [ftpZonesVisible, setFtpZonesVisible] = useState(false);
  const [mmpLineVisible, setMmpLineVisible] = useState(false);
  const [mmpZonesVisible, setMmpZonesVisible] = useState(false);
  // const [data, setData] = useState();

  // add any plot bands such as FTP training zones

  let mmpZones = [
    {
      from: 0,
        to: .35,
        text: 'Recovery',
      colour: "#dedede",
    },
    {
      from: .35,
        to: .45,
        text: 'Zone 1',
      colour: "#cfd9ff",
    },
    {
      from: .45,
        to: .55,
        text: 'Zone 2',
      colour: "#d8ffcf",
    },
    {
      from: .55,
        to: .65,
        text: 'Zone 3',
      colour: "#fffdcf",
    },
    {
      from: .65,
        to: .75,
        text: 'Zone 4',
      colour: "#ffebcf",
    },
    {
      from: .75,
        to: .85,
        text: 'Zone 5',
      colour: "#ffcfcf",
    },
    {
      from: .85,
        to: 1,
        text: 'Maximal',
      colour: "#ffcfcf",
    },
    {
      from: 1,
        to: 10,
        text: 'Supra Maximal',
      colour: "#ffcfcf",
    },
  ];

  let ftpZones = [
    {
      from: 0,
      to: 0.55,
      text: "Active Recovery",
      colour: "#dedede",
    },
    {
      from: 0.55,
      to: 0.75,
      text: "Endurance",
      colour: "#cfd9ff",
    },
    {
      from: 0.75,
      to: 0.9,
      text: "Tempo",
      colour: "#d8ffcf",
    },
    {
      from: 0.9,
      to: 1.05,
      text: "Lactate Threshold",
      colour: "#fffdcf",
    },
    {
      from: 1.05,
      to: 1.2,
      text: "VO2max",
      colour: "#ffebcf",
    },
    {
      from: 1.2,
      to: 10,
      text: "Anaerobic Capacity",
      colour: "#ffcfcf",
    },
  ];

  // useEffect to control when the logic is run, i.e. only on props change
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      updateOptions(props.data, props.workoutData);
      setLoading(false);
    };

    fetchData().catch("Fetching session data error: ", console.error);
  }, [ftpLineVisible, ftpZonesVisible, mmpLineVisible, mmpZonesVisible]);

  const handleZoom = (e, props) => {
    props.handleChangeZoom(e);
  };

  const handleToolTip = (point, props, e) => {
    props.handleChangeToolTip(point, e);
  };

  const updateOptions = (data, workoutData = null) => {
    console.log("props:: ", props);

    let chartTheme = {
      light: {
        background: "#ffffff",
        color: "rgba(33,33,33,1)",
        seriesColors: ["#3f93f4", "#ed1c24", "#ffa505"],
        gridColor: "#E6E6E6",
      },
    };

    let series = [
      {
        color: chartTheme["light"].seriesColors[0],
        showInLegend: false,
        name: "Power",
        id: "power",
        marker: {
          enabled: false,
          states: {
            hover: {
              radius: 4,
            },
          },
          symbol: "circle",
        },
        visible: true,
        data: data.powerData,
      },
    ];

    // if we have workout data add an extra series
    if (workoutData) {
      series.push({
        type: "area",
        color: chartTheme["light"].seriesColors[3],
        showInLegend: false,
        name: "Workout",
        id: "workout",
        tooltip: {
          enabled: false,
        },
        marker: {
          enabled: false,
          // states: {
          //   hover: {
          //     radius: 4,
          //   },
          // },
          symbol: "circle",
        },
        visible: true,
        data: workoutData.chartData,
      });
    }

    // add any plotlines such as ftp
    let plotLines = {};

    if (ftpLineVisible){
      plotLines = {
        color: "#000000",
        width: 1,
        dashStyle: "longdash",
        value: props.session.userPerformanceState.ftp,
        label: {
          text: "FTP",
          align: "right",
          x: -5,
          y: 15,
        },
      };
    }else if (mmpLineVisible){
      plotLines = {
        color: "#000000",
        width: 1,
        dashStyle: "longdash",
        value: props.session.userPerformanceState.mmp,
        label: {
          text: "MMP",
          align: "right",
          x: -5,
          y: 15,
        },
      };
    } else {
      plotLines = {};
    }

    let plotBands = [];

    if (ftpZonesVisible){
      ftpZones.forEach((zone) => {
        plotBands.push({
          color: zone.colour,
          from: Math.round(props.session.userPerformanceState.ftp * zone.from),
          to: props.session.userPerformanceState.ftp * zone.to,
          label: {
            text: zone.text,
            align: "right",
            x: -5,
            style: { fontSize: 12 },
          },
        });
      });
    }else if (mmpZonesVisible){
      mmpZones.forEach((zone) => {
        plotBands.push({
          color: zone.colour,
          from: Math.round(props.session.userPerformanceState.mmp * zone.from),
          to: props.session.userPerformanceState.mmp * zone.to,
          label: {
            text: zone.text,
            align: "right",
            x: -5,
            style: { fontSize: 12 },
          },
        });
      });
    } else {
      plotBands = [];
    }

    // build the chart options
    let chartOptions = {
      chart: {
        type: "line",
        backgroundColor: chartTheme["light"].background,
        zoomType: "x",
        animation: false,
        height: 250,
        marginRight: 0,
        sync: true, // custom property
        resetZoomButton: {
          theme: { style: { display: "none" } },
        },
        events: {
          load: function () {
            if (firstDraw) {
              this.xAxis[0].setExtremes(
                data.powerData[0][0],
                data.powerData[data.powerData.length - 1][0],
                true,
                false
              );
              setFirstDraw(false);
            }
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tooltip: {
        animation: false,
        positioner: function () {
          return {
            // right aligned
            x: this.chart.chartWidth - this.label.width,
            y: 10, // align to title
          };
        },
        borderWidth: 0,
        backgroundColor: "none",
        pointFormat: "{point.y}",
        headerFormat: "",
        shadow: false,
        style: {
          fontSize: "18px",
          color: "white",
        },

        pointFormatter: function (e) {
          props.handleChangeToolTip(this, e);
          return this.y + "W";
          // return false;
        },
        // enabled: false
      },
      series: series,
      plotOptions: {
        series: {
          animation: false,
          connectNulls: false,
          states: {
            hover: {
              // enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
          events: {
            mouseOut: function (e) {
              console.log("mouse out");
              props.handleChangeToolTip({ x: null, y: null }, e);
            },
          },
        },
        line: {
          lineWidth: 1,
        },
        area: {
          lineWidth: 0,
          fillOpacity: 0.3,
        },
        spline: {
          dataGrouping: {
            enabled: true,
            forced: true,
            smoothed: true,
            units: [["second", [1]]],
          },
          lineWidth: 1,
        },
      },
      yAxis: {
        gridLineColor: chartTheme["light"].gridColor,
        title: "",
        labels: {
          align: "left",
          x: 0,
          y: -2,
          style: {
            color: chartTheme["light"].color,
          },
        },
        plotLines: [plotLines],
        plotBands: plotBands,
      },
      xAxis: {
        gridLineColor: chartTheme["light"].gridColor,
        // visible: false,
        crosshair: true,
        style: {
          color: chartTheme["light"].color,
        },
        labels: {
          formatter: function () {
            let minutes = Math.floor(this.value / 60);
            let seconds = this.value - minutes * 60;

            return minutes + ":" + seconds;
          },
        },
        plotLines: [],
        events: {
          afterSetExtremes(e) {
            handleZoom(e, props);
            // props.handleChangeZoom(e);
          },
        },
      },
    };

    setChartOptions(chartOptions);
  };

  // Styles for non-highcharts components
  let styles = {
    buttonCol: {
      display: "flex",
      justifyContent: "right",
    },
    button: {},
  };

  // return
  return loading ? (
    <div>Loading main chart...</div>
  ) : (
    <>
      {/* <Col> */}
      <Row>
        <Col style={styles.buttonCol}>
        <Button
            style={styles.button}
            size={"sm"}
            variant={ftpLineVisible ? "secondary" : "outline-secondary"}
            onClick={() => {
              setFtpLineVisible(!ftpLineVisible);
              setFtpZonesVisible(false);
              setMmpLineVisible(false);
              setMmpZonesVisible(false);
            }}
          >
            FTP
          </Button>
          &nbsp;
          <Button
            style={styles.button}
            size={"sm"}
            variant={ftpZonesVisible ? "secondary" : "outline-secondary"}
            onClick={() => {
              setFtpLineVisible(false);
              setFtpZonesVisible(!ftpZonesVisible);
              setMmpLineVisible(false);
              setMmpZonesVisible(false);
            }}
          >
            FTP Zones
          </Button>
          &nbsp;
          <Button
            style={styles.button}
            size={"sm"}
            variant={mmpLineVisible ? "secondary" : "outline-secondary"}
            onClick={() => {
              setFtpLineVisible(false);
              setFtpZonesVisible(false);
              setMmpLineVisible(!mmpLineVisible);
              setMmpZonesVisible(false);
            }}
          >
            MMP
          </Button>
          &nbsp;
          <Button
            style={styles.button}
            size={"sm"}
            variant={mmpZonesVisible ? "secondary" : "outline-secondary"}
            onClick={() => {
              setFtpLineVisible(false);
              setFtpZonesVisible(false);
              setMmpLineVisible(false);
              setMmpZonesVisible(!mmpZonesVisible);
            }}
          >
            MMP Zones
          </Button>
          &nbsp;
          <MyModal
            title="FTP and Zone Bands"
            content={
              <>
                <p>
                  FTP value used: <b>{props.session.userPerformanceState.ftp}W</b><br />
                  MMP value used: <b>{props.session.userPerformanceState.mmp}W</b>
                </p>
                <p>
                  The FTP/MMP line and zone bands are based on the user's recorded FTP/MMP at the time this ride was uploaded.
                </p>
                <p>
                  <b>FTP Zone Breakdown for {props.session.user.displayName}:</b>
                  <br />
                  {ftpZones.map((zone, i) => {
                    return (
                      <>
                        Zone {`${i + 1} (${zone.text}):  ${Math.round(
                          props.session.userPerformanceState.ftp * zone.from
                        )} -  ${Math.round(
                          props.session.userPerformanceState.ftp * zone.to
                        )}`}
                        W <br />
                      </>
                    );
                  })}
                </p>

                <p>
                  <b>MMP Zone Breakdown for {props.session.user.displayName}:</b>
                  <br />
                  {mmpZones.map((zone, i) => {
                    return (
                      <>
                        Zone {`${i + 1} (${zone.text}):  ${Math.round(
                          props.session.userPerformanceState.mmp * zone.from
                        )} -  ${Math.round(
                          props.session.userPerformanceState.mmp * zone.to
                        )}`}
                        W <br />
                      </>
                    );
                  })}
                </p>
              </>
            }
          >
            <h3>
            <InfoCircleFill style={{fill: "#D3D3D3"}}/>
            </h3>
          </MyModal>
        </Col>
      </Row>
      <Row>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          // onChange={onChangeValue}
        />
      </Row>
      {/* </Col> */}
    </>
  );
};

export default SessionChartPower;
