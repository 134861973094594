import React, { useEffect } from "react";
import LoginLayout from "../components/LoginLayout";
import queryString from "query-string";
import axios from "axios";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import useUserStore from "../components/UserStore";

const CauthPage = (props) => {
  document.title = "Hub Clubs - Auth";

  const navigate = useNavigate();

  const { user, signedIn, setUser, setSignedIn } = useUserStore(
    (state) => state
  );

  // parse the id token from the cognito callback url
  const locationHash = window.location.hash;
  const hashParams = queryString.parse(locationHash);
  const id_token = hashParams.id_token;
  const cognitoState = hashParams.state;

  useEffect(() => {

    let redirect = '';

    // where to go once logged in
    if(cognitoState && cognitoState.length > 0 && cognitoState !== 'undefined'){
      redirect = cognitoState.replace(/['"]+/g, ''); // forward user back where they were
    } else {
      redirect = "/dashboard"; // default to dashboard
    }


  // log the user into parse
  login(id_token, ({ parseToken, session, user }) => {
    setUser(user.data);
    setSignedIn(true);
    navigate(redirect);
  });
  }, [id_token]);

  ////////////////////////////////////////////////////////////////////////
  // Render
  ////////////////////////////////////////////////////////////////////////

  return (
    <LoginLayout>
      <h1>Logging you in, hold tight...</h1>
    </LoginLayout>
  );
};

/**
 * ---------------------------------------------
 * Log in
 * ---------------------------------------------
 */
const login = async (id_token, callback) => {
  try {
    let parseToken = await getParseToken(id_token);
    let session = await getSession(parseToken.data.token);
    let user = await getUser(session.data.user.objectId, parseToken.data.token);
    callback({ parseToken, session, user });
  } catch (err) {
    throw new Error(err);
  }
};

/**
 * ---------------------------------------------
 * Get User
 * ---------------------------------------------
 */

const getUser = async (objectId, sessionToken) => {
  try {
    // now retrieve the user details as we cant get includes using login
    let axiosUserOptions = {
      method: "GET",
      url: process.env.REACT_APP_API_URL + "users/" + objectId,
      headers: {
        "Content-Type": "application/json",
        "X-Parse-Session-Token": sessionToken,
        "X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
        "X-Parse-Javascript-Key": process.env.REACT_APP_JS_KEY,
      },
      params: {
        include: ["preferences", "performanceState", "statistics"],
      },
    };

    // Make the user data API call
    const userData = await axios(axiosUserOptions);

    return userData;
  } catch (err) {
    console.log("Err: ", err);
    throw new Error(err);
  }
};

/**
 * ---------------------------------------------
 * get session
 * ---------------------------------------------
 */
const getSession = async (parseToken) => {
  try {
    // now retrieve the user details as we cant get includes using login
    let axiosUserOptions = {
      method: "GET",
      url: process.env.REACT_APP_API_URL + "sessions/me",
      headers: {
        "Content-Type": "application/json",
        "X-Parse-Session-Token": parseToken,
        "X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
        "X-Parse-Javascript-Key": process.env.REACT_APP_JS_KEY,
      },
    };

    // Make the user data API call
    const sessionData = await axios(axiosUserOptions);

    return sessionData;
  } catch (err) {
    console.log("Err: ", err);
    throw new Error(err);
  }
};

/**
 * ---------------------------------------------
 * Get a Parse token using the JWT returned by Cognito
 * @param {String} cognitoToken - the id_token portion of the JWT token
 * @returns {String} - parse session token
 * ---------------------------------------------
 */
const getParseToken = async (cognitoToken) => {
  
  try {
    // Build the request
    let axiosOptions = {
      method: "GET",
      url: process.env.REACT_APP_API_URL + "custom/session",
      headers: {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": process.env.REACT_APP_APP_ID,
        "X-Parse-Javascript-Key": process.env.REACT_APP_JS_KEY,
        Authorization: "Bearer " + cognitoToken,
      },
    };

    // Make the login API call
    const loginData = await axios(axiosOptions);

    return loginData;
  } catch (err) {
    console.log("Err: ", err);
    throw new Error(err);
  }
};

export default CauthPage;
