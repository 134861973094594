import { Link } from "react-router-dom";
import Translate from "../components/Translate";
import Header from "../components/Header";
import { Container, Row, Col } from "react-bootstrap";

const LoginLayout = (props) => {
  return (
      <Container>
      <Row style={styles.row} className="align-items-center viewport-height">
        <Col>
          <div >{props.children}</div>
        </Col>
      </Row>
    </Container>
  );
};

const styles = {
    row: {
      height: "100vh",
    }
  }

export default LoginLayout;
