/**
 * ---------------------------------------------
 * Power to Zone
 * Send an absolute power value and get a zone record in return
 * ---------------------------------------------
 * @param {int} power
 * ---------------------------------------------
 */
import zones from "./zones";

const powerToZone = (power, ftp) => {
  let selectedZone;
  
  let zonesList = zones();

  let percentFtp = power / ftp;

  zonesList.ftp.map((zone, i) => {
    if (percentFtp > zone.from && percentFtp < zone.to) {
      selectedZone = zone;
    }
  });

  return selectedZone;

};

export default powerToZone;
