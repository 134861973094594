import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Pages
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Preferences from './pages/Preferences';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import CauthPage from './pages/CauthPage';
import AthleteDashboard from './pages/AthleteDashboard';
import SessionView from './pages/SessionView';
import CoachLink from './pages/CoachLink';
import ShareDetails from './pages/ShareDetails';
import ExportData from './pages/ExportData';

// Pages: This page is iframed into the main Wattbike website
import Leaderboard from './pages/Leaderboard';

// Pages: Some more experimental pages
import LiveHome from './pages/LiveHome';
import LiveWall from './pages/LiveWall';
import AccumulatorWall from './pages/AccumulatorWall';

// Helpers
import useUserStore from './components/UserStore';
import Protected from './components/Protected';

const Router = () => {

  const { user, signedIn, setSignedIn } = useUserStore(
    (state) => state
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:redirect?" element={<Home />} />
        <Route
          path="/dashboard"
          title="title"
          element={
            <Protected isSignedIn={signedIn}>
              <Dashboard />
            </Protected>
          }
        />
        <Route
          path="/athlete/:objectId"
          element={
            <Protected isSignedIn={signedIn}>
              <AthleteDashboard />
            </Protected>
          }
        />
        <Route
          path="/session/:objectId"
          element={
            <Protected isSignedIn={signedIn}>
              <SessionView />
            </Protected>
          }
        />
        <Route
          path="/share"
          element={
            <Protected isSignedIn={signedIn}>
              <ShareDetails />
            </Protected>
          }
        />
        <Route
          path="/export"
          element={
            <Protected isSignedIn={signedIn}>
              <ExportData />
            </Protected>
          }
        />
        <Route
          path="/preferences"
          element={
            <Protected isSignedIn={signedIn}>
              <Preferences />
            </Protected>
          }
        />
        <Route
          path="/coachlink/:objectId/:displayName"
          element={
            <CoachLink />
          }
        />
        <Route
          path="/live"
          element={
            <LiveHome />
          }
        />
        <Route
          path="/live/live-wall/:roomId"
          element={
            <LiveWall />
          }
        />
        <Route
          path="/live/accumulator/:roomId"
          element={
            <AccumulatorWall />
          }
        />
        <Route
          path="/leaderboard/:workoutId/:year?/:month?" // ?limit=10&sort=powerAvg|powerMax|powerKg|time|distance
          element={
            <Leaderboard />
          }
        />
        <Route path="/cauth/:action/:redirect?" element={<CauthPage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </BrowserRouter>
  )
};

export default Router;
