import React from "react";
import useUserStore from "../components/UserStore";
import PrimaryLayout from "../components/PrimaryLayout";
import { Link } from "react-router-dom";
import logo from "../assets/img/wattbike_logo_410x.avif";
import { TypeH1 } from "react-bootstrap-icons";

function Privacy() {
  document.title = "Hub Clubs Privacy Policy";

  const { user, signedIn, setSignedIn } = useUserStore((state) => state);

  const styles = {};

  return (
    <>
      <PrimaryLayout>
        <div class="header">
          <h1>
            Wattbike Privacy Policy
          </h1>
        </div>
        <div>
          <hr />
          <p>
            Wattbike Limited is committed to preserving the privacy of all
            visitors to wattbike.com, users of Wattbike mobile or desktop apps
            and users of any other Wattbike online, digital and offline
            services. Collectively, "Service(s)" or "Wattbike Service(s)".
          </p>
          <p>
            Please read the following Privacy Policy to understand how we store,
            use and protect the information that you provide to us.
          </p>
          <h2>Wattbike Data Protection Officer</h2>
          <p>
            We have a dedicated data protection officer ("DPO"). You can contact
            the DPO in writing at the address below or by emailing
            dpo@wattbike.com
          </p>
          <p>
            Data Protection Officer
            <br />
            Wattbike Ltd
            <br />
            Unit 13, Nottingham South and Wilford Ind Est
            <br />
            Ruddington Lane
            <br />
            Nottingham <br />
            NG11 7EP
          </p>
          <h2>Information sources</h2>
          <p>
            For the most part the information Wattbike collects or processes
            will come directly from you, either provided over the phone to a
            Wattbike representative, in writing, or entered manually online or
            via an app. In some cases we may receive information you grant us
            access to when linking with a third party service, or if you use a
            third party service (Facebook/Twitter) to log into a Wattbike
            Service.
          </p>
          <h2>What information do we collect and process</h2>
          <p>
            The information we collect or process will depend on how you
            interact with Wattbike and which Services you make use of. Placing
            an order will require a different set of data than creating an
            account on our app for example.
          </p>
          <ul>
            <li>
              We collect the basic information required to create an account on
              our Services, in most cases this is limited to an email address
              and password, but may also include details such as a reference
              code from a social platform if one was used to log into or create
              an account.
            </li>
            <li>
              If placing an order, we will require the information relevant to
              processing and fulfilling the order. We use industry standard
              encryption when processing this data and do not store payment
              details or process payments ourselves. Information processed will
              include, amongst others, billing and delivery address, contact
              phone and email address, payment card details, product details,
              and for finance orders some historical, employment and other
              personal information required by our finance partner. We strive to
              process or collect the minimum information required to fulfil
              these orders.
            </li>
            <li>
              You can optionally choose to add basic profile information on some
              Wattbike Services, such as first and last name, country of
              residence and upload an image to compliment your profile.
            </li>
            <li>
              For full use of the Wattbike Hub apps there is additional
              information that is required. Gender, weight and DOB for example
              are all required for much of the Wattbike Hub functionality to
              work as you would expect.
            </li>
            <li>
              Wattbike will collect a minimal amount of data from third parties
              when this will improve your experience using Wattbike Services.
              For example if you connect to Apple Health or Google Fit we will
              collect your current weight value these services store so we can
              provide the best experience when using the Wattbike Hub. You can
              restrict this access at any time.
            </li>
            <li>
              Wattbike may collect biometric data from third party hardware such
              as heart rate belts and watches, but only if you explicitly choose
              to connect these.
            </li>
            <li>
              Optionally when using Wattbike Services we will record location
              data to help us understand our users and improve our products and
              services
            </li>
            <li>
              We may combine information that we collect from you with
              information obtained from other members, third parties and
              information derived from any other subscription, product, or
              service we provide.
            </li>
            <li>
              We collect information from your browser, computer, or mobile
              device, which provide us with technical information when you
              access or use Wattbike Services. This technical information
              includes device and network information, cookies, log files and
              analytics information.
            </li>
          </ul>
          <h2>Why do we collect, store, process or share information</h2>
          <ul>
            <li>
              As necessary to provide Wattbike Services and fulfil our
              obligations. For example, we will require address and contact
              details to fulfil an order and we will need to pass payment
              details to a payment provider to process a card payment.
            </li>
            <li>Where necessary to comply with a legal obligation.</li>
            <li>Where you have made the information public.</li>
            <li>
              Where necessary for the purposes of Wattbike's or a third party's
              legitimate interests.
            </li>
            <li>
              Where you have explicitly chosen. For example if you link with
              Strava we will send them your activity data when you upload a
              session.
            </li>
          </ul>
          <h2>Where do we store data</h2>
          <p>
            Wattbike Service's databases and processing resources are located on
            servers based in Europe; primarily in the UK and Ireland.
          </p>
          <p>
            Wattbike cannot guarantee the physical location of information
            stored or processed by third party services used by Wattbike, or any
            services you choose to connect and share information with.
          </p>
          <h2>What information do we process</h2>
          <p>
            There are a few situations where we will process information but not
            store it, or only store part of it.
          </p>
          <ul>
            <li>
              When we process a finance application Wattbike will only store the
              information required to fulfil the order. The financial and
              personal information required to process the finance application
              is securely encrypted and forwarded to our finance partner, V12
              Finance, for processing and is not stored by Wattbike. For V-12's
              Privacy Policy please visit our Finance Page.
            </li>
            <li>
              When processing a card payment Wattbike will not store details of
              the payment card, but we will store billing address and contact
              details.
            </li>
          </ul>
          <h2>When and how do we share your data</h2>
          <h3>Publicly</h3>
          <ul>
            <li>
              By default, activity information uploaded to the Wattbike Hub
              service - such as the power and cadence produced during a Wattbike
              session - is publicly available.
            </li>
            <li>
              No required personal information, e.g. email, is made publicly
              available
            </li>
            <li>
              You can choose to make your account private at any time which will
              cause future sessions to be private by default. You can also set
              historical and individual sessions to be private from your account
              on hub.wattbike.com
            </li>
            <li>
              Profile information including name, username and country may be
              shown to visitors of hub.wattbike.com
            </li>
          </ul>
          <h3>Complimentary services</h3>
          <ul>
            <li>
              You can optionally choose to share data with complimentary third
              party services such as Strava, TrainingPeaks, Google Fit and Apple
              Health. You can revoke these integrations at any time.
            </li>
          </ul>
          <h3>Third party services and organisations</h3>
          <ul>
            <li>
              Wattbike use third party services to deliver physical and digital
              product as well as monitor and maintain Wattbike Services. These
              include bug tracking systems, analytics services, logistics
              companies and logging
            </li>
            <li>
              Wattbike use services for such tasks as customer support
              management (Zendesk)
            </li>
            <li>
              Every attempt is made to only share anonymised or non-personally
              identifiable data with external services, however in some cases
              limited personal data or ID numbers may be required
            </li>
            <li>
              We use email services to send service notifications such as a
              summary of your workout on the Wattbike Hub app. In these cases
              your details are processed but not stored by these services. You
              may opt out of these notifications at any time.
            </li>
            <li>
              We use email services to send marketing communications and store
              your newsletter preferences. We will typically store a name, email
              address, country and source (e.g. "Home Page Newsletter Signup")
              with these services. You may opt out of these at any time by
              contacting us using the information on this page, or by using
              links in the footer of emails you receive from us.
            </li>
            <li>
              We may share non-personally identifiable information with sport
              scientists, universities and other researchers and experts to help
              improve our products and services.
            </li>
            <li>
              If required by law we may need to pass on personally identifiable
              information to a relevant agency
            </li>
          </ul>
          <h2>Cookies, logs, analytics and other technology</h2>
          <p>
            Wattbike uses industry standard technologies to improve, secure,
            maintain and ensure the proper running of Wattbike Services.
          </p>
          <p>
            Usage and error log files are used to aid with development and
            debugging of Wattbike Services and stored information includes IP
            address, browser and device information, time, time zone and date
            information and user identification numbers where applicable.
          </p>
          <p>
            Wattbike will use third party and internal analytics tools to
            analyse trends, measure performance, track conversion and otherwise
            understand and improve our systems and user experience. Account
            identification numbers and activity data may be included or
            referenced to this end.
          </p>
          <p>
            Cookies and sessions are used to temporarily store information about
            your visit, for example to hold products in your shopping cart or
            language preferences.
          </p>
          <h2>Retention of data</h2>
          <p>
            Wattbike will retain data as long as it is required for the proper
            fulfilment of our obligations and the effective operation of our
            Services. Account profile information is typically held until the
            point an account is deleted for example.
          </p>
          <p>
            Wattbike will retain personal and other information even from
            deleted accounts where required to by law or for other such
            essential business needs, such as fraud prevention or dispute
            resolution.
          </p>
          <p>
            Non-personal information such as activity data and analytics from
            deleted accounts or other sources will be anonymised and kept to aid
            the improvement of Wattbike Services.
          </p>
          <h2>Security of your data</h2>
          <p>All Wattbike Services are based on servers in the UK or Europe.</p>
          <p>
            Information is transmitted between systems using industry standard
            encryption protocols.
          </p>
          <p>
            We use industry standard web encryption to transfer payment details,
            and do not hold or process payments on our systems but use
            best-in-class industry partners.
          </p>
          <p>
            We never store passwords in plain text but store them encrypted.
          </p>
          <p>
            Only personnel who have a legitimate requirement to access your
            information are granted access.
          </p>
          <h2>What are your rights?</h2>
          <p>
            <strong>Access</strong>
            <br />
            You can access much of your personal information by logging into
            your accounts on wattbike.com, hub.wattbike.com or on the Hub
            Android or iOS apps. If you would like access to further information
            please contact dpo@wattbike.com
          </p>
          <p>
            <strong>Update</strong>
            <br />
            You can modify much of your personal information by logging into
            your accounts on wattbike.com, hub.wattbike.com or on the Hub
            Android or iOS apps. If you would like to modify further information
            please contact dpo@wattbike.com.
          </p>
          <p>
            <strong>Remove</strong>
            <br />
            Where legally appropriate you can request that your personal
            information is removed from Wattbike systems. We may have a legal
            obligation to keep a record of financial transactions for example
            though. Please contact dpo@wattbike.com.
          </p>
          <p>
            <strong>Move</strong>
            <br />
            You have the right to have access to a copy of your personal
            information. Please contact dpo@wattbike.com
          </p>
          <p>
            <strong>Make a Complaint</strong>
            <br />
            You can contact Wattbike's Data Protection Officer ("DPO") using the
            details at the top of this policy or at dpo@wattbike.com. You also
            have the right to make a complaint to the Information Commissioner's
            Office which enforces data protection laws: https://ico.org.uk/.
          </p>
          <h2>This policy</h2>
          <p>
            Wattbike reserves the right to modify this Privacy Policy at any
            time and we encourage you to check this document for changes
            periodically. If we make substantive or material changes to this
            policy we will strive to make this prominent to our users, up to and
            including restricting access to some or all Services.
          </p>
          <p>
            If you object to any of the points outlined in this Privacy Policy,
            you should stop using Wattbike Services and request that your
            account is deleted.
          </p>
          <h2>Company Details</h2>
          <p>
            <strong>Company No.:</strong> 5554245
            <br />
            <strong>VAT No:</strong> GB115 0811 57
          </p>
          <p>
            <strong>Registered Address:</strong>
            <br />
            Wattbike Ltd
            <br />
            Unit 13, Nottingham South and Wilford Ind Est
            <br />
            Ruddington Lane
            <br />
            Nottingham <br />
            NG11 7EP
          </p>
        </div>
      </PrimaryLayout>
    </>
  );
}
export default Privacy;
