import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DateFormat from "../components/formatters/DateFormatter";
import DistanceFormat from "../components/formatters/DistanceFormatter";
import DataTable, { createTheme } from "react-data-table-component";
import SessionMiniChartPower from "../components/SessionMiniChartPower";
import AthleteTag from "../components/AthleteTag"; //
import Translate from "../components/Translate";
import { useNavigate } from 'react-router-dom';
import { Activity } from "react-bootstrap-icons";
import { hubGetFile } from "../utils/hubApiRequest";

const SessionsList = (sessionsData) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formattedTableData, setFormattedTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filterText, setFilterText] = useState();
  const mobileBreakpoint = 500;
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= mobileBreakpoint
  );

  useEffect(() => {
    if(filterText && filterText.length > 0){
      filterResults();
    }else{
      setFilteredTableData(formattedTableData); // reset the filtered results
    }
    
  }, [filterText]);

  const filterResults = () => {
    let tempResults = [];

    formattedTableData.forEach(activity => {
      let expression = `(.*)(${filterText})(.*)`;
      const filterTextRegex = new RegExp(expression);
      if(filterTextRegex.test(activity.title.toLowerCase())){
        tempResults.push(activity);
      }
    });

    setFilteredTableData(tempResults);
    
  }

  //
  const convertExport = async (file, format, filename) => {

    // Build final file name
    let now = new Date();
    const dateString = now.toISOString().substring(0, 10);
    const fullFilename = `${dateString} - ${filename}.${format}`;

    // get data
    const fileContent = await hubGetFile({ fileName: file, cache: true });
    const outputArray = await buildOutputArray(fileContent.data);

    // convert data
    const convertedContent = await convert(outputArray, 'csv');

    // download data
    forceDownload(convertedContent, 'csv', fullFilename);
    
  }

  const [tableColumns, setTableColumns] = useState([
    {
      name: <Translate label={"table-date"} />,
      selector: (row, i) => <DateFormat date={row.date} />,
      sortable: true,
      right: false,
    },
    {
      name: <Translate label={"table-athlete"} />,
      selector: (row, i) => <AthleteTag athlete={row.athlete} />,
      sortable: true,
    },
    {
      name: <Translate label={"table-title"} />,
      selector: (row, i) => row.title,
      sortable: true,
    },
    {
      name: "",
      selector: (row) =>
        row.time !== "NA" ? (
          <SessionMiniChartPower fileName={row.file} />
        ) : null,
      sortable: false,
    },
    {
      name: <Translate label={"table-time"} />,
      selector: (row, i) => row.time,
      sortable: true,
      maxWidth:"30px"
    },
    {
      name: <Translate label={"table-powerAvg"} />,
      selector: (row, i) => row.powerAvg,
      cell: (row) =>
        row.powerAvg !== "NA" ? Math.round(row.powerAvg) + "W" : null,
      sortable: true,
      maxWidth:"30px"
    },
    {
      name: <Translate label={"table-powerMax"} />,
      selector: (row, i) => row.powerMax,
      cell: (row) =>
        row.powerMax !== "NA" ? Math.round(row.powerMax) + "W" : null,
      sortable: true,
      maxWidth:"30px"
    },
    {
      name: '',
      selector: (row, i) => row.sessionData,
      cell: (row) =>
        <><a href={`${process.env.REACT_APP_API_URL}files/${row.tcx_file}`}>TCX</a> &nbsp;|&nbsp; <a href={`${process.env.REACT_APP_API_URL}files/${row.fit_file}`}>FIT</a>|&nbsp; <a href="#" onClick={() => convertExport(row.file, 'csv', "WB-HUB" + '-' + row.athlete.displayName.toUpperCase())}>CSV</a></>,
      sortable: false,
      maxWidth:"50px"
    },

  ]);

  const [tableColumnsMobile, setTableColumnsMobile] = useState([
    {
      name: <Translate label={"table-date"} />,
      selector: (row, i) => <DateFormat date={row.date} />,
      sortable: true,
      right: false,
    },
    {
      name: <Translate label={"table-athlete"} />,
      selector: (row, i) => <AthleteTag athlete={row.athlete} />,
      sortable: true,
    },
    {
      name: <Translate label={"table-title"} />,
      selector: (row, i) => row.title,
      sortable: true,
    },
    {
      name: "",
      selector: (row) =>
        row.time !== "NA" ? (
          <SessionMiniChartPower fileName={row.file} />
        ) : null,
      sortable: false,
    },
    {
      name: <Translate label={"table-time"} />,
      selector: (row, i) => row.time,
      sortable: true,
      maxWidth:"40px"
    },
  ]);

  const handleRowClick = (row, event) => {
    navigate('/session/' + row.id);
  }

  useEffect(() => {
    const formatDate = async () => {
      setLoading(true);
      let tableData = await formatSessionData(sessionsData.data);
      await setFormattedTableData(tableData);
      await setFilteredTableData(tableData);
      setLoading(false);
    };
    formatDate().catch("Format date error: ", console.error);
  }, []);

  createTheme('wattbikeTheme', {
    text: {
      primary: '#000000',
      secondary: '#999999',
      font: "trim-regular",
    },
    background: {
      default: 'none',
    },
    divider: {
      default: '#666666',
    },
    action: {
      button: 'rgba(255,255,255,.54)',
      hover: 'rgba(255,255,255,.08)',
      disabled: 'rgba(255,255,255,.12)',
    },
    button: {
      default: '#FFFFFF',
      focus: 'rgba(255, 255, 255, .54)',
      hover: 'rgba(255, 255, 255, .12)',
      disabled: 'rgba(255, 255, 255, .18)',
    }
  });
 
  if (loading) {
    return (
          <Translate label={"general-loading"} />
    );
  } else if (isMobile) {
    return (
      <>
        <DataTable
          columns={tableColumnsMobile}
          data={formattedTableData}
          noHeader={true}
          pagination={true}
          pointerOnHover={true}
          onRowClicked={handleRowClick}
          fixedHeader={false}
          paginationPerPage={20}
          paginationRowsPerPageOptions={[20, 50, 100]}
        />
      </>
    )
  } else {
    return (
      <>
      <Row className="justify-content-end">
      <Col sm={12} md={6}>
        <Form.Control 
          id="filterSearch"
          type="text"
          placeholder="Filter By Activity Name"
          value={filterText}
          onChange={(event) => {setFilterText(event.target.value);}}
          style={{"float":"right", "padding": 5, "width": 200 }}
        />
        </Col>
        </Row>
        <Row>
        <Col sm={12} md={12}>
        <DataTable
          columns={tableColumns}
          data={filteredTableData}
          noHeader={true}
          pagination={true}
          pointerOnHover={true}
          onRowClicked={handleRowClick}
          fixedHeader={false}
          paginationPerPage={20}
          paginationRowsPerPageOptions={[20, 50, 100]}      
        />
        </Col>
      </Row>
      </>
    )
  };
};

// "https://dev-api.wattbike.com/v2/files/id-anonymous-1687354529268_1687354541153.wbs"

const formatSessionData = (sessionsData) => {
  // format data for the data table
  let tableData = [];
  for (let i = 0; i < sessionsData.length; i++) {
    if (sessionsData[i].state === "ready" && sessionsData[i].sessionSummary) {
      let formattedTime = millisToMinutesAndSeconds(
        sessionsData[i].sessionSummary.time
      );

      tableData.push({
        id: sessionsData[i].objectId,
        title: sessionsData[i].title,
        athlete: sessionsData[i].user,
        pes: sessionsData[i].sessionSummary.pesCombinedCoefficient,
        powerAvg: sessionsData[i].sessionSummary.powerAvg,
        powerMax: sessionsData[i].sessionSummary.powerMax,
        time: formattedTime,
        distance: sessionsData[i].sessionSummary.distance,
        date: sessionsData[i].startDate.iso,
        file: sessionsData[i].sessionData.wbsr.name,
        tcx_file: sessionsData[i].sessionData.tcx.name,
        fit_file: sessionsData[i].sessionData.fit.name,
      });
    } else {
      tableData.push({
        id: sessionsData[i].objectId,
        title: sessionsData[i].title + " [PROCESSING]",
        athlete: sessionsData[i].user,
        time: "NA",
        date: sessionsData[i].startDate.iso,
      });
    }
  }
  return tableData;
};

const millisToMinutesAndSeconds = (millis) => {
  let minutes = Math.floor(millis / 60000);
  let seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};

/**
  * ---------------------------------------------
  * Build an array which is used to output the data
  *
  * @param {array} `revolutions`
  * @return {array}
  * ---------------------------------------------
  */
const buildOutputArray = (fileData) => {

  return new Promise(resolve => {

    let revList = [];

    if (fileData.laps){ // full wbs file

      fileData.revolutions = [];
      for (let i = 0; i < fileData.laps.length; i++) {
        for (let j = 0; j < fileData.laps[i].data.length; j++) {

          let tmpRev = {
            "time": fileData.laps[i].data[j].time,
            "heartrate": fileData.laps[i].data[j].heartrate,
            "balance": fileData.laps[i].data[j].balance,
            "force": fileData.laps[i].data[j].force,
            "power": fileData.laps[i].data[j].power,
            "speed": fileData.laps[i].data[j].speed,
            "distance": fileData.laps[i].data[j].distance,
            "cadence": fileData.laps[i].data[j].cadence,

          };

          // we dont always have pes data
          if(fileData.laps[i].data[j].pes){
            tmpRev['pesCombinedCoefficient'] = fileData.laps[i].data[j].pes.combinedCoefficient;
            tmpRev['pesRightCoefficient'] = fileData.laps[i].data[j].pes.rightCoefficient;
            tmpRev['pesLeftCoefficient'] = fileData.laps[i].data[j].pes.leftCoefficient;
          }else{
            tmpRev['pesCombinedCoefficient'] = 'na';
            tmpRev['pesRightCoefficient'] = 'na';
            tmpRev['pesLeftCoefficient'] = 'na';
          }

          revList.push(tmpRev);
        }
      }
      resolve(revList);
    }else{ 
      for (let i = 0; i < fileData.revolutions.length; i++) {
        revList.push(
          {
            "time": fileData.revolutions[i].time,
            "distance": fileData.revolutions[i].distance,
            "balance": fileData.revolutions[i].balance,
            "force": fileData.revolutions[i].force,
            "power": fileData.revolutions[i].power,
            "speed": fileData.revolutions[i].speed,
            "cadence": fileData.revolutions[i].cadence,
            "heartrate": fileData.revolutions[i].heartrate
          }
        );
      }
      resolve(revList);
    }

  })
}

  /**
  * ---------------------------------------------
  * Convert the array to csv or json
  *
  * @param {array} `data` array of json objects
  * @param {string} `format` i.e. 'csv' or 'json'
  * ---------------------------------------------
  */
  const convert = (data, format='csv', filenameSuffix='') => {
    return new Promise(resolve => {

      if(format === 'csv'){
        const replacer = (key, value) => value === null ? '' : value; // handle null values
        const header = Object.keys(data[0]);
        let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        csv = csv.join('\r\n');
        
        resolve(csv);
      }else if (format === 'json'){
        resolve(data);
      }

    })
  }


  /**
  * ---------------------------------------------
  * Force the file download
  *
  * @param {string} `data` i.e. string contents for the txt file
  * @param {string} `format` i.e. 'csv' or 'json'
  * ---------------------------------------------
  */
  const forceDownload = (data, format, filename) => {

    // stringify content
    if(format === 'json'){
      data = JSON.stringify(data);
    }

    let blob = new Blob([data], { type: 'text/' + format });
    let url = window.URL.createObjectURL(blob);

    // trigger a download
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
export default SessionsList;
