import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from "highcharts/modules/accessibility";
highchartsAccessibility(Highcharts);

const SessionChartPES = (props) => {

  const [loading, setLoading] = useState(false);
  const [chartOptions, setChartOptions] = useState();
  
  useEffect(() => {
    setLoading(true);
    updateOptions();
    setLoading(false);
  }, []);

  const handleZoom = (e, props) => {
    // props.handleChangeZoom(e);
  }

  // const handleToolTip = (point, props, e) => {
  //   // props.handleChangeToolTip(point, e);
  // }

  const updateOptions = async () => {

    let chartTheme = {
      background: "#ffffff",
      color: "rgba(33,33,33,1)",
      seriesColors: ['#3f93f4', '#ed1c24', '#ffa505', '#238823'],
      gridColor: "#E6E6E6"
    };

    let series = await [
      {
        color: chartTheme.seriesColors[3],
        showInLegend: false,
        name: 'PES',
        marker: {
          enabled: false,
          states: {
            hover: {
              radius: 4
            }
          },
          symbol: 'circle'
        },
        visible: true,
        data: props.data.pesData,
        borderWidth: 0,
        zoneAxis: 'y',
        zones: [{
          value: 55,
          color: chartTheme.seriesColors[1]
        },{
          value: 65,
          color: chartTheme.seriesColors[2]
        },{
          value: 75,
          color: chartTheme.seriesColors[3]
        },{
          value: 85,
          color: chartTheme.seriesColors[3]
        },{
          value: 85,
          color: chartTheme.seriesColors[2]
        },{
          color: chartTheme.seriesColors[1]
        }]
      }
    ];

    let chartOptions = {
      chart: {
        type: 'column',
        backgroundColor: chartTheme.background,
        zoomType: 'x',
        animation: false,
        height: 300,
        marginRight: 0,
        sync: true // custom property
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      tooltip: {
        animation: false,
        positioner: function () {
          return {
              // right aligned
              x: this.chart.chartWidth - this.label.width,
              y: 0 // align to title
          };
        },
        style: {
          fontSize: '18px',
          color: 'white',
        },
        pointFormatter: function(e) {
          props.handleChangeToolTip(this, e);
          return Math.round(this.y) + " / 75 PES";
        },
        borderWidth: 0,
        backgroundColor: 'none',
        pointFormat: '{point.y}',
        headerFormat: '',
        shadow: false,
      },
      series: series,
      plotOptions: {
        series: {
          animation: false,
          states: {
            hover: {
              enabled: false
            }
          },
          events: {
            mouseOut: function (e) {
              console.log("mouse out");
              props.handleChangeToolTip({x:null, y:null}, e);
            }
          }
        },
        line: {
          lineWidth: 1,
        },
        spline: {
          dataGrouping: {
            enabled: true,
            forced: true,
            smoothed: true,
            units: [[
              'second', [1]
            ]]
          },
          lineWidth: 1,
        }
      },
      yAxis: {
        gridLineColor: chartTheme.gridColor,
        title: "",
        min: 20,
        max: 100,
        labels: {
          align: 'left',
          x: 0,
          y: -2,
          style: {
            color: chartTheme.color,
          }
        }
      },
      xAxis: {
        gridLineColor: chartTheme.gridColor,
        visible: true,
        crosshair: true,
        style: {
          color: chartTheme.color,
        },
        labels: {
          formatter: function () {
            let minutes = Math.floor(this.value / 60);
            let seconds = this.value - minutes * 60;
            return minutes + ':' + seconds;
          }
        },
        plotLines: [],
        events: {
          afterSetExtremes (e) {
            props.handleChangeZoom(e);
          }
        }
      }
    };

    setChartOptions(chartOptions);

  };

    return loading ? (
      <div>Loading PES chart...</div>
    ) : (
      <div>
        <div>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} onChange={props.onChangeValue}/>
        </div>
      </div>
    );
}

export default SessionChartPES;
