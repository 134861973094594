/**
 * ---------------------------------------------
 * Text to Hex
 * Take a string, hash it and return a Hex colour value based on it
 * ---------------------------------------------
 * @param {String} str (required) any length string
 * @returns {String} - Hex colour code
 * ---------------------------------------------
 */
const textToHex = function (str) {

  if(!str || str === ""){
    return "999999";
  }

  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

export default textToHex;
