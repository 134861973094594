////////////////////////////////////////////////////////////////
// Handles the low level API requests and related local caching
////////////////////////////////////////////////////////////////
import axios from "axios";
import localDB from "../utils/LocalDB";
var hash = require("object-hash");

/**
 * ---------------------------------------------
 * Hub Labs Get request
 * Performs a get request on the API or returns local data if cache is requested and found
 * ---------------------------------------------
 * @param {Object} request - Object containing the request
 * @param {Object} request.token (optional) x-auth-token
 * @param {String} request.resource (required) the resource required e.g. 'leaderboard'
 * @param {Bool|null} request.cache (optional) should we look for this response in cache and cache any result
 * @param {Object|null} request.params (optional) string representation of params object e.g. '{state: "ready"}'
 * 
 * @param {ObjIntect|null} request.limit (optional) default is 1000
 * @param {Int|null} request.skip (optional) how many items to skip - used for pagination
 * @param {Object|null} request.order (optional)
 * @returns {Object} - API response
 * ---------------------------------------------
 */
const hubLabsGet = async (request) => {

  // try {
  // check local cache
  let requestHash = hash([process.env.REACT_APP_CACHE_ID, process.env.REACT_APP_CUSTOM_STAGE, request]);
  if (request.cache) {
    let cacheResponse = await localDB.localCache.get(requestHash);
    if (cacheResponse) {
      return cacheResponse.value;
    }
  }

  // Build the query params
  let params = {};

  if (request.params) {
    params = request.params;
  }

  // build headers
  let headers = {};
  if (request.token) {
    // console.log("getting with token");
    headers = {
      "Content-Type": "application/json",
      "x-auth-token": request.authUser.sessionToken,
    };
  } else {
    // console.log("getting without token");
    headers = {
      "Content-Type": "application/json",
    };
  }

  // Build the request
  const axiosOptions = {
    method: "GET",
    url: process.env.REACT_APP_LABS_API_URL + "/" + request.resource,
    headers: headers,
    params: params,
  };

  // Make the API call
  // console.log("axiosOptions: ", axiosOptions);
  let response = await axios(axiosOptions);

  if (!response || !response.data) {
    return [];
  }

  // save response to cache
  if (response && response.data && request.cache) {
    localDB.localCache.put({ key: requestHash, value: response.data });
  }

  return response.data;
  // } catch (err) {
  //   return {err: err, errCode: 1};
  //   // throw new Error("Response Error: " + err);
  // }
};


export { hubLabsGet };
