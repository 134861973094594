import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import useUserStore from "../components/UserStore";
import PrimaryLayout from "../components/PrimaryLayout";
import { Link } from "react-router-dom";

function LiveHome() {
  document.title = "Hub Live - Home";

  const { user, signedIn, setSignedIn } = useUserStore((state) => state);

  let randomId = getRandomInt(10000)

  const styles = {};

  return (
    <>
      <PrimaryLayout>
        <Container>
          <p>Choose a room type</p>
          <div>
            <Link to={`${"live-wall/"+randomId}`}>Live Wall</Link>
          </div>
          <div>
            <Link to={`${"accumulator/"+randomId}`}>Accumulator Wall</Link>
          </div>
        </Container>
      </PrimaryLayout>
    </>
  );
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


export default LiveHome;
