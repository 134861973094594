import React, { useState, useEffect } from "react";
import { createSearchParams, useParams, useLocation, useNavigate } from "react-router-dom";
import { hubLabsGet } from "../utils/hubLabsApiRequest";
import LeaderboardList from "../components/LeaderboardList";
import Loading from "../components/Loading";
import Form from "react-bootstrap/Form";

/**
 * ---------------------------------------------
 * Leaderboard notes
 * 
 * 6 Second Sprint
 * 30 Second Sprint
 * Max Power
 * 
 * 500m
 * 1k
 * 10k
 * 25k
 * 50k
 * 
 * Box Hill
 * 
 * 
 * */


function Leaderboard() {
  document.title = "Wattbike Hub Leaderboards";
  const navigate = useNavigate();

  // Get object ID from URL
  let { workoutId, year, month } = useParams();

  // Get optional query params. Mainly filters on the displayed data
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Set up some variables that will trigger UI updates
  const [loading, setLoading] = useState(false);
  const [workout, setWorkout] = useState({});
  const [sessions, setSessions] = useState([]);
  const [lastUpdated, setLastUpdated] = useState('');
  const [totalEntries, setTotalEntries] = useState([]);
  const [totalAttempts, setTotalAttempts] = useState([]);
  const [orderedBy, setOrderedBy] = useState(searchParams.get("sort"));
  const [limit, setLimit] = useState(searchParams.get("limit") ? searchParams.get("limit") : 1000);

  // onload
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      let params = {
        workoutId: workoutId,
        year: year,
        month: month,
        limit: limit,
        sort: orderedBy
      }

      let sessionsList;
      try {
        sessionsList = await getLeaderboardSessions(params);
      } catch (error) {
        console.log(error);
      }

      if (sessionsList) {
        await setSessions(sessionsList.sessions);
        await setWorkout(sessionsList.workout);
        await setTotalEntries(sessionsList.fullCount);
        await setTotalAttempts(sessionsList.fullAttemptsCount);
        // calc and add last updated time
        let lastUpdatedString;
        let dateNow = new Date;
        let lastUpdatedDateObject = new Date(sessionsList.lastUpdated);
        var diff = (dateNow.getTime() - lastUpdatedDateObject.getTime()) / 1000; // get seconds difference
        if (diff < 5) {
          lastUpdatedString = `Just now`;
        } else if (diff < 120) {
          lastUpdatedString = `${Math.abs(Math.round(diff))} seconds ago`;
        } else {
          lastUpdatedString = `${Math.abs(Math.round(diff / 60))} minutes ago`;
        }
        await setLastUpdated(lastUpdatedString);
      }

      setLoading(false);
    };
    fetchData().catch("Fetching sessions data error: ", console.error);
  }, [workoutId, orderedBy]);

  const styles = {
    container: {
      maxWidth: 1200,
      margin: "0 auto",
      padding: '0'
    },
    header: {
      textAlign: 'center'
    }
  };

  // Switch Challenge being viewed
  // - get the sort value from the url and assign that before navigating so we dont need to refresh
  const redirectToChallenge = (url) => {

    // assign sort value
    const [, paramString] = url.split('?');
    const params = new URLSearchParams(paramString);
    setOrderedBy(params.get("sort"));

    // go to new challenge
    navigate(url);
  }

  return (
    <>
      <div style={styles.container}>
        <div style={styles.header}>
          <h1>{workout.title}</h1>

          <p>{totalAttempts} entries by {totalEntries} users. Ranked by {orderedBy}. Last updated: {(lastUpdated ? lastUpdated : "Just now")}</p>

          <Form.Control
            as="select"
            className="form-select form-select-override"
            // value=''
            onChange={(event) => { redirectToChallenge(event.target.value); }}
            style={{ "margin": "0 auto", "padding": 5, "width": 300, "fontSize": "1.2em" }}
          >
            <option value="">Select a Leaderboard</option>
            <optgroup label="Challenges">
              <option value="/leaderboard/Yn6ZH2XvuK?sort=time">200m Challenge (time)</option>
              <option value="/leaderboard/wo-1k-distance?sort=time">1k Challenge (time)</option>
              <option value="/leaderboard/wo-5k-distance?sort=time">5k Challenge (time)</option>
              <option value="/leaderboard/vlt7tw8qk2?sort=time">10k Challenge (time)</option>
              <option value="/leaderboard/HInpZ6sYoG?sort=time">15k Challenge (time)</option>
            </optgroup>
            <optgroup label="Tests">
              <option value="/leaderboard/wo-6sec-test?sort=powerMax">6 Sec Test (max power)</option>
              <option value="/leaderboard/wo-30sec-test?sort=powerAvg">30 Sec Test (avg power)</option>
              <option value="/leaderboard/wo-0356757975062e92?sort=powerAvg">20 Min FTP Test (avg power)</option>
            </optgroup>
            <optgroup label="Climbs">
              <option value="/leaderboard/wo-climb-box-hill?sort=time">Box Hill (time)</option>
            </optgroup>
            <optgroup label="Summer Sessions">
              <option value="/leaderboard/TCsHqET9Lo?sort=time">The Tour - Col Bayard (time)</option>
            </optgroup>
            // 
          </Form.Control>
        </div>
        {loading ? (
          <Loading label={"general-loading"} />
        ) : (
          <LeaderboardList data={loading ? [] : sessions} workout={workout} orderedBy={orderedBy} />
        )}
      </div>
    </>
  );
}

//////////////////////////////////////////////////////////////
// Get challenge sessions
//////////////////////////////////////////////////////////////
const getLeaderboardSessions = async (params = {}) => {
  // compile all options
  let requestOptions = {
    resource: "leaderboard",
    params: params,
    cache: false,
  };

  // run the query
  let response = await hubLabsGet(requestOptions);
  return response;
};

export default Leaderboard;
