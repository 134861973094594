import React, {useEffect, useState} from "react";
import TimeFormat from "./formatters/TimeFormat";
import DistanceFormatter from "./formatters/DistanceFormatter";
import { Row, Col } from "react-bootstrap";
import Translate from "./Translate";
import usePreferencesStore from "./PreferenceStore";

const PerformanceStateHeader = (props) => {

  const { units, setUnits } = usePreferencesStore((state) => state);
  const [loading, setLoading] = useState(false);
  const [headerData, setHeaderData] = useState({});
  const [performanceState, setPerferomanceState] = useState({});
  const [selected, setSelected] = useState("mmp");

  // useEffect to control when the logic is run, i.e. only on props change
  useEffect( () => {
    setPerferomanceState(props.data);
  }, [props]);

  const style = {
    container:{
      marginTop: "20px",
      marginBottom: "20px",
      fontFamily: "trim-regular",
      cursor: "pointer",
    },
    metricContainer: {
      textAlign: "center",
      "background-color": "#333333",
      margin: "2px",
      padding: "10px"
      
    },
    metricTitle: {
      textAlign: "center",
      fontSize: ".8em",
      color: "#ed1c24", //#ed1c24
      textTransform: "uppercase",
    },
    metricValue: {
      textAlign: "center",
      marginRight: 15,
      color: "#333",
      fontSize: "2em",
      whiteSpace: "nowrap",
    },
    metricSubValue: {
      textAlign: "center",
      fontSize: ".7em",
      color: "#888888",
    },
    metricUnit: {
      fontSize: ".7em",
    },
    selected: {
      borderBottom: "2px solid black",
    },
  };

  const setMetric = (metric) => {
    setSelected(metric);
    props.setMetric(metric);
  }

  return loading ? (
    <>
     
    </>
  ) : (
    <>
      <Row style={style.container}>
      <Col onClick={() => setMetric("mmp")} >
          <div style={style.metricTitle}>
          MMP
          </div>
          <div style={ selected === 'mmp' ? {...style.metricValue, ...style.selected}: {...style.metricValue}}>
          {/* <div style={{...style.metricValue, ...style.selected}}> */}
            {performanceState.mmp ? performanceState.mmp : "--"}<span style={style.metricUnit}>W</span>
          </div>
          
        </Col>

        <Col onClick={() => setMetric("ftp")}>
          <div style={style.metricTitle}>
          FTP
          </div>
          <div style={ selected === 'ftp' ? {...style.metricValue, ...style.selected}: {...style.metricValue}}>
            {performanceState.ftp ? performanceState.ftp : "--"}<span style={style.metricUnit}>W</span>
          </div>
        </Col>

        <Col onClick={() => setMetric("mhr")}>
          <div style={style.metricTitle}>
          MHR
          </div>
          <div style={ selected === 'mhr' ? {...style.metricValue, ...style.selected}: {...style.metricValue}}>
            {performanceState.mhr ? performanceState.mhr : "--"}<span style={style.metricUnit}>bpm</span>
          </div>
        </Col>

        <Col onClick={() => setMetric("thr")}>
          <div style={style.metricTitle}>
          THR
          </div>
          <div style={ selected === 'thr' ? {...style.metricValue, ...style.selected}: {...style.metricValue}}>
            {performanceState.thr ? performanceState.thr : "--"}<span style={style.metricUnit}>bpm</span>
          </div>
        </Col>

        <Col onClick={() => setMetric("mets")}>
          <div style={style.metricTitle}>
          METS
          </div>
          <div style={ selected === 'mets' ? {...style.metricValue, ...style.selected}: {...style.metricValue}}>
            {performanceState.mets ? performanceState.mets : "--"}
          </div>
        </Col>

        <Col onClick={() => setMetric("powerKg")}>
          <div style={style.metricTitle}>
          powerKg
          </div>
          <div style={ selected === 'powerKg' ? {...style.metricValue, ...style.selected}: {...style.metricValue}}>
            {performanceState.powerKg ? performanceState.powerKg : "--"}<span style={style.metricUnit}>W</span>
          </div>
        </Col>

        <Col onClick={() => setMetric("peakPower6sec")}>
          <div style={style.metricTitle}>
          6sec Peak W
          </div>
          <div style={ selected === 'peakPower6sec' ? {...style.metricValue, ...style.selected}: {...style.metricValue}}>
            {performanceState.peakPower6sec ? performanceState.peakPower6sec : "--"}<span style={style.metricUnit}>W</span>
          </div>
        </Col>

        {/* <Col onClick={() => setMetric("height")}>
          <div style={style.metricTitle}>
          height
          </div>
          <div style={ selected === 'height' ? {...style.metricValue, ...style.selected}: {...style.metricValue}}>
            {performanceState.height ? performanceState.height : "--"}<span style={style.metricUnit}>cm</span>
          </div>
        </Col> */}

        <Col onClick={() => setMetric("weight")}>
          <div style={style.metricTitle}>
          weight
          </div>
          <div style={ selected === 'weight' ? {...style.metricValue, ...style.selected}: {...style.metricValue}}>
            {performanceState.weight ? performanceState.weight : "--"}<span style={style.metricUnit}>kg</span>
          </div>
        </Col>

        <Col onClick={() => setMetric("crf")}>
          <div style={style.metricTitle}>
          crf
          </div>
          <div style={ selected === 'crf' ? {...style.metricValue, ...style.selected}: {...style.metricValue}}>
            {performanceState.crf ? performanceState.crf : "--"}
          </div>
        </Col>
       
      </Row>
      <Row>
      
      </Row>
    </>
  );
};

export default PerformanceStateHeader;
