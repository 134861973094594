import React from 'react';

const TimeFormat = (props) => {

  let minutesAndSeconds = 0;
  
  if(props.input && props.input === 'milliseconds'){
    minutesAndSeconds = millisToMinutesAndSeconds(props.value);
  }else{
    minutesAndSeconds = secondsToMinutesAndSeconds(props.value);    
  }

  return (<>{minutesAndSeconds}</>);
};

const millisToMinutesAndSeconds = (millis) => {
  let minutes = Math.floor(millis / 60000);
  let seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

function secondsToMinutesAndSeconds(secondsIn) {
  let minutes = Math.floor(secondsIn / 60);
  let seconds = ((secondsIn % 60)).toFixed(0);
  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

export default TimeFormat;

