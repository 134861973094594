import { create } from "zustand";

const usePreferencesStore = create((set) => ({
    language: 'en',
    theme: 'dark',
    units: 'metric',
    setLanguage: (language) => set({ language }),
    setTheme: (theme) => set({ theme }),
    setUnits: (units) => set({ units }),
}));

export default usePreferencesStore;