import React, { useEffect, useState } from "react";
import { languageList } from '../assets/lang/Lang';
import usePreferencesStore from './PreferenceStore';

const Translate2 = (props) => {

    // const [translatedText, setTranslatedText] = useState(false);
    const language = usePreferencesStore((state) => state.language);


    let translatedText = '';

    if(!languageList[language] || !languageList[language][props.label]){
        // returning an obvious front end error here to point out what needs 
        // translating, could equally have returned the 'en' version
        translatedText = 'Translate: ' + language + ' ' + props.label;
    }else{
        translatedText = languageList[language][props.label];
    }

    return (<>{translatedText}</>);

}

 
export default Translate2;
