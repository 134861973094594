import React from 'react';

const DateFormat = (props) => {

  let date = new Date(props.date);

  // let formattedDate = date.toUTCString();
  // let formattedDate = date.toDateString();
  let formattedDate = date.toLocaleDateString();

  return (<>{formattedDate}</>);
};

export default DateFormat;

