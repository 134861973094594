import React, {useState, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useUserStore from "../components/UserStore";
import highchartsAccessibility from "highcharts/modules/accessibility";
highchartsAccessibility(Highcharts);

const PerfStateChart = (props) => {

  const [loading, setLoading] = useState(false);
  const [chartOptions, setChartOptions] = useState({});

  // useEffect to control when the logic is run, i.e. only on props change
  useEffect( () => {
    const fetchData = async () => {
      setLoading(true);
      let formattedData = await formatData(props.data);
      updateOptions(formattedData);
      setLoading(false);
    };

    fetchData().catch('Eerror: ', console.error);
  }, [props.metric]);

  const formatData = (data) => {

    let chartData = [];

    for (let i = 0; i < data.length; i++) {
        let perfState = data[i].userPerformanceState;

        const dateStamp = new Date(perfState.updatedAt);
        const timeStamp = dateStamp.getTime();

        chartData.push([timeStamp, perfState[props.metric]]);
        
    }

    return chartData;

  }

  const updateOptions = async (formattedData) => {

    let chartTheme = {
        background: "#ffffff",
        color: "rgba(33,33,33,1)",
        seriesColors: ['#3f93f4', '#ed1c24', '#ffa505'],
        gridColor: "#E6E6E6"
    };

    let series = await [
      {
        color: chartTheme.seriesColors[1],
        showInLegend: false,
        name: props.metric,
        visible: true,
        data: formattedData,
        marker: {
          enabled: true
        }
      }
    ];

    let chartOptions = {
      chart: {
        type: 'spline',
        backgroundColor: chartTheme.background,
        zoomType: 'x',
        animation: true,
        height: 200,
        marginRight: 0,
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      series: series,
      plotOptions: {
        series: {
          animation: false,
          states: {
            hover: {
              enabled: false
            }
          }
        },
        line: {
          lineWidth: 1,
        }
      },
      yAxis: {
        gridLineColor: chartTheme.gridColor,
        title: "",
      },
      xAxis: {
        gridLineColor: chartTheme.gridColor,
        type: 'datetime',
        visible: true,
        crosshair: true,
        style: {
          color: chartTheme.color,
        },
    
        plotLines: [],
        
      }
    };


    setChartOptions(chartOptions);

  };


    return loading ? (
      <div>Loading chart...</div>
    ) : (
        <>
            <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
        </>
    );

}

export default PerfStateChart;
