import React from "react";
import DataTable, { createTheme } from 'react-data-table-component';

const SessionLapsList = (props) => {

  let tableData = [];

  let tableColumns = [
    {
      name: '#',
      selector: 'title',
      sortable: true,
    },
    {
      name: 'From',
      selector: 'from',
      hide: 6000,
    },
    {
      name: 'To',
      selector: 'to',
      hide: 6000,
    },
    {
      name: 'Duration',
      selector: 'duration',
      sortable: true,
      right: true,
    }
  ];

  createTheme('wattbikeTheme', {
    text: {
      primary: '#333333',
      secondary: '#999999',
    },
    background: {
      default: 'none',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#666666',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  });

  const handleRowClick = (row, event) => {
    props.handleLapClick({minSeconds:row.from, maxSeconds:row.to});
  }

  const handleRowSelect = (event) => {
    props.handleRowSelect(event);
  }

  const secondsToMinutesAndSeconds = (secondsIn) => {
    let minutes = Math.floor(secondsIn / 60);
    let seconds = ((secondsIn % 60)).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }
 
  const rowSelectCritera = row => row.selected === true;
  
  const formatLapData = (props) =>{
  
    let minSeconds = 0;
    let maxSeconds = 0;
  
    for (let i = 0; i < props.data.laps.length; i++) {
      if(i === 0){
        minSeconds = 0;
        maxSeconds = props.data.laps[i];
      }else{
        minSeconds = props.data.laps[i-1];
        maxSeconds = props.data.laps[i];
      }
  
      let formattedTime = secondsToMinutesAndSeconds((maxSeconds - minSeconds) / 1000);
  
      tableData.push({ 
        id: i, 
        title: 'Lap: '+ (i+1), 
        from: minSeconds/1000, 
        to: maxSeconds/1000,
        duration: formattedTime,
        selected: false
      });
    }
    
    return true;
  }

  formatLapData(props);

  return (
    <>
      <DataTable
        columns={tableColumns}
        data={tableData}
        theme="wattbikeTheme"
        noHeader={true}
        pagination={false}
        pointerOnHover={true}
        onRowClicked={handleRowClick}
        highlightOnHover={true}
        fixedHeader
        fixedHeaderScrollHeight="300px"
        // selectableRows={true}
        // selectableRowSelected={rowSelectCritera}
        // onSelectedRowsChange={handleRowSelect}
      />
    </>
  )
}

export default SessionLapsList;
