import localDB from "../utils/LocalDB";
var hash = require("object-hash");

/////////////////////////////////////////////////////////////
// Process Raw session file (wbs) data into something more suitable for charting
/////////////////////////////////////////////////////////////
const ProcessData = async (processOptions) => {
  // check local cache
  let requestHash = hash([
    process.env.REACT_APP_CACHE_ID,
    processOptions.fileName,
    processOptions.ranges,
  ]);
  if (processOptions.cache) {
    let cacheResponse = await localDB.localCache.get(requestHash);
    if (cacheResponse) {
      return cacheResponse.value;
    }
  }

  // Process the file if result not in cache
  let file = processOptions.file;
  let cpIntervals = processOptions.cpIntervals;
  let minIndex = processOptions.minIndex;
  let maxIndex = processOptions.maxIndex;
  let ranges = processOptions.ranges;

  let revolutions = [];
  let powerData = [];
  let heartrateData = [];
  let cadenceData = [];
  let speedData = [];
  let pesData = [];
  let balanceData = [];
  let torqueData = [];
  let cumulativeTime = 0;
  let polarDataFull = [];
  let maxPolarForce = 0;
  let secondsPowerArray = []; // the power at each second during a session
  let cpResults = {}; // holds the values used ofr charting
  let headerData = {
    // Holds the current state of the top level data, used because these change when data is zoomed
    distance: 0,
    time: 0,
    pes: 0,
    speed: 0,
    energy: 0,
    powerMax: 0,
    powerAvg: 0,
    hrAvg: 0,
    hrMax: 0,
    cadenceAvg: 0,
    cadenceMax: 0,
    balance: 0,
    standardDeviation: 0,
    weightedPower: 0,
  };

  // build a flat array of revolutions and add cumulative session time
  for (let i = 0; i < file.laps.length; i++) {
    for (let j = 0; j < file.laps[i].data.length; j++) {
      cumulativeTime += parseFloat(file.laps[i].data[j].time);
      file.laps[i].data[j].cumulative = cumulativeTime; // add cumulative time to revolution
      revolutions.push(file.laps[i].data[j]);
    }
  }

  // build an array of power readings by second rather than revolution
  for (let i = 0; i < Math.round(revolutions[revolutions.length - 1].cumulative); i++) {
    let tempObj = revolutions.find((obj) => obj.cumulative >= i); // not ideal for low rpm, i could be way above
    if (tempObj) {
      secondsPowerArray[i] = Math.round(tempObj.power);
    } else {
      secondsPowerArray[i] = 0;
    }
  }

  // loop flat revolutions array to build data suitable for charting
  let powerAvgSubtotal = 0;
  let powerSum = 0;
  let hrAvgSubtotal = 0;
  let cadenceAvgSubtotal = 0;
  let pesAvgSubtotal = 0;
  let balanceSubtotal = 0;
  let forceDataErrorCount = 0;
  let count = 0;
  let rangeCumulative = 0; // experimenting to remove gaps in data due to ranges
  let matrix = {};
  let finalMatrix = [];
  let powerRanges = [];
  let cadenceRanges = [];

  if (!ranges) {
    console.log("no ranges found for processing, using defaults");
    ranges = [
      {
        minIndex: 0,
        maxIndex: revolutions.length - 1,
      },
    ];
  } else {
    console.log("ranges found: ", ranges);
  }

  for (let j = 0; j <= ranges.length - 1; j++) {
    // loop each range

    minIndex = ranges[j].minIndex;
    maxIndex = ranges[j].maxIndex;

    // a bit of sanity checking of the min and max values
    if (minIndex == null || maxIndex == null) {
      minIndex = 0;
      // maxIndex = Math.ceil(revolutions[revolutions.length - 1].cumulative);
      maxIndex = revolutions.length - 1;
    } else {
      // console.log("NOT null ranges");
      minIndex = minIndex || minIndex < 0 ? minIndex : 0;
      maxIndex =
        maxIndex && maxIndex !== -1 ? maxIndex : revolutions.length - 1;
    }

    for (let i = minIndex; i <= maxIndex; i++) {
      // loop rows in each range segment

      let revolution = revolutions[i];

      count++;

      // Master chart data
      if (revolution) {
        powerData.push([revolution.cumulative, parseInt(revolution.power)]);
        heartrateData.push([
          revolution.cumulative,
          parseInt(revolution.heartrate),
        ]);
        cadenceData.push([revolution.cumulative, parseInt(revolution.cadence)]);
        speedData.push([revolution.cumulative, parseInt(revolution.speed)]);

        // Header data
        headerData.distance += parseFloat(revolution.distance);
        headerData.time += parseFloat(revolution.time);
        headerData.pes = 0;
        headerData.speed = 0;
        headerData.energy += 1; // need calc from model b
        headerData.powerMax =
          revolution.power > headerData.powerMax
            ? parseFloat(revolution.power)
            : headerData.powerMax;
        headerData.hrMax =
          revolution.heartrate > headerData.hrMax
            ? parseFloat(revolution.heartrate)
            : headerData.hrMax;
        headerData.cadenceMax =
          revolution.cadence > headerData.cadenceMax
            ? parseFloat(revolution.cadence)
            : headerData.cadenceMax;

        // Header subtotals used for averages after the loop is complete
        powerSum += parseFloat(revolution.power);
        powerAvgSubtotal += parseFloat(revolution.force) * 3.1415 * 2 * 0.17;
        hrAvgSubtotal += parseInt(revolution.heartrate);
        // cadenceAvgSubtotal += parseFloat(revolution.cadence);
        cadenceAvgSubtotal +=
          parseFloat(revolution.cadence) * parseFloat(revolution.time);
        balanceSubtotal += parseFloat(revolution.balance);
        if (revolution.pes) {
          // PES data
          pesAvgSubtotal += parseFloat(revolution.pes.combinedCoefficient);
          pesData.push([
            revolution.cumulative,
            parseFloat(revolution.pes.combinedCoefficient) * 100,
          ]);
        } else {
          forceDataErrorCount++;
        }

        // Balance data
        balanceData.push([revolution.cumulative, parseInt(revolution.balance)]);

        // Torque data
        torqueData.push([
          revolution.cumulative,
          parseInt((9.5488 * revolution.power) / revolution.cadence),
        ]);

        // Polar chart data
        if (revolution.polar) {
          let polarChartRevolution = [];
          let pointInterval = 0;
          let revolutionMaxForce = 0;

          // build one full polar spline
          revolution.polar.force.split(",").forEach(function (value, index) {
            if (index !== 0) {
              if (index <= revolution.polar.lcnt) {
                pointInterval += 180 / revolution.polar.lcnt;
              } else {
                pointInterval +=
                  180 / (revolution.polar.cnt - revolution.polar.lcnt);
              }
            }

            // get max force value for this rev and all vevolutions
            if (parseInt(value) > maxPolarForce) {
              maxPolarForce = parseInt(value);
            }
            if (parseInt(value) > revolutionMaxForce) {
              revolutionMaxForce = parseInt(value);
            }

            // add point to polar spline
            polarChartRevolution.push([
              parseInt(pointInterval),
              parseInt(value),
            ]);
          });

          // add polar spline to full polar stack
          polarDataFull.push({
            colour: "rgba(0,0,0,0.1)",
            data: polarChartRevolution,
            maxValue: revolutionMaxForce,
          });
        } else {
          // add an empty entry if data is missing so indexes match with other charts
          polarDataFull.push({
            colour: "rgba(0,0,0,0.1)",
            data: [[]],
            maxValue: 0,
          });
        }
      }
    }
  }

  // Calculate data for Standard Deviation
  let totalSqrDifference = 0;
  let meanPower = powerSum / powerData.length;
  for (let i = 0; i < powerData.length; i++) {
    let difference = Math.abs(powerData[i][1] - meanPower);
    let differenceSqr = Math.pow(difference, 2);
    totalSqrDifference += differenceSqr;
  }

  // PESmap Data loop
  let powerStep = Math.ceil((Math.ceil(headerData.powerMax / 50) * 50) / 11);
  let cadenceStep = Math.ceil(
    (Math.ceil(headerData.cadenceMax / 10) * 10) / 11
  );

  for (let j = 0; j <= ranges.length - 1; j++) {
    // loop each range
    for (let i = minIndex; i <= maxIndex; i++) {
      // loop rows in each range segment

      let revolution = revolutions[i];

      if (revolution) {
        let power = Math.ceil(revolution.power / powerStep) * powerStep;
        let cadence = Math.ceil(revolution.cadence / cadenceStep) * cadenceStep;

        // initialise the object and build the array of ranges looked at for axis use later
        if (!matrix[power]) {
          matrix[power] = {};
          powerRanges.push(power);
        }
        if (!matrix[power][cadence]) {
          matrix[power][cadence] = { count: 0, value: 0 };
          cadenceRanges.push(cadence);
        }

        // add our data to the object
        let pes = revolution.pes
          ? Math.round(revolution.pes.combinedCoefficient * 100)
          : 0;
        matrix[power][cadence].value = matrix[power][cadence].value += pes;
        matrix[power][cadence].count = matrix[power][cadence].count += 1;
      }
    }
  }

  // clean and sort the PESmap ranges arrays, then set them to state
  powerRanges.sort((a, b) => a - b);
  powerRanges = [...new Set(powerRanges)];
  cadenceRanges.sort((a, b) => a - b);
  cadenceRanges = [...new Set(cadenceRanges)];

  // refactor the PESmap matrix for the heatmap coordinates and average the pes values proportionally
  for (let k = 0; k < powerRanges.length; k++) {
    for (let l = 0; l < cadenceRanges.length; l++) {
      if (matrix[powerRanges[k]][cadenceRanges[l]]) {
        let avgPes = Math.round(
          matrix[powerRanges[k]][cadenceRanges[l]].value /
            matrix[powerRanges[k]][cadenceRanges[l]].count
        );
        if (!avgPes) {
          avgPes = 0;
        }
        finalMatrix.push([l, k, avgPes]);
      } else {
        finalMatrix.push([l, k, null]);
      }
    }
  }

  // complete some of the averages
  headerData.cadenceAvg = Math.round(cadenceAvgSubtotal / headerData.time);
  headerData.powerAvg = Math.round(powerAvgSubtotal / headerData.time);
  headerData.hrAvg = Math.round(hrAvgSubtotal / count);
  headerData.balanceAvg = Math.round(balanceSubtotal / count);
  headerData.pesAvg = pesAvgSubtotal / (powerData.length - forceDataErrorCount);
  headerData.standardDeviation = Math.sqrt(
    totalSqrDifference / powerData.length,
    2
  );

  ///////////////////////////////////////
  // END of main processing
  ///////////////////////////////////////

  ///////////////////////////////////////
  // Weighted Power processing
  ///////////////////////////////////////

  
  let timePeriod = 30;
  let tempTotalRaisedValues = 0;

  // Loop over the seconds array and build results based on durations
  for (let i = 0; i < secondsPowerArray.length; i++) {
    let tempIntervalTotal = 0;
    let tempIntervalAvg = 0;
    

    // rolling 30 second (timePeriod) averages, except for the end of the ride where we have less than 30 seconds
    if (i <= secondsPowerArray.length - timePeriod) {

      // get total power for 30 seconds ahead
      for (var j = 0; j < timePeriod; j++) {
        tempIntervalTotal += secondsPowerArray[i + j];
      }
      // get average to 4th power
      tempIntervalAvg = Math.pow(tempIntervalTotal / timePeriod, 4);

      // add to the sum of raised values
      tempTotalRaisedValues += tempIntervalAvg;
      
    }
  }

  let averageRaisedVlaues = tempTotalRaisedValues / (secondsPowerArray.length - timePeriod);
  let weightedPower = Math.pow(averageRaisedVlaues, 1 / 4)
  headerData["weightedPower"] = Math.round(weightedPower);

  ///////////////////////////////////////
  // Critical Power Chart Processing
  ///////////////////////////////////////

  // Set up durations to look for and build result objects
  cpIntervals.forEach(function (interval) {
    cpResults[interval] = {
      avg: null,
      watts: null,
      start: null,
      end: null,
    };
  });

  // Loop over the seconds array and build results based on durations
  for (let i = 0; i < secondsPowerArray.length; i++) {
    let tempMax = []; // hold the running totals for each interval range

    // find the best x second segments
    cpIntervals.forEach(function (interval) {
      tempMax[interval] = 0; // set up the current interval range total

      if (i <= secondsPowerArray.length - interval) {
        for (var j = 0; j < interval; j++) {
          tempMax[interval] += secondsPowerArray[i + j];
        }
        if (tempMax[interval] > cpResults[interval].watts) {
          cpResults[interval] = {
            avg: Math.round(tempMax[interval] / interval),
            watts: tempMax[interval],
            start: i,
            end: i + j,
          };
        }
      }
    });
  }

  minIndex = ranges[0].minIndex;
  maxIndex = ranges[0].maxIndex;

  ///////////////////////////////////////
  // Return the processed file data and 
  // save to cache
  ///////////////////////////////////////
  
  let processedData = {
    revolutions: revolutions,
    powerData: powerData,
    heartrateData: heartrateData,
    cadenceData: cadenceData,
    speedData: speedData,
    pesData: pesData,
    balanceData: balanceData,
    torqueData: torqueData,
    polarDataFull: polarDataFull,
    maxPolarForce: maxPolarForce,
    cpResults: cpResults,
    cpIntervals: cpIntervals,
    headerData: headerData,
    ranges: ranges,
    pesMapData: {
      powerRanges: powerRanges,
      cadenceRanges: cadenceRanges,
      matrix: finalMatrix,
    },
  };

  // save response to cache
  localDB.localCache.put({ key: requestHash, value: processedData });

  return processedData;
};

export default ProcessData;
