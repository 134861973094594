import React, { useState, useEffect } from "react";
import "../components/Dashboard.css";
import PrimaryLayout from "../components/PrimaryLayout";
import { useParams } from "react-router-dom";
import useUserStore from "../components/UserStore";
import SessionsList from "../components/SessionsList";
import { hubGet } from "../utils/hubApiRequest";
import Translate from "../components/Translate";
import BackButton from "../components/BackButton";
import PerformanceStateHeader from "../components/PerformanceStateHeader";
import PerfStateChart from "../components/PerfStateChart";
import TrainingFocusFormatter from "../components/formatters/TrainingFocusFormatter";
import TimeFormat from "../components/formatters/TimeFormat";
import Loading from "../components/Loading";

const Dashboard = (props) => {

  document.title = "Hub Clubs - Athlete Dashboard";

  // Global and local state setup
  const { user, signedIn, setUser, setSignedIn } = useUserStore((state) => state);
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [performanceState, setPerferomanceState] = useState({});
  const [athleteDetails, setAthleteDetails] = useState({});
  const [bikeDetails, setBikeDetails] = useState({});
  const [chartMetric, setChartMetric] = useState("mmp");
  const [summaryStats, setSummaryStats] = useState({week:{sessions: 0, workouts: 0, duration: 0, avgPower: 0}, month:{sessions: 0, workouts: 0, duration: 0, avgPower: 0}});

  // Get object ID from URL
  let { objectId } = useParams();

  // useEffect to control when the logic is run, i.e. onl on props change
  useEffect( () => {
    const fetchData = async () => {
      await setLoading(true);
      let sessionsList = await getSessions(user, objectId);
      setPerferomanceState(sessionsList.results[0].userPerformanceState);
      setAthleteDetails(sessionsList.results[0].user);
      setBikeDetails(sessionsList.results[0].wattbikeDevice);
      let summaryData = calculateSummaryData(sessionsList);
      setSummaryStats(summaryData);
      await setSessions(sessionsList.results);
      await setLoading(false);
    };
    fetchData().catch('Fetching sessions data error: ', console.error);
  }, [props]);


  return loading ? (
    <>
      <PrimaryLayout>
        <BackButton />
        <h2><Translate label={"athlete_dashboard-title"} /></h2>
        <Loading label={"general-loading"} />
      </PrimaryLayout>
    </>
  ) : (
    <>
      <PrimaryLayout>
        <BackButton />
        <h2><Translate label={"athlete_dashboard-title"} /> - {athleteDetails.displayName}</h2>
        <h6><Translate label={"athlete_dashboard-focus"} />: <TrainingFocusFormatter data={performanceState.trainingFocus} /> </h6>
        <hr/>
        <h5>{summaryStats.week.sessions} rides and <TimeFormat value={summaryStats.week.duration} input="milliseconds"/> minutes ridden this week,  {summaryStats.month.sessions} rides and <TimeFormat value={summaryStats.month.duration} input="milliseconds"/> minutes ridden this month</h5>
        <hr/>
        <h4><Translate label={"athlete_dashboard-perf_state"} /></h4>
        <PerformanceStateHeader data={performanceState} setMetric={setChartMetric}/> 
        <hr/>
        <h4><Translate label={"athlete_dashboard-recent"} /> {chartMetric}</h4>
        <p><Translate label={"athlete_dashboard-change_metric"} /></p>
        <PerfStateChart
            data={sessions}
            metric={chartMetric}
          />
        <h4><Translate label={"athlete_dashboard-recent_activity"} /></h4>
        <SessionsList data={sessions}/>
      </PrimaryLayout>
    </>
  );
};

//
const getSessions = async (user, objectId) => {
  // build the 'where' object
  const where = JSON.stringify({
    user: {
      __type: "Pointer",
      className: "_User",
      objectId: objectId,
    }
  });

  // compile all options
  let requestOptions = {
    resource: "RideSession",
    authUser: user,
    where: where,
    include: ["sessionSummary", "user", "userPerformanceState"],
    order: "-startDate",
    limit: 100,
    skip: null,
  };

  // run the query
  let response = await hubGet(requestOptions);
  return response;
};

const calculateSummaryData = (sessionsList) => {
  
  // datestamps for today and 7 days ago
  let today = new Date(Date.now()+1); // start of tomorrow i.e. end of today
  let sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  let thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
  let summary = {
    week:{
      sessions: 0, 
      workouts: 0, 
      duration: 0, 
      avgPower: 0
    },
    month:{
      sessions: 0, 
      workouts: 0, 
      duration: 0, 
      avgPower: 0
    }
  }

  for (let i = 0; i < sessionsList.results.length; i++) {
    const session = sessionsList.results[i];

    let sessionDate = new Date(session.startDate.iso);

    // week stats
    if(sessionDate > sevenDaysAgo && sessionDate <= today){
      ++summary.week.sessions;
      summary.week.duration += session.sessionSummary.time;
      if(summary.training){++summary.week.workouts;}
    }

    // month stats
    if(sessionDate > thirtyDaysAgo && sessionDate <= today){
      ++summary.month.sessions;
      summary.month.duration += session.sessionSummary.time;
      if(summary.training){++summary.month.workouts;}
    }
  }

  return summary;

}

export default Dashboard;
