import React, {useEffect, useState} from "react";
import usePreferencesStore from "../components/PreferenceStore";
import useUserStore from "../components/UserStore";
import PrimaryLayout from "../components/PrimaryLayout";
import Translate from "../components/Translate";
import Button from "react-bootstrap/Button";
import localDB from "../utils/LocalDB";
import { Link } from "react-router-dom";

function Preferences() {
  document.title = "Hub Clubs - Preferences";

  const { language, setLanguage } = usePreferencesStore((state) => state);
  const { user, signedIn, setSignedIn, setUser } = useUserStore(
    (state) => state
  );
  const [localCacheCount, setLocalCacheCount] = useState(null);
  const [cleared, setCleared] = useState(0);


  
  useEffect(() => {
    let localCacheCount = localDB.localCache.count().then(function(count){setLocalCacheCount(count);});
    // console.log("count: ", count);
    // setCount(count);
  }, [cleared]);


  const signout = () => {
    setUser(false);
    setSignedIn(false);
  };

  const clearCache = () => {
    localDB.localCache.clear();
    setCleared(cleared+1);
  };

  return (
    <>
      <PrimaryLayout>
        <h2>
          <Translate label={"preferences-set_language"} />
        </h2>
        <div>
          <Button variant="secondary" onClick={() => setLanguage("en")}>
            English
          </Button>
          &nbsp;
          <Button variant="secondary" onClick={() => setLanguage("fr")}>
          Français
          </Button>
        </div>
        {/* <br />
        <h2>
          <Translate label={"preferences-athletes"} />
        </h2>
        <ul>
          {user.athletes.map((athlete) => {
            return <li>{`${athlete.name} (${athlete.objectId})`}</li>;
          })}
        </ul> */}
        <br />
        <h2>Cache</h2>
        Local Cache Count: { localCacheCount } <br/>
        <Link  onClick={() => clearCache()} >Clear</Link>
        <br/> <br/>
        <h2>
          <Translate label={"preferences-account"} />
        </h2>
        <p><Translate label={"preferences-edit_info_copy"} /></p>
        <div>
          <b>ID:</b> {user.objectId} <br/>
          <b>Display Name:</b> {user.displayName} <br/>
          <b>Email:</b> {user.email}
        </div>
        <br/>
        <div>
          <Button variant="secondary" onClick={() => signout()}>
            <Translate label={"preferences-sign_out"} />
          </Button>
        </div>
      </PrimaryLayout>
    </>
  );
}
export default Preferences;
