import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from "react-bootstrap/Form";
import DateFormat from "../components/formatters/DateFormatter";
import DataTable from "react-data-table-component";
import Translate from "../components/Translate";
import { FaMedal } from "react-icons/fa6";

const SessionsList = (props) => {
  const [loading, setLoading] = useState(false);
  const [formattedTableData, setFormattedTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [nameFilteredTableData, setNameFilteredTableData] = useState(null);
  const [filterName, setfilterName] = useState();
  const [filterAgeGroup, setFilterAgeGroup] = useState('');
  const [filterGender, setFilterGender] = useState('');
  const mobileBreakpoint = 500;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileBreakpoint);

  // Set up ordering
  let orderAscending = false;
  if (props.orderedBy === 'time') { orderAscending = true; }

  // Trigger name filter update on change
  useEffect(() => {
    if (filterName && filterName.length > 0) {
      filterResultsName();
    } else {
      setNameFilteredTableData(null);
      // setFilteredTableData(formattedTableData); // reset the filtered results
    }
  }, [filterName]);

  // Trigger ageGroup filter update on change
  useEffect(() => {
    if (filterAgeGroup || filterGender) {
      setNameFilteredTableData(null);
      setfilterName('');
      filterResultsSubSegment();
    } else {
      setNameFilteredTableData(null);
      setfilterName('');
      setFilteredTableData(formattedTableData); // reset the filtered results
    }
  }, [filterAgeGroup, filterGender]);

  // Filter current age grouping by name
  // - Difference between this and segment filtering is this filter 
  // - does NOT re-number at the same time. This is for users to 
  // - find themselves in the current sub-ranking, rather than show 
  // - a new a sub-ranking
  const filterResultsName = () => {

    if (filterName.length > 0) {
      let tempResults = [];

      filteredTableData.forEach(activity => {
        let expression = `(.*)(${filterName.toLowerCase()})(.*)`;
        const filterNameRegex = new RegExp(expression);
        if (filterNameRegex.test(activity.user.toLowerCase())) {
          tempResults.push(activity);
        }
      });
      setNameFilteredTableData(tempResults);
    } else {
      setNameFilteredTableData(null);
    }

  }

  // Filter datatable array on ageGroup
  // - Difference between this and name filtering is this filter 
  // - re-numbers at the same time as this is a sub-ranking
  const filterResultsSubSegment = () => {

    let tempResults = [];
    let counter = 1;

    formattedTableData.forEach((activity, i) => {
      if (
        (activity.ageGroup === filterAgeGroup || filterAgeGroup === '') &&
        (activity.gender === filterGender || filterGender === '')
      ) {

        activity['position'] = counter;
        tempResults.push(activity);
        counter++;
      }
    });

    setFilteredTableData(tempResults);
  }

  // set up the datatable structure
  const [tableColumns, setTableColumns] = useState([
    {
      // selector: (row, i) => (i <= 2 ? <FaMedal style={{ fontSize: '1.8em' }}/> : <div style={{ fontSize: '1.8em' }}>{i+1}</div>),
      selector: (row, i) => <div style={{ fontSize: '1.8em' }}>{row.position}</div>,
      sortable: false,
      center: true,
      maxWidth: "30px",
      id: "position"
    },
    {
      name: <Translate label={"table-rider"} />,
      selector: (row, i) => <><div style={{ fontSize: '1.2em' }}>{row.user}</div><div style={{ fontSize: '.7em' }}>{row.userId}</div></>,
      sortable: false,
      id: "rider"
    },
    {
      name: <Translate label={"table-date"} />,
      selector: (row, i) => row.date,
      cell: (row, i) => <DateFormat date={row.date} />,
      sortable: false,
      right: false,
      id: "date"
    },
    // {
    //   name: <Translate label={"table-ageGroup"} />,
    //   selector: (row, i) => row.ageGroup,
    //   // cell: (row, i) => row.ageGroup,
    //   // sortable: false,
    //   right: false,
    //   id: "ageGroup"
    // },
    {
      name: <Translate label={"table-model"} />,
      selector: (row, i) => row.model,
      sortable: false,
      right: false,
    },
    {
      name: <Translate label={"table-time"} />,
      selector: (row, i) => row.time,
      cell: (row, i) => (props.orderedBy === 'time' ? <div style={{ fontSize: '1.0em', fontWeight: 'bold' }}>{row.formattedTime}</div> : row.formattedTime),
      sortable: false,
      // maxWidth: "50px",
      id: "time"
    },
    // {
    //   name: <Translate label={"table-powerKg"} />,
    //   selector: (row, i) => row.powerKg,
    //   cell: (row) =>
    //     (props.orderedBy === 'powerKg' ? <div style={{ fontSize: '1.0em', fontWeight: 'bold' }}>{row.powerKg.toFixed(2)}W</div> : row.powerKg.toFixed(2) + "W"),
    //   sortable: false,
    //   // maxWidth: "50px",
    //   id: "powerKg"
    // },
    {
      name: <Translate label={"table-powerAvg"} />,
      selector: (row, i) => row.powerAvg,
      cell: (row) =>
        (props.orderedBy === 'powerAvg' ? <div style={{ fontSize: '1.0em', fontWeight: 'bold' }}>{Math.round(row.powerAvg)}W</div> : Math.round(row.powerAvg) + "W"),
      sortable: false,
      // maxWidth: "50px",
      id: "powerAvg"
    },
    {
      name: <Translate label={"table-powerMax"} />,
      selector: (row, i) => row.powerMax,
      cell: (row) =>
        (props.orderedBy === 'powerMax' ? <div style={{ fontSize: '1.0em', fontWeight: 'bold' }}>{Math.round(row.powerMax)}W</div> : Math.round(row.powerMax) + "W"),
      sortable: false,
      // maxWidth: "50px",
      id: "powerMax"
    },

  ]);

  const [tableColumnsMobile, setTableColumnsMobile] = useState([
    {
      selector: (row, i) => <div style={{ fontSize: '1.8em' }}>{row.position}</div>,
      sortable: false,
      center: true,
      maxWidth: "30px",
      id: "position"
    },
    {
      name: <Translate label={"table-rider"} />,
      selector: (row, i) => <><div style={{ fontSize: '1.2em' }}>{row.user}</div><div style={{ fontSize: '.7em' }}>{row.userId}</div></>,
      sortable: false,
      maxWidth: "50%"
    },
    {
      name: <Translate label={"table-time"} />,
      selector: (row, i) => row.time,
      cell: (row, i) => (props.orderedBy === 'time' ? <div style={{ fontSize: '1.0em', fontWeight: 'bold' }}>{row.formattedTime}</div> : row.formattedTime),
      sortable: false,
      maxWidth: "30px",
      id: "time"
    },
    {
      name: <Translate label={"table-powerAvg"} />,
      selector: (row, i) => row.powerAvg,
      cell: (row) =>
        (props.orderedBy === 'powerAvg' ? <div style={{ fontSize: '1.0em', fontWeight: 'bold' }}>{Math.round(row.powerAvg)}W</div> : Math.round(row.powerAvg) + "W"),
      sortable: false,
      maxWidth: "30px",
      id: "powerAvg"
    },
    {
      name: <Translate label={"table-powerMax"} />,
      selector: (row, i) => row.powerMax,
      cell: (row) =>
        (props.orderedBy === 'powerMax' ? <div style={{ fontSize: '1.0em', fontWeight: 'bold' }}>{Math.round(row.powerMax)}W</div> : Math.round(row.powerMax) + "W"),
      sortable: false,
      maxWidth: "30px",
      id: "powerMax"
    },
  ]);

  useEffect(() => {
    const formatDate = async () => {
      setLoading(true);
      let tableData = await formatSessionData(props.data);
      await setFormattedTableData(tableData);
      await setFilteredTableData(tableData);
      setLoading(false);
    };
    formatDate().catch("Format date error: ", console.error);
  }, []);


  const customStyles = {
    rows: {
      style: {
        minHeight: '62px', // override the row height
        background: '#F7F7F7',
        marginTop: '1em',
        borderRadius: '15px',
        fontSize: '1em',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '0px',
      },
    },
    cells: {
      style: {
        paddingLeft: '0px', // override the cell padding for data cells
        paddingRight: '0px',
      },
    },
  };

  const customStylesMobile = {
    rows: {
      style: {
        minHeight: '62px',
        background: '#F7F7F7',
        marginTop: '.5em',
        borderRadius: '10px',
        fontSize: '1em',
      },
    },
    headCells: {
      style: {
        paddingLeft: '5px',
        paddingRight: '0px',
      },
    },
    cells: {
      style: {
        paddingLeft: '5px',
        paddingRight: '0px',
        marginLeft: '0px',
        marginRight: '0px',
      },
    },
  };


  const formatSessionData = (sessions) => {
    // format data for the data table
    let tableData = [];

    if (!sessions || sessions.length === 0) { return tableData; }

    for (let i = 0; i < sessions.length; i++) {

      // if (sessions[i].state === "ready" && sessions[i].rideSummary_obj) {

      let formattedTime = millisToMinutesAndSeconds(
        sessions[i].rideSummary_obj.time
      );

      tableData.push({
        position: i + 1,
        id: sessions[i].objectId,
        title: sessions[i].title,
        userId: sessions[i].user_obj._id,
        ageGroup: sessions[i].user_obj.ageGroup,
        gender: sessions[i].user_obj.gender,
        model: sessions[i].model,
        user: `${sessions[i].user_obj.firstName} ${sessions[i].user_obj.initial}`,
        pes: sessions[i].rideSummary_obj.pesCombinedCoefficient,
        powerAvg: sessions[i].rideSummary_obj.powerAvg,
        powerMax: sessions[i].rideSummary_obj.powerMax,
        // powerKg: sessions[i].rideSummary_obj.powerKg,
        time: sessions[i].rideSummary_obj.time,
        formattedTime: formattedTime,
        distance: sessions[i].rideSummary_obj.distance,
        date: sessions[i].startDate,
      });
      // }
    }
    return tableData;
  };

  const millisToMinutesAndSeconds = (millis) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    let secondsDetail = ((millis % 60000) / 1000).toFixed(2);
    // return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    if (minutes < 5) {
      return `${minutes}:${(seconds < 10 ? "0" : "") + secondsDetail}`
    } else {
      return `${minutes}:${(seconds < 10 ? "0" : "") + seconds}`
    }

  };


  ////////////////////////////////////////

  if (loading) {
    return (
      <Translate label={"general-loading"} />
    );
  } else if (isMobile) {
    return (
      <>
        <br />
        <Container>
          <Row>
            <Col style={{ "textAlign": "center", "padding": 5, "width": 300, "fontSize": "1.2em" }}>
              Set your category:
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                as="select"
                className="form-select form-select-override"
                value={filterAgeGroup}
                onChange={(event) => { setFilterAgeGroup(event.target.value); }}
                style={{ "padding": 5, "marginTop": 5, "fontSize": "1.2em" }}
              >
                <option value="">Age Group</option>
                <option value="">Open</option>
                <option value="U10">U10</option>
                <option value="U12">U12</option>
                <option value="U14">U14</option>
                <option value="U16">U16</option>
                <option value="Junior">Junior</option>
                <option value="Senior">Senior</option>
                <option value="35-39">35-39</option>
                <option value="40-44">40-44</option>
                <option value="45-49">45-49</option>
                <option value="50-54">50-54</option>
                <option value="55-59">55-59</option>
                <option value="60-64">60-64</option>
                <option value="65-69">65-69</option>
                <option value="70-74">70-74</option>
                <option value="75+">75+</option>
              </Form.Control>
            </Col>
            <Col>
              <Form.Control
                as="select"
                className="form-select form-select-override"
                value={filterGender}
                onChange={(event) => { setFilterGender(event.target.value); }}
                style={{ "padding": 5, "marginTop": 5, "fontSize": "1.2em" }}
              >
                <option value="">Sex</option>
                <option value="m">Male</option>
                <option value="f">Female</option>
              </Form.Control>
            </Col>
          </Row>
          <Row>
            <Col style={{ "textAlign": "center", "marginTop": 10, "padding": 5, "width": 300, "fontSize": "1.2em" }}>
              Search:
            </Col>
          </Row>
          <Row>
            <Col>
            <Form.Control
                id="filterSearchMobile"
                type="text"
                placeholder="Search current age group"
                value={filterName}
                onChange={(event) => { setfilterName(event.target.value); }}
                style={{ "textAlign": "center", "margin": "0 auto", "padding": 5, "width": 300, "fontSize": "1.2em" }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                columns={tableColumnsMobile}
                data={(nameFilteredTableData ? nameFilteredTableData : filteredTableData)}
                customStyles={customStylesMobile}
                // conditionalRowStyles={conditionalRowStyles}
                noHeader={true}
                pagination={true}
                // fixedHeader={true}
                paginationPerPage={20}
                paginationRowsPerPageOptions={[20, 50, 100]}
                defaultSortFieldId={props.orderedBy}
                defaultSortAsc={orderAscending}
              />
            </Col>
          </Row>
        </Container>
      </>

    )
  } else {
    return (
      <>
        <br /><br />
        <Container>
          <Row >
            <Col sm={12} md={12} style={{ "float": "left", "marginLeft": 8, "padding": 5, "width": 200, "fontSize": ".8em" }}>
              Set your category:
            </Col>
          </Row>

          <Row >
            <Col sm={12} md={12}>
              <Form.Control
                as="select"
                className="form-select form-select-override"
                value={filterAgeGroup}
                onChange={(event) => { setFilterAgeGroup(event.target.value); }}
                style={{ "float": "left", "padding": 5, "marginRight": 5, "width": 150, "fontSize": ".8em" }}
              >
                <option value="">All Age Groups</option>
                <option value="U10">U10</option>
                <option value="U12">U12</option>
                <option value="U14">U14</option>
                <option value="U16">U16</option>
                <option value="Junior">Junior</option>
                <option value="Senior">Senior</option>
                <option value="35-39">35-39</option>
                <option value="40-44">40-44</option>
                <option value="45-49">45-49</option>
                <option value="50-54">50-54</option>
                <option value="55-59">55-59</option>
                <option value="60-64">60-64</option>
                <option value="65-69">65-69</option>
                <option value="70-74">70-74</option>
                <option value="75+">75+</option>
              </Form.Control>

              <Form.Control
                as="select"
                className="form-select form-select-override"
                value={filterGender}
                onChange={(event) => { setFilterGender(event.target.value); }}
                style={{ "float": "left", "padding": 5, "width": 100, "fontSize": ".8em" }}
              >
                <option value="">All Sex</option>
                <option value="m">Male</option>
                <option value="f">Female</option>
              </Form.Control>
              <Form.Control
                id="filterSearch"
                type="text"
                placeholder="Search current age group"
                value={filterName}
                onChange={(event) => { setfilterName(event.target.value); }}
                style={{ "float": "right", "padding": 5, "width": 200, "fontSize": ".8em" }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <DataTable
                columns={tableColumns}
                data={(nameFilteredTableData ? nameFilteredTableData : filteredTableData)}
                noHeader={true}
                pagination={true}
                // conditionalRowStyles={conditionalRowStyles}
                // pointerOnHover={true}
                // onRowClicked={handleRowClick}
                // fixedHeader={true}
                customStyles={customStyles}
                paginationPerPage={20}
                paginationRowsPerPageOptions={[20, 50, 100]}
                defaultSortFieldId={props.orderedBy}
                defaultSortAsc={orderAscending}
              />
            </Col>
          </Row>
        </Container>
      </>
    )
  };

};

export default SessionsList;
