import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../components/Dashboard.css";
import PrimaryLayout from "../components/PrimaryLayout";
import useUserStore from "../components/UserStore";
import Translate from "../components/Translate";
import Button from 'react-bootstrap/Button';
import { hubPut } from "../utils/hubApiRequest";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from '../assets/img/wattbike_logo_410x.avif';
import { Container, Row, Col } from "react-bootstrap";
import CoachLink from "../components/CoachLink";
import QrCode from "../components/QrCode";

const ShareDetails = (props) => {
  document.title = "Hub Clubs - Connect with a Coach";

  const navigate = useNavigate();

  // Get coaches object ID from URL
  let { objectId, displayName } = useParams();

  // Logged in user details
  const { user, signedIn, setUser, setSignedIn } = useUserStore(
    (state) => state
  );

  // if not logged in send them now
  if(!signedIn || !user || !user.objectId){
    window.location.replace(`${process.env.REACT_APP_WBAUTH_URL}?client_id=${process.env.REACT_APP_WBAUTH_CLIENT}&response_type=token&scope=email+openid&redirect_uri=${process.env.REACT_APP_WBAUTH_CALLBACK}&state="/coachlink/${objectId}/${displayName}"`);
  }  

  // local state
  const [loading, setLoading] = useState(false);
  const [linked, setLinked] = useState(false);
  const [linkError, setLinkError] = useState(false);

  // do any data fetching, processing etc
  useEffect(() => {
    setLoading(true);
    setLoading(false);
  }, [user]);

  // Add this coach to the logged in user's account
  const updateUser = async () => {

    // get the current list and add the new coach ID
    let newCoachesList = user.coaches;
    newCoachesList.push(objectId);

    // build the request
    let requestOptions = {
        resource: "users/" + user.objectId,
        authUser: user,
        data: {coaches: newCoachesList}
      };
    
      // run the query
      let response = await hubPut(requestOptions);
      if (response.status === 200){
        setLinked(true);
      } else {
        console.log("Link error: ", response);
        setLinkError(response.status)
      }
  }

  const styles = {
    
  }


  // Render
  if (loading) {
    return (
      <PrimaryLayout>
        <h2>
          <Translate label={"general-loading"} />
        </h2>
      </PrimaryLayout>
    );
  } else {
    return (
      <PrimaryLayout>
        <Container>
          <p>Click to download your QR code to print out, or scan it directly:</p>
          <QrCode coach={user} />
            
          <br />
          <CoachLink coach={user} />
          <br />
            
        </Container>
      </PrimaryLayout>
    );
  }
};


export default ShareDetails;
