import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import powerToZone from "../utils/powerToZone";

const LiveActivityCard = ({user, containerStyle, selectUser}) => {

    let zone = powerToZone(user.live.power, 279);
    // let backgroundColour = zone ? zone.colour : "grey";
    let backgroundColour = "blue";

    let bgHeight = Math.round((user.live.power / 600) * 100);
    let backgroundPosition = `50% ${bgHeight}%`;
    

    const styles = {
        card: {
            display: "block",
            padding: 50,
            borderRadius: "20px",
            display: "inline-block",
            margin: 30,
            background: `linear-gradient(to top, ${backgroundColour} 50%, white 50%)`,
            backgroundSize: "100% 200%",
            backgroundPosition: backgroundPosition,
            transition:"all .3s ease",
            minWidth: 300,
        },
        cardSelected: {
            display: "block",
            padding: 50,
            backgroundColor: "green",
            borderRadius: "20px",
            display: "inline-block",
            margin: 30,
            minWidth: 300,
        },
        cardDimmed: {
            display: "block",
            padding: 50,
            backgroundColor: "grey",
            borderRadius: "20px",
            display: "inline-block",
            margin: 30,
            minWidth: 300,
        },
        cardPew: {
            display: "block",
            padding: 50,
            borderRadius: "20px",
            display: "inline-block",
            margin: 30,
            background: "linear-gradient(to top, red 50%, red 50%)",
            backgroundSize: "100% 200%",
            backgroundPosition: backgroundPosition,
            transition:"all 1s ease",
            minWidth: 300,
        },
        value: {
            fontFamily: "trim-semibold",
            color: "black",
            fontSize: "4em"
        },
        subValue: {
            fontFamily: "trim-semibold",
            color: "black",
            fontSize: "1.3em"
        },
        name: {
            fontFamily: "trim-semibold",
            textAlign: "center",
            color: "black",
            fontSize: "1.3em",
            textTransform: "uppercase",
        }
    };

    return (
        <div style={styles[containerStyle]} onClick={() => selectUser(user._id)}>
            <div style={styles.name}>{user.name}</div>
            <div style={styles.value}>{user.live.power}W</div>
            <hr />
            <div style={styles.subValue}>{Math.round(user.live.cadence)}rpm</div>
            <div style={styles.subValue}>{Math.round(user.live.speed)}km/h</div>
            <div style={styles.subValue}>{Math.round(user.total.distance)}m</div>
            <hr />
            <div style={styles.subValue}>{Math.round(user.total.crankRevs)} revs</div>
            <hr />
            <div style={styles.subValue}>{user.live.performanceState && user.live.performanceState.ftp ? Math.round(user.live.performanceState.ftp) : '--'}W</div>
        </div>
    )
};

//

export default LiveActivityCard;
