import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useUserStore = create(
  persist(
    (set, get) => ({
      user: { name: "NA" },
      signedIn: false,
      setUser: (user) => set({ user }),
      setSignedIn: (signedIn) => set({ signedIn }),
    }),
    {
      name: "useUserStore", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useUserStore;
