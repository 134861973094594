import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import QRCode from "react-qr-code";

const QrCode = (props) => {
  const [loading, setLoading] = useState(false);
  const [qrValue, setQrValue] = useState("this is a test");
  

  useEffect(() => {

  }, []);

  const downloadQR = () => {
    const svg = document.getElementById("qrcode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
  
      const downloadLink = document.createElement("a");
      downloadLink.download = "qrcode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
  
    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  };
  

  const styles = {
    container: {
      padding: 30,
      paddingLeft: 0,
      paddingTop: 0,
      cursor: "pointer",
      height: "auto", 
      // margin: "0 auto", 
      maxWidth: 250, 
      width: "100%"
    }
  };

  if (loading) {
    return <div></div>;
  } else {
    return (
      <div style={styles.container}>
        {/* <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}> */}
        <QRCode
        id="qrcode"
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={`https://clubs.wattbike.com/coachlink/${props.coach.objectId}/${props.coach.displayName}`}
        viewBox={`0 0 256 256`}
        onClick={() => downloadQR()}
        />
    {/* </div> */}
      </div>
    );
  }
};

//

export default QrCode;
