/**
 * ---------------------------------------------
 * Central store for power zones
 * ---------------------------------------------
 */
const zones = () => {

  let ftpZones = [
    {
      from: 0,
      to: 0.55,
      text: "Active Recovery",
      colour: "#dedede",
    },
    {
      from: 0.55,
      to: 0.75,
      text: "Endurance",
      colour: "#cfd9ff",
    },
    {
      from: 0.75,
      to: 0.9,
      text: "Tempo",
      colour: "#d8ffcf",
    },
    {
      from: 0.9,
      to: 1.05,
      text: "Lactate Threshold",
      colour: "#fffdcf",
    },
    {
      from: 1.05,
      to: 1.2,
      text: "VO2max",
      colour: "#ffebcf",
    },
    {
      from: 1.2,
      to: 10,
      text: "Anaerobic Capacity",
      colour: "#ffcfcf",
    },
  ];

  //////////

  let mmpZones = [
    {
      from: 0,
        to: .35,
        text: 'Recovery',
      colour: "#dedede",
    },
    {
      from: .35,
        to: .45,
        text: 'Zone 1',
      colour: "#cfd9ff",
    },
    {
      from: .45,
        to: .55,
        text: 'Zone 2',
      colour: "#d8ffcf",
    },
    {
      from: .55,
        to: .65,
        text: 'Zone 3',
      colour: "#fffdcf",
    },
    {
      from: .65,
        to: .75,
        text: 'Zone 4',
      colour: "#ffebcf",
    },
    {
      from: .75,
        to: .85,
        text: 'Zone 5',
      colour: "#ffcfcf",
    },
    {
      from: .85,
        to: 1,
        text: 'Maximal',
      colour: "#ffcfcf",
    },
    {
      from: 1,
        to: 10,
        text: 'Supra Maximal',
      colour: "#ffcfcf",
    },
  ];

  return {ftp: ftpZones, mmp: mmpZones};
};

export default zones;
