import React from "react";
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import io from 'socket.io-client';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function AccumulatorWall() {
  document.title = "Hub Live - Accumulator Wall";

  // Get the room ID
  const { roomId } = useParams();

  // State to store the current message
  const [roomState, setRoomState] = useState({});
  const [riderList, setRiderList] = useState([]);
  const [viewerList, setViewerList] = useState([]);

  // the website joins the socket room like a rider would, but set as a 'viewer'
  const randomInt = getRandomInt(10000);
  const socketUser = {
    _id: randomInt,
    name: "test web app " + randomInt,
    type: 'viewer',
    performanceState: {
      weight: null,
      ftp: null
    }
  }

  useEffect(() => {
    // Create a socket connection
    const SOCKET_URL = "http://127.0.0.1:4000";
    const socket = io(SOCKET_URL, { autoConnect: false });
    socket.connect();
    console.log("Connecting to: ", roomId);
    socket.emit("joinRoom", { roomSelected: roomId, user: socketUser });

    // Listen for incoming room state changes
    socket.on('roomStateUpdate', (newState) => {
      setRoomState(newState);
    });

    // Clean up the socket connection on unmount
    return () => {
      socket.disconnect();
    };

  }, []);


  // Set number of riders and viewers
  useEffect(() => {
    setRiderList([]);
    setViewerList([]);
    let tempListRider = [];
    let tempListViewer = [];
    if (roomState.riders) {
      Object.keys(roomState.riders).map(key => {
        if (roomState.riders[key].type === 'rider') {
          tempListRider.push(roomState.riders[key]);   
        } else {
          tempListViewer.push(roomState.riders[key]);
        }
      })
      setRiderList(tempListRider);
      setViewerList(tempListViewer);
    }

  }, [roomState.riders]);


  const styles = {
    headerContainer: {
      textAlign: "center",
      colour: "white",
      paddingTop: "2em",
      backgroundColor: "black",
    },
    riderContainer: {
      textAlign: "center",
      colour: "white",
      minHeight: "100vh",
      backgroundColor: "black",
    },
    roomNumber: {
      fontFamily: "trim-semibold",
      textAlign: "center",
      color: "white",
      fontSize: "4em",
      margin: 0
    },
    roomInfo: {
      fontFamily: "trim-semibold",
      textAlign: "center",
      color: "white",
      fontSize: "1em",
      margin: 0
    },
    mainCounter: {
      fontFamily: "trim-semibold",
      textAlign: "center",
      color: "white",
      fontSize: "16em",
      margin: 0
    },
  };

  //

  if (roomState && roomState.stats && roomState.stats.distance) {
    return (
      <>
        <Row style={styles.headerContainer}>
          <div style={styles.roomNumber}>
            {roomId} <br />
          </div>
          <div style={styles.roomInfo}>
            RIDERS: {riderList.length} VIEWERS: {viewerList.length}
          </div>
        </Row>
        <Row style={styles.riderContainer}>
          <div style={styles.mainCounter}>
            {(roomState.stats.distance / 1000).toFixed(2)}km
          </div>
          <div style={styles.roomInfo}>
          {Math.round(roomState.stats.distance)}m
          </div>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row style={styles.headerContainer}>
          <div style={styles.roomNumber}>
            {roomId} <br />
          </div>
        </Row>
        <Row style={styles.riderContainer}>
          <div style={styles.mainCounter}>
            --km
          </div>
        </Row>
      </>
    );
  }

}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export default AccumulatorWall;
