import React from 'react';

const ModelFormatter = (props) => {

  const model = serialToModel(props.serialNumber);

  return (<>{model}</>);
};

function serialToModel(serial) {
  let model = 'Unknown Model';
  const atomRegex = /^(26)[a-zA-Z0-9]{6}/i;
  const atomNgRegex = /^(261)[a-zA-Z0-9]{5}/i;
  const ptRegex = /^(25)[a-zA-Z0-9]{6}/i;
  const icRegex = /^(28)[a-zA-Z0-9]{6}/i;
  const axRegex = /^(30)[a-zA-Z0-9]{6}/i;
  const testRegex = /^(.*)(test-)(.*)/g;

  if(atomNgRegex.test(serial)){
    model = 'Wattbike NG Atom';
  }else if(atomRegex.test(serial)){
    model = 'Wattbike Atom';
  }else if(ptRegex.test(serial)){
    model = 'Wattbike Pro/Trainer';
  }else if(icRegex.test(serial)){
    model = 'Wattbike Icon';
  }else if(axRegex.test(serial)){
    model = 'Wattbike AtomX';
  }else if(testRegex.test(serial)){
    model = 'Virtual Test Device';
  }
  
  return model;
}

export default ModelFormatter;

