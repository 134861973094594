import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PrimaryLayout from "../components/PrimaryLayout";
import useUserStore from "../components/UserStore";
import { hubGet } from "../utils/hubApiRequest";
import Translate from "../components/Translate";
import BackButton from "../components/BackButton";
import { hubGetFile } from "../utils/hubApiRequest";
import ProcessData from "../utils/ProcessData";
import SessionChartPower from "../components/SessionChartPower";
import SessionHeader from "../components/SessionHeader";
import SessionChartHR from "../components/SessionChartHR";
import SessionChartPES from "../components/SessionChartPES";
import SessionChartBalancePie from "../components/SessionChartBalancePie";
import SessionChartCadence from "../components/SessionChartCadence";
import SessionChartPolarAM from "../components/SessionChartPolarAM";
import SessionChartCpCurve from "../components/SessionChartCpCurve"; //
import SessionLapsList from "../components/SessionLapsList"; //
import Highcharts, { color } from "highcharts";
import DateFormat from "../components/formatters/DateFormatter";
import ModelFormatter from "../components/formatters/ModelFormatter";
import SessionOutcomes from "../components/SessionOutcomes"; //
import AthleteTag from "../components/AthleteTag";
import Loading from "../components/Loading";

const Dashboard = (props) => {
  document.title = "Hub Clubs - Session View";

  // Get object ID from URL
  let { objectId } = useParams();

  // Global state setup
  const { user, signedIn, setUser, setSignedIn } = useUserStore(
    (state) => state
  );

  // Local state setup
  const [loading, setLoading] = useState(false);
  const [headerLoading, setHeaderLoading] = useState(false);
  const [session, setSession] = useState({});
  const [file, setFile] = useState({});
  const [originalProcessedData, setOriginalProcessedData] = useState({});
  const [processedData, setProcessedData] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [workout, setWorkout] = useState({});
  // const [firstLoad, setFirstLoad] = useState(true);
  // const [ranges, setRanges] = useState(null);
  const [hoverData, setHoverData] = useState({ power: null, hr: null, cadence: null });
  const [options, setOptions] = useState({
    cacheProcessing: true,
    cacheFile: true,
    cpIntervals: [5, 10, 30, 60, 120, 240, 300, 600, 1200, 3600], // seconds, contiguous periods to calculate power/duration for
  });

  const polarStyle = {
    width: "400px",
    padding: "1em",
  };

  // useEffect to control when the logic is run, i.e. onl on props change
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // get the session
      let sessionRecord = await getSession(user, objectId);
      setSession(sessionRecord);

      // get the raw file
      let file = await hubGetFile({
        fileName: sessionRecord.sessionData.wbs.name,
        cache: options.cacheFile,
      });
      setFile(file);

      // If this is a Workout session (not tests for now), get the workout segments for charting
      if (sessionRecord.training && sessionRecord.training.workout) {
        let workout = await getWorkout(
          user,
          sessionRecord.userPerformanceState.ftp,
          sessionRecord.training.workout.objectId
        );
        await setWorkout(workout);
      }

      // Process data into chart friendly format
      let processOptions = {
        file: file.data,
        fileName: sessionRecord.sessionData.wbs.name,
        cpIntervals: options.cpIntervals,
        ranges: null,
        cache: options.cacheProcessing,
      };

      const processedData = await ProcessData(processOptions);
      await setProcessedData(processedData);
      await setOriginalProcessedData(processedData);
      await setSummaryData(processedData);
      // await setCurrentData(processedData);
      doubleClickListener(); // listens for a chart double click to reset zoom

      setLoading(false);
    };
    fetchData().catch("Fetching session data error: ", console.error);
  }, []);

  ////////////////////////////////////////////////////////////////////////
  // Loop all charts and sync crosshairs
  ////////////////////////////////////////////////////////////////////////
  const syncTooltips = (index, e, point) => {
    Highcharts.charts.forEach(function (chart) {
      if (chart) {
        chart.xAxis[0].drawCrosshair(e, point); // Show the crosshair
      }
    });
  };

  ////////////////////////////////////////////////////////////////////////
  // Loop all charts and sync crosshairs
  ////////////////////////////////////////////////////////////////////////
  const setHoverValues = (index) => {
    let power;
    if (
      processedData.revolutions[index] &&
      processedData.revolutions[index].power
    ) {
      power =
        ": " + processedData.revolutions[index]
          ? parseInt(processedData.revolutions[index].power) + "W"
          : "--";
    } else {
      power = ": --";
    }

    let cadence;
    if (
      processedData.revolutions[index] &&
      processedData.revolutions[index].cadence
    ) {
      cadence =
        ": " + processedData.revolutions[index]
          ? parseInt(processedData.revolutions[index].cadence) + "rpm"
          : "--";
    } else {
      cadence = ": --";
    }

    let hr;
    if (
      processedData.revolutions[index] &&
      processedData.revolutions[index].heartrate
    ) {
      hr =
        ": " + processedData.revolutions[index]
          ? parseInt(processedData.revolutions[index].heartrate) + "bpm"
          : "--";
    } else {
      hr = ": --";
    }

    setHoverData({ power: power, hr: hr, cadence: cadence });
  };

  ////////////////////////////////////////////////////////////////////////
  // Loop all charts and sync zoom extents
  ////////////////////////////////////////////////////////////////////////
  const syncZoom = (e) => {
    Highcharts.charts.forEach(function (chart, i) {
      if (chart && chart.options.chart.sync) {
        chart.xAxis.forEach((axis) => {
          axis.setExtremes(e.min, e.max, true, false, {
            trigger: "syncExtremes",
          });
        });
      }
    });
  };

  ////////////////////////////////////////////////////////////////////////
  // Return the index of the master chart point based on the xaxis time value of the point
  // - Note: in case of zooming, highcharts supplies an x axis value between points, I am getting
  // - the index above the value returned in these cases, hence '>='
  ////////////////////////////////////////////////////////////////////////
  const getIndexFromSeconds = (seconds) => {
    let index = processedData.revolutions.findIndex(
      (x) => x.cumulative >= seconds
    );
    return index;
  };

  ////////////////////////////////////////////////////////////////////////
  // Chart Callbacks
  ////////////////////////////////////////////////////////////////////////

  // Callback to handle master chart zoom
  const handleChangeZoom = async (highchartEvent) => {
    if (
      highchartEvent.trigger !== "syncExtremes" // Prevent feedback loop when charts reset
    ) {
      let minIndex = getIndexFromSeconds(highchartEvent.min);
      let maxIndex = getIndexFromSeconds(highchartEvent.max);
      let ranges = [{ minIndex: minIndex, maxIndex: maxIndex }];

      // setRanges(ranges);
      setCurrentDataRange(ranges);
      syncZoom(highchartEvent);
    }

    // we need to re-add the event listner as we've toggled isLoading
    // doubleClickListener();
  };

  // Callback to handle master chart hover
  const handleChangeToolTip = (point, e) => {
    let xValue = point.x;
    let index = getIndexFromSeconds(xValue);
    syncTooltips(index, e, point);
    if(point.x === null){
      setHoverData({ power: null, hr: null, cadence: null });
    }else{
      setHoverValues(index);
    }
    
  };

  ////////////////////////////////////////////////////////////////////////
  // Crop down the full data range to the zoom level of the master chart
  ////////////////////////////////////////////////////////////////////////
  const setCurrentDataRange = async (selectedRanges) => {
    // setHeaderLoading(true);

    let processOptions = {
      file: file.data,
      fileName: session.sessionData.wbs.name,
      cpIntervals: options.cpIntervals,
      ranges: selectedRanges,
      cache: options.cacheProcessing,
    };

    let newProcessedData = await ProcessData(processOptions);
    await setSummaryData(newProcessedData);
    await setProcessedData(newProcessedData);

    // setHeaderLoading(false);

    // we need to re-add the event listner as we've toggled isLoading
    doubleClickListener();
  };
  ////////////////////////////////////////////////////////////////////////
  // Reset zoom on all charts
  ////////////////////////////////////////////////////////////////////////
  const resetZoom = () => {

    if (originalProcessedData && originalProcessedData.revolutions) {
      let ranges = [
        { minIndex: 0, maxIndex: originalProcessedData.revolutions.length - 1 },
      ];
      let chartExtents = [
        {
          min: 0,
          max: originalProcessedData.revolutions[
            originalProcessedData.revolutions.length - 1
          ].cumulative,
        },
      ];

      // setRanges(ranges);
      setCurrentDataRange(ranges);
      syncZoom(chartExtents);
    }

    //

    // if (processedData && processedData.revolutions) {
    //   Highcharts.charts.forEach(function (chart) {
    //   if (chart && chart.options.chart.sync) {
    //     chart.xAxis.forEach((axis) => {
    //       axis.setExtremes(null, null, true, false, {
    //         trigger: "syncExtremes",
    //       });
    //     });
    //   }
    // });
    // }
  };

  ////////////////////////////////////////////////////////////////////////
  // Set zoom based on lap click
  ////////////////////////////////////////////////////////////////////////
  const handleLapClick = async (range) => {

    // this.setState({ isLoading: true });

    let minIndex = getIndexFromSeconds(range.minSeconds);
    let maxIndex = getIndexFromSeconds(range.maxSeconds);
    let ranges = [{ minIndex: minIndex, maxIndex: maxIndex }];

    // setRanges(ranges);
    setCurrentDataRange(ranges);
    syncZoom({min: range.minSeconds, max:range.maxSeconds});



    // // await this.setState({ranges: [{ "minIndex": minIndex, "maxIndex": maxIndex }]});

    // let processOptions = {
    //   file: file.data,
    //   fileName: this.state.session.sessionData.wbs.name,
    //   cpIntervals: this.state.cpIntervals,
    //   ranges: [{ "minIndex": minIndex, "maxIndex": maxIndex }],
    //   cache: this.state.cache
    // }

    // const processedData = await ProcessData(processOptions);
    // await this.setState({ processedData: processedData, currentData: processedData });

    // // let urlRanges = encodeURIComponent(JSON.stringify(ranges));
    // let urlRanges = JSON.stringify([{ "minIndex": minIndex, "maxIndex": maxIndex }]);
    // let addressBarLocation = `${window.location.origin}/session/${this.props.match.params.id}?ranges=${urlRanges}`;
    // window.history.pushState(null, null, addressBarLocation);

    // this.setState({ isLoading: false });

    // we need to re-add the event listner as we've toggled isLoading
    // this.doubleClickListener();

  }

  ////////////////////////////////////////////////////////////////////////
  // Misc functions
  ////////////////////////////////////////////////////////////////////////

  // Used for testing a slow connection, can be removed when not needed
  // const wait = (ms) => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       resolve(ms);
  //     }, ms);
  //   });
  // };

  // // Redraw all charts
  // const redrawCharts = () => {
  //   Highcharts.charts.forEach(function (chart) {
  //     if (chart) {
  //       chart.redraw();
  //     }
  //   });
  // };

  // double click listener
  const doubleClickListener = (event) => {
    let targets = document.getElementsByClassName("zoomChart");

    // targets[i].removeEventListener(e.type, "dblclick");

    for (var i = 0; i < targets.length; i++) {
      targets[i].addEventListener("dblclick", resetZoom);
    }
  };

  const styles = {
    hoverSummary: {
      position: "fixed",
      left: "50%",
      transform: "translate(-50%, 50%)",
      fontSize: 25,
      padding: 15,
      paddingBottom: 10,
      zIndex: 1000,
      bottom: 30,
      width: 650,
      textAlign: "center",
      backgroundColor: "#666",
      borderRadius: "10px",
      color: "#fff",
      opacity: "100%",
      transition: "bottom 0.5s"
    },
    hidden: {
      bottom: -35,
      transition: "bottom 0.5s"
    }
  }

  return loading || !session || !session.startDate ? (
    <>
      <PrimaryLayout>
        <BackButton />
        <h2><Translate label={"session-page_title"} /></h2>
        <Loading label={"general-loading"} />
      </PrimaryLayout>
    </>
  ) : (
    <>
      <PrimaryLayout>
        <BackButton />
        <div className="zoomChart">
        <div className="row">
            <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9">
              {/* Left */}
              <h2>
                {session.title} - <DateFormat date={session.startDate.iso} />
              </h2>
              <p>
                <ModelFormatter
                  serialNumber={session.wattbikeDevice.serialNumber}
                />
                ({session.wattbikeDevice.serialNumber}) Firmware: v
                {session.wattbikeDevice.firmwareVersion}
              </p>
              <AthleteTag athlete={session.user} />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 text-end">
              {/* Right */}
                Download: <a href={`${process.env.REACT_APP_API_URL}files/${session.sessionData.tcx.name}`}>TCX</a> | <a href={`${process.env.REACT_APP_API_URL}files/${session.sessionData.fit.name}`}>FIT</a>
            </div>
          </div>

          {!session.outcomes ? <><br/><br/><h5>Summary</h5><SessionHeader data={summaryData} /></> : <><br/><br/><h5>Test Outcomes</h5><SessionOutcomes data={session.outcomes} /><hr /><br/><h5>Summary</h5><SessionHeader data={summaryData} /><hr/></>}

          {/* {headerLoading ? null : <SessionHeader data={summaryData} />} */}

          {/* {hoverData.power === null ? null : ( */}
          <div style={ hoverData.power === null ? {...styles.hoverSummary, ...styles.hidden} : {...styles.hoverSummary}}>
            <p>
              
                <>
                  <Translate label={"session-power"} /> {hoverData.power} &nbsp;
                  <Translate label={"session-cadence"} /> {hoverData.cadence}{" "}
                  &nbsp;
                  <Translate label={"session-hr"} /> {hoverData.hr}
                </>
              
            </p>
          </div>
          {/* )} */}

          <h4>
            <Translate label={"session-power"} />
          </h4>
          <SessionChartPower
            data={processedData}
            workoutData={workout}
            session={session}
            handleChangeZoom={handleChangeZoom}
            handleChangeToolTip={handleChangeToolTip}
          />
          {/* <SessionChartPower data={processedData} workoutData={workout} handleChangeZoom={handleChangeZoom} handleChangeToolTip={handleChangeToolTip}/> */}
          <h4>
            <Translate label={"session-cadence"} />
          </h4>
          <SessionChartCadence
            data={processedData}
            handleChangeZoom={handleChangeZoom}
            handleChangeToolTip={handleChangeToolTip}
          />
          {processedData.headerData.hrAvg ? (
            <>
              <h4>
                <Translate label={"session-hr"} />
              </h4>
              <SessionChartHR
                data={processedData}
                handleChangeZoom={handleChangeZoom}
                handleChangeToolTip={handleChangeToolTip}
              />
            </>
          ) : null}
          <h4>PES</h4>
          <SessionChartPES
            data={processedData}
            handleChangeZoom={handleChangeZoom}
            handleChangeToolTip={handleChangeToolTip}
          />
          <h4>Power-Duration Plot</h4>
          <SessionChartCpCurve data={processedData} />

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              {/* Polar */}
              <h3>Polar View</h3>
              <div style={polarStyle}>
                {headerLoading ? null : (
                  <SessionChartPolarAM data={processedData} bg="#ffffff" />
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              {/* Balance Pie */}
              <div>
                <h3>Balance</h3>
                <SessionChartBalancePie data={processedData} bg="#ffffff" />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <h3>Laps</h3>
            <SessionLapsList
              data={session}
              handleLapClick={handleLapClick}
              // handleRowSelect={handleRowSelect}
            />
          </div>
        </div>
      </PrimaryLayout>
    </>
  );
};

//
const getSession = async (user, objectId) => {
  let requestOptions = {
    resource: "RideSession/" + objectId,
    authUser: user,
    where: null,
    include: [
      "user",
      "sessionSummary",
      "userPerformanceState",
      "user.preferences",
      "training.workout",
    ],
    order: null,
    limit: null,
    skip: null,
    cache: true,
  };

  // run the query
  let response = await hubGet(requestOptions);
  return response;
};

////////////////////////////////////////////////////////////////////////
// Fetch Workout from Hub API
////////////////////////////////////////////////////////////////////////
const getWorkout = async (user, ftp, id) => {
  // compile all options
  let requestOptions = {
    resource: "Workout/" + id,
    authUser: user,
    where: null,
    include: null,
    order: null,
    limit: null,
    skip: null,
  };

  // run the query
  let response = await hubGet(requestOptions);

  // create higharts friendly data - only for segment based WOs for now
  let chartData = [];
  if (response.segments) {
    let cumulativeTime = 0;
    for (let i = 0; i < response.segments.length; i++) {
      let targetPower = response.segments[i].start * (ftp / 100);

      if (i === 0) {
        chartData.push([0, 0]);
        chartData.push([0, targetPower]);
        chartData.push([response.segments[i].duration, targetPower]);
        chartData.push([response.segments[i].duration, 0]);
      } else {
        chartData.push([cumulativeTime, 0]);
        chartData.push([cumulativeTime, targetPower]);
        chartData.push([
          cumulativeTime + response.segments[i].duration,
          targetPower,
        ]);
        chartData.push([cumulativeTime + response.segments[i].duration, 0]);
      }

      cumulativeTime += response.segments[i].duration;

      // chartData.push([cumulativeTime,response.segments[i].end]);
    }
  } else {
    chartData = null;
  }

  response.chartData = chartData;

  return response;
};

export default Dashboard;
