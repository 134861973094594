import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../components/Dashboard.css";
import CoachLinkLayout from "../components/CoachLinkLayout";
import useUserStore from "../components/UserStore";
import Translate from "../components/Translate";
import Button from 'react-bootstrap/Button';
import { hubPut } from "../utils/hubApiRequest";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from '../assets/img/wattbike_logo_410x.avif';
import raamTitle from '../assets/img/raamTitle.png';
import raamTop from '../assets/img/raamTop.png';
import raamBottom from '../assets/img/raamBottom.png';
import { Container, Row, Col } from "react-bootstrap";

const CoachLink = (props) => {
  document.title = "Hub Clubs - Connect with a Coach";

  const navigate = useNavigate();

  // Get coaches object ID from URL
  let { objectId, displayName } = useParams();

  // Logged in user details
  const { user, signedIn, setUser, setSignedIn } = useUserStore(
    (state) => state
  );

  // if not logged in send them now
  if (!signedIn || !user || !user.objectId) {
    window.location.replace(`${process.env.REACT_APP_WBAUTH_URL}?client_id=${process.env.REACT_APP_WBAUTH_CLIENT}&response_type=token&scope=email+openid&redirect_uri=${process.env.REACT_APP_WBAUTH_CALLBACK}&state="/coachlink/${objectId}/${displayName}"`);
  }

  // local state
  const [loading, setLoading] = useState(false);
  const [linked, setLinked] = useState(false);
  const [linkError, setLinkError] = useState(false);

  // do any data fetching, processing etc
  useEffect(() => {
    setLoading(true);
    setLoading(false);
  }, [user]);

  // Add this coach to the logged in user's account
  const updateUser = async () => {

    // get the current list and add the new coach ID
    let newCoachesList = user.coaches;
    newCoachesList.push(objectId);

    // build the request
    let requestOptions = {
      resource: "users/" + user.objectId,
      authUser: user,
      data: { coaches: newCoachesList }
    };

    // run the query
    let response = await hubPut(requestOptions);
    if (response.status === 200) {
      setLinked(true);
    } else {
      console.log("Link error: ", response);
      setLinkError(response.status)
    }
  }

  const styles = {
    login: {
      display: "block",
      marginTop: "20px",
      fontSize: "1.2em",
      color: "black",
      width: "100%",
      height: "100%",
    },
    logo: {
      maxWidth: "250px",
      paddingBottom: 10,
    },
    raamTop: {
      position: "absolute",
      top: 0,
      right: -2
    },
    raamBottom: {
      position: "absolute",
      bottom: -1,
      left: 0
    },
    raamContainer: {
      maxWidth: "80%",
      margin: "0 auto",
      textAlign: "center"
    },
    raamLogo: {
      maxWidth: "70%",
      minWidth: "280px",
      // margin: "0 auto",
      paddingTop: "20px",
      paddingBottom: "20px",
    },
    raamCopy: {
      fontSize: "1.5em",
    },
    row: {
      height: "100vh",
      // fontSize: "2em",
    },
    col: {
      // fontSize: ".7em",
      // width: "800px",
    }
  }


  // Render
  if (loading) {
    return (
      <>
        <CoachLinkLayout>
          <h2>
            <Translate label={"general-loading"} />
          </h2>
        </CoachLinkLayout>
      </>
    );
  } else if (!signedIn) {
    <Link style={styles.login} to={`${process.env.REACT_APP_WBAUTH_URL}?client_id=${process.env.REACT_APP_WBAUTH_CLIENT}&response_type=token&scope=email+openid&redirect_uri=${process.env.REACT_APP_WBAUTH_CALLBACK}&state="/coachlink/:${objectId}/:${displayName}"`}>Login to link with your coach</Link>

  } else if (linkError) {
    return (
      <>
        <CoachLinkLayout>
          <Container>
            <Row style={styles.row} className="align-items-center">
              <Col style={styles.col}>
                <img style={styles.logo} src={logo} alt="Logo" />
                <h3><Translate label={"coachlink-error_copy"} /></h3>
                <p><Translate label={"general-error"} />: {linkError}</p>
              </Col>
            </Row>
          </Container>
        </CoachLinkLayout>
      </>
    );
  } else if (linked && objectId == process.env.REACT_APP_WEBDNA_COACH_ID) {
    return (
      <>
        <CoachLinkLayout>
          <Container style={styles.raamContainer}>
            <Row style={styles.row} className="align-items-center">
              <Col style={styles.col} >
                <img style={styles.logo} src={logo} alt="Logo" />
                <br/>
                <img style={styles.raamTop} src={raamTop} alt="RAAM" />
                <img style={styles.raamLogo} src={raamTitle} alt="RAAM" />
                <p style={styles.raamCopy}>You are now signed up to the RAAM Challenge.</p>
                <p style={styles.raamCopy}>You can close this window and use your app to record your cycling sessions.</p>
                <p style={styles.raamCopy}> Good Luck!</p>
                <img style={styles.raamBottom} src={raamBottom} alt="RAAM" />
              </Col>
            </Row>
          </Container>
        </CoachLinkLayout>
      </>
    );
  }  else if (linked) {
    return (
      <>
        <CoachLinkLayout>
          <Container>
            <Row style={styles.row} className="align-items-center">
              <Col style={styles.col} >
                <img style={styles.logo} src={logo} alt="Logo" />
                <h2><Translate label={"coachlink-title"} /></h2>
                <h3><Translate label={"coachlink-success_title"} /></h3>
                <p><Translate label={"coachlink-success_copy"} /></p>
              </Col>
            </Row>
          </Container>
        </CoachLinkLayout>
      </>
    );
  } else {
    if (objectId == process.env.REACT_APP_WEBDNA_COACH_ID) {
      return (
        <>
          <CoachLinkLayout>
            <Container>
              <Row style={styles.row} className="align-items-center">
                <Col style={styles.col}>
                  <img style={styles.logo} src={logo} alt="Logo" />
                  <img style={styles.raamTop} src={raamTop} alt="RAAM" /> <br/>
                  <img style={styles.raamLogo} src={raamTitle} alt="RAAM" />
                  <p>By joining this event you agree to allow your riding activity to be shared with the RAAM event organisers.</p>
                  <p>Would you like to take part in this event?</p>
                  <Button variant="primary" size="lg" onClick={updateUser}>Yes, let me in!</Button>
                  <img style={styles.raamBottom} src={raamBottom} alt="RAAM" />
                </Col>
              </Row>
            </Container>
          </CoachLinkLayout>
        </>
      );

    } else {
      return (
        <>
          <CoachLinkLayout>
            <Container>
              <Row style={styles.row} className="align-items-center">
                <Col style={styles.col} >
                  <img style={styles.logo} src={logo} alt="Logo" />
                  <h2><Translate label={"coachlink-title"} /></h2>
                  <p><Translate label={"coachlink-copy"} /></p>
                  <p><Translate label={"coachlink-confirm_copy"} />: <b>{displayName} ({objectId})?</b></p>
                  <Button variant="primary" size="lg" onClick={updateUser}><Translate label={"coachlink-confirm_button"} /></Button>
                </Col>
              </Row>
            </Container>
          </CoachLinkLayout>
        </>
      );
    }
  }

};


export default CoachLink;
