import React, { useState } from "react";
import copy from "copy-to-clipboard";
import { useNavigate, Link, Text } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Translate from "../components/Translate";

export default function CoachLink(props) {

  const copyToClipboard = () => {
    copy(`https://clubs.wattbike.com/coachlink/${props.coach.objectId}/${props.coach.displayName}`);
  };

  const link = {
    color: "black",
    // backgroundColor: "white",
    fontFamily: "Arial",
    // textDecoration: "none",
  };

  const container = {
    marginTop: 30,
    paddingTop: "15px",
    // backgroundColor: "#e7e7e7",
    // borderRadius: "8px"
    borderTop: "2px solid #999"
  };

  return (
    <div style={container}>
      <p><b><Translate label={"coachlink-boxtitle"} /> </b> <br/><Translate label={"coachlink-boxcopy"} /></p>
      {/* <p>https://coach.wattbike.com/coachlink/{props.coach.objectId}/{props.coach.displayName} </p> */}
      <Button variant="secondary" onClick={copyToClipboard}><Translate label={"coachlink-copybutton"} /></Button>
      <br/>
      {/* <Link to={`/coachlink/${props.coach.objectId}/${props.coach.displayName}`}>Test Link</Link> */}
    </div>
  );
}
