import React, {useEffect, useState} from "react";
import TimeFormat from "./formatters/TimeFormat";
import DistanceFormatter from "./formatters/DistanceFormatter";
import { Row, Col } from "react-bootstrap";
import Translate from "./Translate";
import usePreferencesStore from "./PreferenceStore";

const PerformanceStateHeader = (props) => {

  const [loading, setLoading] = useState(false);
  const [outcomes, setOutcomes] = useState({});

  // useEffect to control when the logic is run, i.e. only on props change
  useEffect( () => {
    setLoading(true);
    setOutcomes(props.data);
    
    setLoading(false);
  }, [props]);

  const style = {
    container:{
      marginTop: "20px",
      marginBottom: "20px",
      fontFamily: "trim-regular"
    },
    metricContainer: {
      textAlign: "center",
      "background-color": "#333333",
      margin: "2px",
      padding: "10px",
    },
    metricTitle: {
      textAlign: "center",
      fontSize: ".8em",
      color: "#ed1c24", //#ed1c24
      textTransform: "uppercase",
    },
    metricValue: {
      textAlign: "center",
      marginRight: 15,
      color: "#333",
      fontSize: "2em",
      whiteSpace: "nowrap",
    },
    metricSubValue: {
      textAlign: "center",
      fontSize: ".7em",
      color: "#888888",
    },
    metricUnit: {
      fontSize: ".7em",
    },
  };

  return loading ? (
    <>
     
    </>
  ) : (
    <>
      <Row style={style.container}>

        {Object.keys(outcomes).map((keyName, i) => (
            <Col className={style.metricContainer}>
                <div style={style.metricTitle}>
                {keyName}
                </div>
                <div style={style.metricValue}>
                {outcomes[keyName]}
                </div>
            </Col>
        ))}

        

        
       
      </Row>
      <Row>
      
      </Row>
    </>
  );
};

export default PerformanceStateHeader;
