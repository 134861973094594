import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import blackOrWhite from "../utils/blackOrWhite";
import textToHex from "../utils/textToHex";
import { Link } from "react-router-dom";
import useUserStore from "../components/UserStore";


const AthleteTag = (props) => {
  const [loading, setLoading] = useState(false);
  const [userColour, setUserColour] = useState(null);
  const [textColour, setTextColour] = useState(null);
  const [displayedName, setDisplayedName] = useState("");
  const { user, signedIn, setUser, setSignedIn } = useUserStore(
    (state) => state
  );

  useEffect(() => {

    const start = async () => {
      setLoading(true);

      // generate a colour based on the user's ID
      let colour = textToHex(props.athlete.objectId);
      setUserColour(colour);

      // get an appropriately contrasting colour for the text
      let textColour = blackOrWhite(colour);
      setTextColour(textColour);

      // default the displayed name to the user's object id
      setDisplayedName(props.athlete.objectId);

      // override the displayed name with a real name if we know it
      setDisplayedName(props.athlete.displayName);

      // if we dont have a name then give a placeholder
      if (!props.athlete.displayName || props.athlete.displayName === ""){
        setDisplayedName(props.athlete.objectId);
      }

      // or if it's me then do something custom
      if(props.objectId === user.objectId)
      {
        setDisplayedName("My Account");
      }

      setLoading(false);
    };
    start().catch("Error: ", console.error);

  }, [props]);

  const style = {
    color: textColour,
    borderRadius: "12px",
    // width: "20em",
    // overflow: "hidden",
    display: "inline-block",
    backgroundColor: userColour,
    padding: "5px",
    paddingLeft: "8px",
    paddingRight: "8px",
    fontFamily: "Arial",
    textDecoration: "none"
  };

  return loading || !props.athlete || !props.athlete.objectId ? (
    <div></div>
  ) : (
    <div style={style}>
      <Link style={style} to={`/athlete/${props.athlete.objectId}`}>{displayedName}</Link>
    </div>
  );
};

//

export default AthleteTag;
