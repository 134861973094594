import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../components/Dashboard.css";
import PrimaryLayout from "../components/PrimaryLayout";
import useUserStore from "../components/UserStore";
import SessionsList from "../components/SessionsList";
import AthletesList from "../components/AthletesList";
import { hubGet } from "../utils/hubApiRequest";
import Translate from "../components/Translate";
import CoachLink from "../components/CoachLink";
import Loading from "../components/Loading";
import DateRangePicker from "../components/DateRangePicker";
import QrCode from "../components/QrCode";

const Dashboard = (props) => {
  document.title = "Hub Clubs - Dashboard";

  const { user, signedIn, setUser, setSignedIn } = useUserStore(
    (state) => state
  );

  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [athletes, setAthletes] = useState([]);

  const today = new Date();
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setDate(threeMonthsAgo.getDate() - 95);
  const [dateRange, setDateRange] = useState({
    from: threeMonthsAgo,
    to: today,
  });

  const mobileBreakpoint = 500;
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= mobileBreakpoint
  );

  // get activity feed TODO: set these to a global config so we're not fetching all the time?
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // let athletesList = await getAthletes(user); // searching all users for those with this user set in their coaches array (until we sort the cross-referencing)
      await setAthletes(user.athletes);

      let sessionsList = await getFeedSessions(
        user,
        user.athletes,
        dateRange,
        50 // limit
      );
      await setSessions(sessionsList.results);

      setLoading(false);
    };
    fetchData().catch("Fetching sessions data error: ", console.error);
  }, [user, dateRange]);

  // return loading ? (
  //   <>
  //     <PrimaryLayout>
  //       <Loading label={"general-loading"} />
  //     </PrimaryLayout>
  //   </>
  // ) : (
  return (
    <>
      <PrimaryLayout>
        <Row>
          <Col sm={12} md={3}>
            <h4>
              <Translate label={"dashboard-my_athletes"} />
            </h4>
            {loading ? null : <AthletesList athletes={athletes} />}

            {!isMobile ? (
              <>
                <CoachLink coach={user} />
                <br />
                <p>
                <Translate label={"coachlink-qr_copy"} />:
                  <QrCode coach={user} />
                </p>
              </>
            ) : null}
          </Col>
          <Col sm={12} md={9}>
            <Row>
              <Col sm={12} md={6}>
                <h4>
                  <Translate label={"dashboard-recent_activity"} />
                </h4>
              </Col>
              <Col sm={12} md={6} className="text-end">
                <DateRangePicker
                  value={dateRange}
                  setDateRange={setDateRange}
                />
              </Col>
            </Row>
            {loading ? (
              <Loading label={"general-loading"} />
            ) : (
              <SessionsList data={sessions} />
            )}
          </Col>
        </Row>
      </PrimaryLayout>
    </>
  );
};

//////////////////////////////////////////////////////////////
// Get all recent sessions the logged in user has access to
//////////////////////////////////////////////////////////////
const getFeedSessions = async (user, athletes, dateRange, limit = 50) => {
  let dateFrom = new Date(dateRange.from);
  let dateTo = new Date(dateRange.to);

  // Limit the request only to active athletes
  let athleteArray = [];
  user.athletes.forEach((athlete) => {
    athleteArray.push({
      __type: "Pointer",
      className: "_User",
      objectId: athlete.objectId,
    });
  });

  let where = { 
    $and: [  
      { _rperm: user.objectId }, 
      {
        // user: { $in: athleteArray },
        
        // _rperm: user.objectId, // only acls with this user explicitly has read permissions
        // user: { "$exists": true},
        startDate: {
          $gte: {
            __type: "Date",
            iso: dateFrom,
          },
          $lte: {
            __type: "Date",
            iso: dateTo,
          },
        }
      }
    ],
  };

  // compile all options
  let requestOptions = {
    resource: "RideSession",
    authUser: user,
    where: JSON.stringify(where),
    include: ["sessionSummary", "user"],
    order: "-startDate",
    limit: limit,
    skip: null,
    cache: true,
  };

  // run the query
  let response = await hubGet(requestOptions);
  return response;
};

export default Dashboard;
