import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../components/Dashboard.css";
import PrimaryLayout from "../components/PrimaryLayout";
import useUserStore from "../components/UserStore";
import Translate from "../components/Translate";
import Button from 'react-bootstrap/Button';
import { hubPut } from "../utils/hubApiRequest";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from '../assets/img/wattbike_logo_410x.avif';
import { Container, Row, Col } from "react-bootstrap";
import CoachLink from "../components/CoachLink";
import QrCode from "../components/QrCode";
import { hubGet } from "../utils/hubApiRequest";

const ExportData = (props) => {
  document.title = "Hub Clubs - Connect with a Coach";

  const navigate = useNavigate();

  // Get coaches object ID from URL
  let { objectId, displayName } = useParams();

  // Logged in user details
  const { user, signedIn, setUser, setSignedIn } = useUserStore(
    (state) => state
  );

  // if not logged in send them now
  if(!signedIn || !user || !user.objectId){
    window.location.replace(`${process.env.REACT_APP_WBAUTH_URL}?client_id=${process.env.REACT_APP_WBAUTH_CLIENT}&response_type=token&scope=email+openid&redirect_uri=${process.env.REACT_APP_WBAUTH_CALLBACK}&state="/coachlink/${objectId}/${displayName}"`);
  }  

  // local state
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [linked, setLinked] = useState(false);
  const [linkError, setLinkError] = useState(false);

  const today = new Date();
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setDate(threeMonthsAgo.getDate() - 95);
  const startOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const startOfLastMonth = startOfThisMonth;
  startOfLastMonth.setMonth(startOfLastMonth.getMonth()-1);
  const startYear = new Date(new Date().getFullYear(), 0, 1);
  const endYear = new Date(new Date().getFullYear(), 11, 31);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await setAthletes(user.athletes);
      setLoading(false);
    };
    fetchData().catch("Fetching sessions data error: ", console.error);
  }, [user]);


  // Get sessions for this month
  const exportSessions = async (startDate, endDate) => {

    let thisMonthDateRange = {
      from: startDate,
      to: endDate,
    }

    let sessionsList = await getFeedSessions(
      user,
      user.athletes,
      thisMonthDateRange,
      150
    );
    console.log("sessionsList: ", sessionsList)
    if(sessionsList.results.length > 0){
      convertData(sessionsList.results);
    } else{
      alert("No ride data found")
    }
  };

  /**
  * ---------------------------------------------
  * Convert the array to csv etc
  *
  * @param {array} `data` array of json objects
  * @param {string} `format` i.e. 'csv' or 'json'
  * ---------------------------------------------
  */
  const convertData = (data, format='csv', filenameSuffix='') => {
    // 
    let flatData = flattenActivity(data);

    if(format === 'csv'){
      const replacer = (key, value) => value === null ? '' : value; // handle null values
      const header = Object.keys(flatData[0]);
      let csv = flatData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      csv = csv.join('\r\n');
      exportData(csv, 'csv', filenameSuffix);
    }else if (format === 'json'){
      exportData(JSON.stringify(flatData), 'json', filenameSuffix);
    }
  }

  /**
 * Flatten a multidimensional object
 *
 * For example:
 *   flattenObject{ a: 1, b: { c: 2 } }
 * Returns:
 *   { a: 1, c: 2}
 */
const flattenActivity = (objects) => {
  let flattenedObjectsArray = [];
  objects.forEach(obj => {
    
    
    const flattened = {
      "wattbikeDevice-appVersion" : obj.wattbikeDevice.appVersion,
      "wattbikeDevice-serialNumber" : obj.wattbikeDevice.serialNumber,
      "wattbikeDevice-firmwareVersion" : obj.wattbikeDevice.firmwareVersion,
      "startDate-iso" : obj.startDate.iso,
      "deviceUserAgent" : obj.deviceUserAgent,
      "userPerformanceState-weight" : obj.userPerformanceState.weight,
      "userPerformanceState-height" : obj.userPerformanceState.height,
      "userPerformanceState-powerKg" : obj.userPerformanceState.powerKg,
      "userPerformanceState-ftp" : obj.userPerformanceState.ftp,
      "userPerformanceState-updatedAt" : obj.userPerformanceState.updatedAt,
      "sessionSummary-objectId" : obj.sessionSummary.objectId,
      "sessionSummary-powerAvg" : obj.sessionSummary.powerAvg,
      "sessionSummary-distance" : obj.sessionSummary.distance,
      "sessionSummary-cadenceMax" : obj.sessionSummary.cadenceMax,
      "sessionSummary-speedMax" : obj.sessionSummary.speedMax,
      "sessionSummary-anglePeakForceLeftAvg" : obj.sessionSummary.anglePeakForceLeftAvg,
      "sessionSummary-anglePeakForceRightNewtonsAvg" : obj.sessionSummary.anglePeakForceRightNewtonsAvg,
      "sessionSummary-balanceAvg" : obj.sessionSummary.balanceAvg,
      "sessionSummary-pesLeftLegCoefficient" : obj.sessionSummary.pesLeftLegCoefficient,
      "sessionSummary-anglePeakForceRightAvg" : obj.sessionSummary.anglePeakForceRightAvg,
      "sessionSummary-pesRightLegCoefficient" : obj.sessionSummary.pesRightLegCoefficient,
      "sessionSummary-powerMax" : obj.sessionSummary.powerMax,
      "sessionSummary-speedAvg" : obj.sessionSummary.speedAvg,
      "sessionSummary-revolutionsCount" : obj.sessionSummary.revolutionsCount,
      "sessionSummary-cadenceAvg" : obj.sessionSummary.cadenceAvg,
      "sessionSummary-anglePeakForceLeftNewtonsAvg" : obj.sessionSummary.anglePeakForceLeftNewtonsAvg,
      "sessionSummary-time" : obj.sessionSummary.time,
      "sessionSummary-pesCombinedCoefficient" : obj.sessionSummary.pesCombinedCoefficient,
      "sessionSummary-energy" : obj.sessionSummary.energy,
      "sessionSummary-createdAt" : obj.sessionSummary.createdAt,
      "sessionSummary-updatedAt" : obj.sessionSummary.updatedAt,
      "user-displayName" : obj.user.displayName
    }
    flattenedObjectsArray.push(flattened);
  });

  return flattenedObjectsArray
}

  /**
  * ---------------------------------------------
  * Force the file download
  *
  * @param {string} `data` i.e. string contents for the txt file
  * @param {string} `format` i.e. 'csv' or 'json'
  * ---------------------------------------------
  */
  const exportData = (data, format, filenameSuffix) => {

    // Build file name
    let now = new Date();
    const dateString = now.toISOString().substring(0, 10);
    if(filenameSuffix === ''){filenameSuffix = dateString;}
    let firstName = "test";
    let lastName = "test";

    const filename = 'WB-HUB -' + firstName.toUpperCase() + '-' + lastName.toUpperCase() + '-' + filenameSuffix + '.' + format

    let blob = new Blob([data], { type: 'text/' + format });
    let url = window.URL.createObjectURL(blob);

    // trigger a download
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  // Render
  if (loading) {
    return (
      <PrimaryLayout>
        <h2>
          <Translate label={"general-loading"} />
        </h2>
      </PrimaryLayout>
    );
  } else {
    return (
      <PrimaryLayout>
        <Container>
          <p>Export Data:</p>
          <div>
            <Button variant="secondary" onClick={() => exportSessions(startOfThisMonth, endOfThisMonth)}>
              <Translate label={"export-this_month"} />
            </Button>
          </div>
          <br />
          <div>
            <Button variant="secondary" onClick={() => exportSessions(startOfLastMonth, startOfThisMonth)}>
              <Translate label={"export-last_month"} />
            </Button>
          </div>
          <br />
          {/* <div>
            <Button variant="secondary" onClick={() => exportSessions(startYear, endYear)}>
              <Translate label={"export-this_year"} />
            </Button>
          </div> */}
            
        </Container>
      </PrimaryLayout>
    );
  }
};


//////////////////////////////////////////////////////////////
// Get all recent sessions the logged in user has access to
//////////////////////////////////////////////////////////////
const getFeedSessions = async (user, athletes, dateRange, limit = 20) => {
  let dateFrom = new Date(dateRange.from);
  let dateTo = new Date(dateRange.to);
  console.log("dateFrom::", JSON.stringify(dateFrom));

  // Limit the request only to active athletes
  let athleteArray = [];
  user.athletes.forEach((athlete) => {
    athleteArray.push({
      __type: "Pointer",
      className: "_User",
      objectId: athlete.objectId,
    });
  });

  let where = {
    user: { $in: athleteArray },
    startDate: {
      $gte: {
        __type: "Date",
        iso: dateFrom,
      },
      $lte: {
        __type: "Date",
        iso: dateTo,
      },
    },
  };

  // compile all options
  let requestOptions = {
    resource: "RideSession",
    authUser: user,
    where: JSON.stringify(where),
    include: ["sessionSummary", "user", "userPerformanceState", "training"],
    order: "-startDate",
    limit: limit,
    skip: null,
    cache: false,
  };

  // run the query
  let response = await hubGet(requestOptions);
  console.log("sessions response: ", response);
  return response;
};


export default ExportData;
