import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Translate from "../components/Translate";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MobileCheck from "../components/MobileCheck";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const PrimaryLayout = (props) => {

  const mobileBreakpoint = 500;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileBreakpoint);

  return (
    <>
      <MobileCheck />
      <Header />
      <Container style={styles.content}>
        <div>{props.children}</div>
      </Container>
      <Footer />
    </>
  );
};

const styles = {
    content: {
      minHeight: "100vh",
      padding: "20px",
      paddingTop: "30px",
      paddingLeft: "10px"
    }
  }

export default PrimaryLayout;
