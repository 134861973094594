import React, { useState, useEffect } from "react";
import textToHex from "../utils/textToHex";
import useUserStore from "../components/UserStore";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const AthletesList = (props) => {
  const { user, signedIn, setUser, setSignedIn } = useUserStore(
    (state) => state
  );
  const [loading, setLoading] = useState(false);
  const [athletes, setAthletes] = useState([]);
  const mobileBreakpoint = 500;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileBreakpoint);

  useEffect(() => {
    setLoading(true);

    if (props.athletes) {
      // sort the list of athletes and set to state for use later
      let tempAthletes = props.athletes;
      tempAthletes = tempAthletes.sort((a, b) => {
        let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();
    
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    });

      // add some for testing long lists
      // for (let i = 0; i < 250; i++) {
      //   tempAthletes.push(tempAthletes[0]);
      // }
      
      setAthletes(tempAthletes);
    }

    setLoading(false);
  }, []);

  const styles = {
    listStyle: {
      borderRadius: "8px",
      overflow: "hidden",
      fontSize: 14,
      // paddingLeft: "22px",
      fontFamily: "Arial",
    },
    containerStyle: {
      maxHeight: 300,
      overflowY: "scroll"
    },
  }

  if (loading) {
    return (
      <>
        <div>Loading.</div>
      </>
    );
  } else if (!athletes || athletes.length === 0) {
    return (
      <>
        <div>No athletes associated with this account.</div>
      </>
    );
  } else if (isMobile) {
    return (
      <>
        <DropdownButton variant="secondary" id="dropdown-basic-button" title="Athletes:">

          {athletes.map((athlete, i) => {
            return (
              <Dropdown.Item href={`/athlete/${athlete.objectId}`}>
                {athlete.name ? athlete.name : athlete.objectId}
              </Dropdown.Item>
            );
          })}

        </DropdownButton>
      </>
    );
  } else {
    return (
      <div style={styles.containerStyle}>
        <ol style={styles.listStyle}>
          {athletes.map((athlete, i) => {
            return (
              <li
                key={i}
                style={{ color: textToHex(athlete.objectId), marginBottom: 5 }}
              >
                {/* <AthleteTag objectId={athlete.objectId} /> */}
                <Link
                  style={{ color: textToHex(athlete.objectId), fontWeight: "bold" }}
                  to={`/athlete/${athlete.objectId}`}
                >
                  {athlete.name ? athlete.name : athlete.objectId}
                </Link>
              </li>
            );
          })}
          <li
                key={"me"}
                style={{ color: textToHex(user.objectId), marginBottom: 5 }}
              >
          <Link
            style={{ color: textToHex(user.objectId), fontWeight: "bold" }}
            to={`/athlete/${user.objectId}`}
          >
            {user.displayName ? user.displayName : user.objectId}
          </Link>
          </li>
        </ol>
      </div>
    );
  }
};

export default AthletesList;
