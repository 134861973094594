import React, { useState } from "react";
import {
  Badge,
  Popover,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Modal,
  Button,
} from "react-bootstrap";
// import ModelFormatter from './formatters/ModelFormatter';

const MyModal = (props) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const styles = {
    children: {
        cursor: "pointer",
    }
  };

  return (
    <>
      <div variant="primary" onClick={handleShow} style={styles.children}>
      {props.children}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.content}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyModal;
