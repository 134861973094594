import React from 'react';

const TrainingFocusFormatter = (props) => {

  const focus = idToFocus(props.data);

  return (<>{focus}</>);
};

function idToFocus(focusId) {
  let focus;
  
  if(focusId === 10){  focus = "Cycling: Sprinter"; }
  else if (focusId === 11){  focus = "Cycling: Sustained Power"; }
  else if (focusId === 12){  focus = "Cycling: Solo Endurance"; }
  else if (focusId === 13){  focus = "Cycling: Group Endurance"; }
  else if (focusId === 14){  focus = "Cycling: All Day Events"; }
  else if (focusId === 21){  focus = "Team Sports: Powerful Bursts"; }
  else if (focusId === 22){  focus = "Team Sports: Sustained High Tempo"; }
  else if (focusId === 23){  focus = "Team Sports: Range Of Intensities"; }
  else if (focusId === 41){  focus = "Health Fitness: Powerful Bursts"; }
  else if (focusId === 42){  focus = "Health Fitness: Sustained High Tempo"; }
  else if (focusId === 43){  focus = "Health Fitness: Range Of Intensities"; }
  else if (focusId === 51){  focus = "Competitive Fitness: Powerful Bursts"; }
  else if (focusId === 52){  focus = "Competitive Fitness: Sustained High Tempo"; }
  else if (focusId === 53){  focus = "Competitive Fitness: Range Of Intensities"; }
  else if (focusId === 31){  focus = "Return To Play: Rehab"; }
  else if (focusId === 32){  focus = "Return To Play: Return To Training"; }
  else if (focusId === 33){  focus = "Return To Play: On The Path"; }
  else if (focusId === 34){  focus = "Return To Play: Match Fitness"; }
  else if (focusId === 61){  focus = "Off-Feet Conditioning: Powerfu Bursts"; }
  else if (focusId === 62){  focus = "Off-Feet Conditioning: Sustained High Tempo"; }
  else if (focusId === 63){  focus = "Off-Feet Conditioning: Range Of Intensities"; }
  else if (focusId === 71){  focus = "Other: Powerful Bursts"; }
  else if (focusId === 72){  focus = "Other: Sustained High Tempo"; }
  else if (focusId === 73){  focus = "Other: Range Of Intensities"; }
  else { focus = "Unknown";  }
  
  return focus;
}

export default TrainingFocusFormatter;

