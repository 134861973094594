import React, {useState, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useUserStore from "../components/UserStore";
import highchartsAccessibility from "highcharts/modules/accessibility";
highchartsAccessibility(Highcharts);

const SessionChartHR = (props) => {

  const { user, signedIn, setUser, setSignedIn } = useUserStore((state) => state);
  const [session, setSession] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chartOptions, setChartOptions] = useState({});
  const [firstDraw, setFirstDraw] = useState(true);

  // useEffect to control when the logic is run, i.e. only on props change
  useEffect( () => {
    const fetchData = async () => {
      setLoading(true);
      updateOptions(props.data, props.workoutData);
      setLoading(false);
    };

    fetchData().catch('Eerror: ', console.error);
  }, []);

  const handleZoom = (e, props) => {
    props.handleChangeZoom(e);
  }

  const handleToolTip = (point, props, e) => {
    props.handleChangeToolTip(point, e);
  }

  const updateOptions = async () => {

    let that = this;

    let chartTheme = {
      "dark": {
        background: "rgba(33,33,33,1)",
        color: "#ffffff",
        seriesColors: ['#3f93f4', '#ed1c24', '#ffa505'],
        gridColor: "#6b6b6b"
      },
      "light": {
        background: "#ffffff",
        color: "rgba(33,33,33,1)",
        seriesColors: ['#3f93f4', '#ed1c24', '#ffa505'],
        gridColor: "#E6E6E6"
      }
    };

    let series = await [
      {
        color: chartTheme['light'].seriesColors[1],
        showInLegend: false,
        name: 'HR',
        marker: {
          enabled: false,
          states: {
            hover: {
              radius: 4
            }
          },
          symbol: 'circle'
        },
        visible: true,
        data: props.data.heartrateData
      }
    ];

    let chartOptions = {
      chart: {
        type: 'line',
        backgroundColor: chartTheme['light'].background,
        zoomType: 'x',
        animation: false,
        height: 200,
        marginRight: 0,
        sync: true, // custom property
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      tooltip: {
        animation: false,
        positioner: function () {
          return {
              // right aligned
              x: this.chart.chartWidth - this.label.width,
              y: 0 // align to title
          };
        },
        style: {
          fontSize: '18px',
          color: 'white',
        },
        pointFormatter: function(e) {
          props.handleChangeToolTip(this, e);
          return this.y + "bpm";
        },
        borderWidth: 0,
        backgroundColor: 'none',
        pointFormat: '{point.y}',
        headerFormat: '',
        shadow: false,
      },
      series: series,
      plotOptions: {
        series: {
          animation: false,
          states: {
            hover: {
              enabled: false
            }
          },
          events: {
            mouseOut: function (e) {
              console.log("mouse out");
              props.handleChangeToolTip({x:null, y:null}, e);
            }
          }
        },
        line: {
          lineWidth: 1,
        },
        spline: {
          dataGrouping: {
            enabled: true,
            forced: true,
            smoothed: true,
            units: [[
              'second', [1]
            ]]
          },
          lineWidth: 1,
        }
      },
      yAxis: {
        gridLineColor: chartTheme['light'].gridColor,
        title: "",
        min: 50,
        labels: {
          align: 'left',
          x: 0,
          y: -2,
          style: {
            color: chartTheme['light'].color,
          }
        }
      },
      xAxis: {
        gridLineColor: chartTheme['light'].gridColor,
        visible: true,
        crosshair: true,
        style: {
          color: chartTheme['light'].color,
        },
        labels: {
          formatter: function () {
            let minutes = Math.floor(this.value / 60);
            let seconds = this.value - minutes * 60;
            return minutes + ':' + seconds;
          }
        },
        plotLines: [],
        events: {
          afterSetExtremes (e) {
            // handleZoom(e,props)
            props.handleChangeZoom(e);
          }
        },
      }
    };

    // function handleZoom ({ min, max }, props) {
    //   props.handleChangeZoom({ min, max });
    // }

    // function handleToolTip (point, props, e) {
    //   props.handleChangeToolTip(point, e);
    // }

    setChartOptions(chartOptions);

  };


    return loading ? (
      <div>Loading main chart...</div>
    ) : (
      <div>
        <div>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} onChange={props.onChangeValue}/>
        </div>
      </div>
    );

}

export default SessionChartHR;
