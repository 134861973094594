import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Translate from "../components/Translate";
import useUserStore from "../components/UserStore";
import wattbikeLogo from "../assets/img/wattbike_logo_410_black.avif";
import { Row, Col } from "react-bootstrap";

const Footer = () => {
  const { user, signedIn, setSignedIn } = useUserStore((state) => state);

  const date = new Date();
  const year = date.getFullYear();

  const styles = {
    footerContainer: {
      backgroundColor: "black",
      padding: 40,
      color: "#999",
      fontSize: ".8em"
    },
    link: {
      // fontFamily: "trim-regular",
      textDecoration: "underline",
      textTransform: "uppercase",
      color: "#999"
    },
    termsCopy: {
      color: "#999"
    }
  };

  return (
    <>
    <div style={styles.footerContainer}>
      <Container>
    <Row className="text-center">
      <Col>
        <p>© {year} Copyright: <a style={styles.link} href="https://wattbike.com/">Wattbike Ltd</a></p>
      </Col>
    </Row>
    <Row className="text-center">
      <Col>
      <p style={styles.termsCopy}>Wattbike Ltd is registered in England 05554245. Registered office Unit 13, Nottingham South and Wilford Industrial Estate, Nottingham, NG11 7EP. Wattbike Ltd acts as a broker and not a lender and offers finance from Secured Trust Bank PLC trading as V12 Retail Finance. Wattbike Ltd is authorised and regulated by the Financial Conduct Authority. Our registration number is 738363. Credit provided subject to age, status and a minimum spend of £1000 inc VAT applies. Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc. registered in the U.S. and other countries. Google Play and the Google Play logo are trademarks of Google Inc.</p>
      </Col>
    </Row>
    <Row className="text-center">
      <Col>
      <Navbar data-bs-theme="light" style={styles.navBarSmall}>
          <Container>
          <Navbar.Collapse className="justify-content-center">
      <Nav.Link
          as={Link}
          to="/privacy"
          style={styles.link}
        >
          Privacy
        </Nav.Link>
        &nbsp;&nbsp;
        <Nav.Link
          as={Link}
          to="/terms"
          style={styles.link}
        >
          Terms
        </Nav.Link>
        </Navbar.Collapse>
        </Container>
        </Navbar>
      </Col>
    </Row>
    </Container>
    </div>
    </>
  );
};

export default Footer;
