import React, {useEffect, useState} from "react";
import TimeFormat from "./formatters/TimeFormat";
import DistanceFormatter from "./formatters/DistanceFormatter";
import { Row, Col } from "react-bootstrap";
import Translate from "./Translate";
import usePreferencesStore from "./PreferenceStore";

const SessionHeader = (props) => {

  const { units, setUnits } = usePreferencesStore((state) => state);
  const [loading, setLoading] = useState(false);
  const [headerData, setHeaderData] = useState({});

  // useEffect to control when the logic is run, i.e. only on props change
  useEffect( () => {
    setHeaderData(props.data.headerData);
    // console.log("props.data.headerData: ", props.data.headerData);
  }, [props]);


  const style = {
    container:{
      marginTop: "20px",
      marginBottom: "20px",
      fontFamily: "trim-regular"
    },
    metricContainer: {
      textAlign: "center",
      "background-color": "#333333",
      margin: "2px",
      padding: "10px",
    },
    metricTitle: {
      textAlign: "center",
      fontSize: ".8em",
      color: "#ed1c24", //#ed1c24
      textTransform: "uppercase",
    },
    metricValue: {
      textAlign: "center",
      marginRight: 15,
      color: "#333",
      fontSize: "2em",
      whiteSpace: "nowrap",
    },
    metricSubValue: {
      textAlign: "center",
      fontSize: ".7em",
      color: "#888888",
    },
    metricUnit: {
      fontSize: ".7em",
    },
  };

  return loading ? (
    <>
     
    </>
  ) : (
    <>
      <Row style={style.container}>
        <Col className={style.metricContainer}>
          <div style={style.metricTitle}>
          <Translate label={"session-time"} />
          </div>
          <div style={style.metricValue}>
            <TimeFormat value={headerData.time} />
          </div>
          <div style={style.metricSubValue}>&nbsp;</div>
        </Col>
        <Col className={style.metricContainer}>
          <div style={style.metricTitle}>
          <Translate label={"session-distance"} />
          </div>
          <div style={style.metricValue}>
            <DistanceFormatter
              value={headerData.distance}
              unit={units}
            />
            <span style={style.metricUnit}>
              {units === "imperial" ? "mi" : "km"}
            </span>
          </div>
          <div style={style.metricSubValue}>&nbsp;</div>
        </Col>
        <Col className={style.metricContainer}>
          <div style={style.metricTitle}>
          <Translate label={"session-power"} />
          </div>
          <div style={style.metricValue}>
            {headerData.powerAvg}
            <span style={style.metricUnit}>W</span>
          </div>
          <div style={style.metricSubValue}>
          <Translate label={"session-max"} />:{" "}
            {Math.round(headerData.powerMax)}
            <span style={style.metricUnit}>W</span>
          </div>
        </Col>

        <Col className={style.metricContainer}>
          <div style={style.metricTitle}>
          Weighted Power
          </div>
          <div style={style.metricValue}>
            { !headerData.weightedPower || isNaN(headerData.weightedPower) ? "--" : headerData.weightedPower }
            <span style={style.metricUnit}>W</span>
          </div>
        </Col>

        <Col className={style.metricContainer}>
          <div style={style.metricTitle}>
          <Translate label={"session-hr"} />:
          </div>
          <div style={style.metricValue}>
            {headerData.hrAvg
              ? Math.round(headerData.hrAvg)
              : "--"}
            <span style={style.metricUnit}>bpm</span>
          </div>
          <div style={style.metricSubValue}>
          <Translate label={"session-max"} />:{" "}
            {headerData.hrMax}
            <span style={style.metricUnit}>bpm</span>
          </div>
        </Col>
        <Col className={style.metricContainer}>
          <div style={style.metricTitle}>
          <Translate label={"session-cadence"} />:
          </div>
          <div style={style.metricValue}>
            {headerData.cadenceAvg}
          </div>
          <div style={style.metricSubValue}>
          <Translate label={"session-max"} />:{" "}
            {Math.round(headerData.cadenceMax)}
            <span style={style.metricUnit}>rpm</span>
          </div>
        </Col>
        {/* <Col className={style.metricContainer}>
          <div style={style.metricTitle}>
          <Translate label={"session-energy"} />:
          </div>
          <div style={style.metricValue}>
            {headerData.energy}
          </div>
          <div style={style.metricSubValue}>&nbsp;</div>
        </Col> */}
        <Col className={style.metricContainer}>
          <div style={style.metricTitle}>PES:</div>
          <div style={style.metricValue}>
            {Math.round(headerData.pesAvg * 100)}
          </div>
          <div style={style.metricSubValue}>&nbsp;</div>
        </Col>
        <Col className={style.metricContainer}>
          <div style={style.metricTitle}>
          <Translate label={"session-balance"} />:
          </div>
          <div style={style.metricValue}>
            {headerData.balanceAvg} /{" "}
            {100 - headerData.balanceAvg}
          </div>
          <div style={style.metricSubValue}>&nbsp;</div>
        </Col>
      </Row>
    </>
  );
};

export default SessionHeader;
