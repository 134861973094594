import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { withRouter } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const SessionChartCpCurve = (props) => {

  const [loading, setLoading] = useState(false);
  const [chartOptions, setChartOptions] = useState();
  
  useEffect(() => {
    setLoading(true);
    updateOptions();
    setLoading(false);
  }, []);


  const handleZoom = (e, props) => {
    props.handleChangeZoom(e);
  }

  const handleToolTip = (point, props, e) => {
    props.handleChangeToolTip(point, e);
  }

  const updateOptions = async () => {

    // try {

      let plotSeries = [];
      let counter = 0;
      let that = this;

      let chartTheme = {
        background: "#ffffff",
        color: "rgba(33,33,33,1)",
        seriesColors: ['#3f93f4', '#ed1c24', '#ffa505'],
        gridColor: "#E6E6E6"
      };

      await Object.keys(props.data.cpResults).forEach(function(key) {
        plotSeries.push({
          x: counter,
          y: parseInt(props.data.cpResults[key].avg)
        });
        counter++;
      });

      let chartOptions = {
        chart: {
          type: 'spline',
          zoomType: '',
          animation: false,
          backgroundColor: chartTheme.background,
        },
        credits: {
          enabled: false
        },
        title: {
          text: null
        },
        yAxis: {
          gridLineColor: chartTheme.gridColor,
          title: "",
            labels: {
              align: 'left',
              x: 0,
              y: -2,
              style: {
                color: chartTheme.color,
              }
          }
        },
        xAxis: {
          gridLineColor: chartTheme.gridColor,
          categories: props.data.cpIntervals,
          style: {
            color: chartTheme.color,
          },
          labels: {
            formatter: function () {
              var label = this.axis.defaultLabelFormatter.call(this);
    
              if (label > 60) {
                return (label / 60) + ':00';
              }else{
                return label + 's'
              }

            }
          }
        },
        series: [{
          showInLegend: false,
          animation: false,
          data: plotSeries
        }]
      };

      setChartOptions(chartOptions);

  };


    return loading ? (
      <div>Loading main chart...</div>
    ) : (
      <div>
        <div>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      </div>
    );
}

export default SessionChartCpCurve;
