////////////////////////////////////////////////////////////////////////////////
// Currently using Dexie wrapped IndexedDB as a simple key-value store 
// to cache API requests, downloaded session files and any pre-processing
////////////////////////////////////////////////////////////////////////////////

import Dexie from 'dexie';

const db = new Dexie('localDexie');

db.version(1).stores({
    RideSessionFile: `key,value,date`,
    RideSessionSummary: `key,value,date`,
    RideSession: `key,value,date`,
    localSettings: `key,value,date`
});

db.version(2).stores({
    RideSessionFile: `key,value,date`,
    RideSessionSummary: `key,value,date`,
    RideSession: `key,value,date`,
    localSettings: `key,value,date`,
    localCache: `key,value,date`
});

db.version(3).stores({
    localCache: `key,value,date`
});

export default db;