import React from 'react';

const DistanceFormatter = (props) => {

  let convertedDistance = 0;
  
  if(props.unit && props.unit === "imperial"){
    convertedDistance = metersToMiles(props.value);
  }else{
    convertedDistance = metersToKilometers(props.value);
  }

  return (<>{convertedDistance}</>);
  
};

function metersToKilometers(meters) {
  const kilometers = (meters / 1000).toFixed(2);
  return kilometers;
}

function metersToMiles(meters) {
  const miles = (meters * 0.00062137119223733).toFixed(2);
  return miles;
}

export default DistanceFormatter;

