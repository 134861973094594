import React, {useEffect, useState} from "react";
import { Row, Col, Container } from "react-bootstrap";
import Translate from "../components/Translate";
import Button from "react-bootstrap/Button";

export default function MobileCheck() {

    const mobileBreakpoint = 500;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileBreakpoint);
    const [isDisplayed, setIsDisplayed] = useState(true);

    const styles = {
        container:{
            position: "absolute",
            zIndex: 10000,
            padding: "20px",
            fontFamily: "trim-regular",
            textAlign: "center",
            backgroundColor: "grey",
            color: "white",
            height: "100%"
          },
          row:{
            textAlign: "center",
            fontFamily: "trim-regular",
          }
    };

    return (
    <>
        { isMobile && isDisplayed ? <>
        <Container style = {styles.container}>
            <Row style = {styles.row}>
                <h2>Please Note</h2><p>You are viewing this site from a small resolution device.</p> <p>Due to the charts and data displayed, this site is best viewed on a larger format screen such as a tablet or laptop</p>
                <br/><br/>
                <Button variant="secondary" onClick={() => setIsDisplayed(!isDisplayed)}>
                    <Translate label={"general-ok"} />
                </Button>
            </Row>
        </Container>
        </> : null }
    </>
  );
}