import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";

const SessionChartPolarAM = (props) => {
  const [loading, setLoading] = useState(false);

  let chartTheme = {
    spline: "rgba(0,0,0,0.05)",
    target: "rgba(0,0,0,0.1)",
    targetDark: "rgba(0,0,0,0.3)",
    background: "#ffffff",
  };

  // define spline opacity
  let splineColour = "";
  if (props.data.polarDataFull.length < 20) {
    splineColour = "rgba(63, 147, 244, 1)";
  } else if (props.data.polarDataFull.length < 50) {
    splineColour = "rgba(63, 147, 244, 0.4)";
  } else if (props.data.polarDataFull.length < 100) {
    splineColour = "rgba(63, 147, 244, 0.2)";
  } else if (props.data.polarDataFull.length < 500) {
    splineColour = "rgba(63, 147, 244, 0.15)";
  } else if (props.data.polarDataFull.length < 1000) {
    splineColour = "rgba(63, 147, 244, 0.1)";
  } else if (props.data.polarDataFull.length < 5000) {
    splineColour = "rgba(63, 147, 244, 0.05)";
  } else {
    splineColour = "rgba(63, 147, 244, 0.04)";
  }

  let chartConf = {
    divId: props.identifier ? props.identifier : "polarChartDiv",
    max: props.data.maxPolarForce,
    series: props.data.polarDataFull,
    width: props.width,
    height: props.height,
    bg: props.bg,
  };

  // useEffect to control when the logic is run, i.e. only on props change
  useEffect(() => {
    setLoading(true);
    createPolar(chartConf);
    setLoading(false);
  }, [props]);

  /**
   * ---------------------------------------------
   * Create a polar chart
   * @param {Object} config
   * ---------------------------------------------
   */

  const getLargestForce = (array) => {
    let maxForce = Math.max(...array.map((revolution) => revolution.maxValue));
    //console.log("maxForce: ", maxForce);
    return maxForce;
  };

  const createPolar = (config) => {

    // if canvas exists, remove it
    var div = document.getElementById(config.divId);
    if (div) {
      div.innerHTML = "";
    }

    // create the canvas
    var currentCanvas = addCanvas(config.divId);

    // draw the target circles
    drawTarget(currentCanvas, config);
    drawTargetLines(currentCanvas, config);

    // draw the polar lines
    drawPolar(currentCanvas, config);

    // draw the AoPF lines
  };

  /**
   * ---------------------------------------------
   * Draw the polar series'
   * @param {Object} canvas
   * @param {Object} config
   * ---------------------------------------------
   */
  const drawPolar = (canvas, config) => {
    let ctx = canvas.getContext("2d");
    const radianValue = 0.01745329252;
    let maxPoint = config.max;
    let multiplier;

    if (canvas.width <= canvas.height) {
      multiplier = canvas.width / 2 / maxPoint;
    } else {
      multiplier = canvas.height / 2 / maxPoint;
    }
    const startpoint = { x: canvas.width / 2, y: canvas.height / 2 };
    for (var i = 0; i < config.series.length; i++) {
      let points = config.series[i].data;
      ctx.strokeStyle = chartTheme.spline;
      ctx.lineWidth = 1;
      ctx.beginPath();
      let degrees = points[0][0] - 90;
      let radius = points[0][1] * multiplier;
      ctx.moveTo(
        radius * Math.cos((180 - degrees) * radianValue) + startpoint.x,
        radius * Math.sin((180 - degrees) * radianValue) + startpoint.y
      );
      for (var j = 0; j < points.length; j++) {
        degrees = points[j][0] - 90;
        radius = points[j][1] * multiplier;
        ctx.lineTo(
          radius * Math.cos((180 - degrees) * radianValue) + startpoint.x,
          radius * Math.sin((180 - degrees) * radianValue) + startpoint.y
        );
      }
      ctx.closePath();
      ctx.stroke();
    }
  };

  /**
   * ---------------------------------------------
   * Draw the target circles in the background
   * @param {Object} canvas
   * @param {Object} config
   * ---------------------------------------------
   */
  const drawTarget = (canvas, config) => {
    var context = canvas.getContext("2d");
    var centerX = canvas.width / 2;
    var centerY = canvas.height / 2;
    let multiplier;

    if (canvas.width < canvas.height) {
      multiplier = canvas.width / 2 / config.max;
    } else {
      multiplier = canvas.height / 2 / config.max;
    }

    var maxRadius = config.max * multiplier - 2; // "-2" just helps the outer circle not get cropped
    var rings = 6;
    var step = Math.round(maxRadius / rings);

    for (var i = 1; i < rings + 1; i++) {
      context.beginPath();
      context.arc(centerX, centerY, step * i, 0, 2 * Math.PI, false);
      if (i === rings) {
        context.lineWidth = 1.5;
        context.strokeStyle = chartTheme.targetDark;
      } else {
        context.lineWidth = 1;
        context.strokeStyle = chartTheme.target;
      }
      context.stroke();
    }
  };

  /**
   * ---------------------------------------------
   * Draw the target segment lines
   * @param {Object} canvas
   * @param {Object} config
   * ---------------------------------------------
   */
  const drawTargetLines = (canvas, config) => {
    var ctx = canvas.getContext("2d");
    const radianValue = 0.01745329252;
    let multiplier;

    if (canvas.width < canvas.height) {
      multiplier = canvas.width / 2 / config.max;
    } else {
      multiplier = canvas.height / 2 / config.max;
    }
    const radius = config.max * multiplier;
    const lineCount = 8;
    const step = 360 / lineCount;
    const startpoint = { x: canvas.width / 2, y: canvas.height / 2 };

    for (var i = 1; i < lineCount + 1; i++) {
      let degrees = step * i;
      ctx.beginPath();
      ctx.strokeStyle = chartTheme.target;
      ctx.moveTo(startpoint.x, startpoint.y);
      ctx.lineTo(
        radius * Math.cos(degrees * radianValue) + startpoint.x,
        radius * Math.sin(degrees * radianValue) + startpoint.y
      );
      ctx.stroke();
    }
  };

  /**
   * ---------------------------------------------
   * Dynamically create and add a canvas to the target div
   * @param {String} divId
   * ---------------------------------------------
   */
  const addCanvas = (divId) => {
    // console.log('divId: ' + divId);
    var canvas = document.createElement("canvas");
    var div = document.getElementById(divId);
    div.appendChild(canvas);

    // console.log("Div width: ", div.offsetWidth);

    canvas.style.height = div.offsetWidth;
    canvas.height = div.offsetWidth;
    canvas.style.width = div.offsetWidth;
    canvas.width = div.offsetWidth;
    canvas.style.background = chartTheme.background;

    return canvas;
  };

  return <div id={chartConf.divId}></div>;
  
};

export default SessionChartPolarAM;
